import { Badge } from '@material-ui/core'
import { CloudDownload, Visibility } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { formatEncodedId } from './customerIdFormatting'
import { Document as Doc, DocumentsQuery } from './Document'
import { ApiClient, BlobResponse } from './wtf/api/ApiClient'
import { useApiContext } from './wtf/api/ApiContext'
import { fakePaginated } from './wtf/api/Search'
import { ListRowPopover } from './wtf/components/datagrid/DefaultDatagridRowPopover'
import { ReadonlyDataGridWithActions } from './wtf/components/datagrid/ReadOnlyDataGridWithActions'
import { useToggle } from './wtf/refresh/Toggle'
import { caseNever } from './wtf/utils/never'
import { toLocaleDateTime } from './wtf/utils/utils'


export const viewOrDownloadFile = (blob: BlobResponse, t: 'view' | 'download', filename: string) => {
    const url = window.URL.createObjectURL(blob.blob)
    try {
        switch (t) {
            case 'view': return window.location.href = url
            case 'download':
                var link = document.createElement('a')
                link.href = url
                link.target = "_self"
                link.download = filename
                link.click()
                link.remove()
                break;
            default: return caseNever(t)
        }
    }
    finally {
        window.URL.revokeObjectURL(url)
    }
}

type Props =
    DocumentsQuery &
    {
        dataGridId: string,
        type: 'inbox' | 'outbox'
        refreshToggle?: boolean,
    }

export const DocumentsGrid = (props: Props) => {
    const api = useApiContext()
    const { toggle: documentsGridToggle, switchToggle: refreshDocumentsGrid } = useToggle()
    const [query, setQuery] = useState<DocumentsQuery>({ applicationId: props.applicationId, customerId: props.customerId })

    useEffect(() => refreshDocumentsGrid(), [props.refreshToggle])

    const dataLoader = async (query: DocumentsQuery, api: ApiClient) => {
        return fakePaginated(props.type === 'inbox' ? await api.documents.getDocumentsInbox(query) : await api.documents.getDocumentsOutbox(query))
    }

    const onViewOrDownloadClick = async (t: 'view' | 'download', documentId: number, filename: string, key: 'inbox' | 'outbox') => {
        let blob: BlobResponse | null;
        blob = key === 'inbox' ? await api.documents.getDocumentInbox(documentId, query) : await api.documents.getDocumentOutbox(documentId, query)
        if (!blob) return
        refreshDocumentsGrid()
        return viewOrDownloadFile(blob, t, blob.filename ?? filename)
    }

    const getColumns = () =>
        Array.from((function* () {
            yield { id: 1, columnName: 'Κωδικός εγγράφου', renderCell: (x: Doc) => <Badge color='error' invisible={x.isExternalUserRead} variant='dot'>{formatEncodedId(x.documentId)}</Badge> }
            yield { id: 2, columnName: 'Είδος εγγράφου', renderCell: (x: Doc) => x.documentTypeName }
            yield { id: 3, columnName: 'Όνομα εγγράφου', renderCell: (x: Doc) => x.originalFilename }
            yield { id: 4, columnName: 'Ημερομηνία Δημιουργίας', renderCell: (x: Doc) => toLocaleDateTime(x.uploadDate) }
        })())

    return (
        <React.Fragment>
            <ReadonlyDataGridWithActions dataGridId={props.dataGridId} dataLoader={(_, api) => dataLoader(query, api)}
                refreshToggle={documentsGridToggle}
                columns={getColumns()}
                showRefresh
                onRowDoubleClick={x => onViewOrDownloadClick('download', x.documentId, x.filename, props.type)}
                rowPopover={x =>
                    <ListRowPopover
                        elements={
                            Array.from((function* () {
                                if (x.canBeDisplayed)
                                    yield { label: 'Προβολή', icon: <Visibility />, onClick: () => onViewOrDownloadClick('view', x.documentId, x.filename, props.type) }
                                yield { label: 'Λήψη', icon: <CloudDownload />, onClick: () => onViewOrDownloadClick('download', x.documentId, x.filename, props.type) }
                            })())
                        }
                    />
                }
            />
        </React.Fragment>
    )
}