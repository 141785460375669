import { Button, Card, CardActions, CardHeader, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { Delete, Edit, Visibility } from '@material-ui/icons'
import React from 'react'

type Props =
    {
        title?: React.ReactNode
        avatar?: React.ReactNode
        onEdit?: () => void
        onEditLink?: string
        onView?: () => void
        onViewLink?: string
        onDelete?: () => void
        childrenLocation?: 'start' | 'end' | 'middle'
    }

type PopoverEntry = {
    icon: React.ReactNode
    label: React.ReactNode
    onClick?: () => void
    href?: string
    hidden?: boolean
}

export const DefaultDatagridRowPopover: React.FC<Props> = props =>
    <Card>
        {props.title && <CardHeader title={props.title} avatar={props.avatar} />}
        {props.title && <Divider />}
        <CardActions color='primary'>
            {props.childrenLocation === 'start' || props.childrenLocation === undefined ? props.children : null}
            {props.onEdit && <Button color='primary' size="small" startIcon={<Edit />} onClick={_ => props.onEdit?.()}>Επεξεργασια</Button>}
            {props.onView && <Button color='primary' size="small" startIcon={< Visibility />} onClick={_ => props.onView?.()}>Προβολη</Button>}
            {props.childrenLocation === 'middle' ? props.children : null}
            {props.onDelete && <IconButton color='primary' size="small" onClick={_ => props.onDelete?.()}><Delete /></IconButton>}
            {props.childrenLocation === 'end' ? props.children : null}
        </CardActions>
    </Card>

const mapEntryToListItem = (...es: PopoverEntry[]) =>
    es.filter(e => !e.hidden).map((e, i) =>
        e.href
            ? <ListItem key={i} component='a' target='_blank' href={e.href}>
                <ListItemIcon>{e.icon}</ListItemIcon>
                <ListItemText><Typography color='textPrimary' variant='body2'>{e.label}</Typography></ListItemText>
            </ListItem>
            : <ListItem key={i} button onClick={e.onClick}>
                <ListItemIcon>{e.icon}</ListItemIcon>
                <ListItemText><Typography variant='body2'>{e.label}</Typography></ListItemText>
            </ListItem>)

export const ListRowPopover = (props: Props & { elements?: PopoverEntry[] }) =>
    <List dense>
        {(props.childrenLocation === 'start' || props.childrenLocation === undefined) && props.elements
            ? mapEntryToListItem(...props.elements)
            : null}
        {mapEntryToListItem({ icon: <Edit />, label: 'Επεξεργασία', onClick: () => props.onEdit?.(), href: props.onEditLink, hidden: !props.onEdit && !props.onEditLink })}
        {mapEntryToListItem({ icon: <Visibility />, label: 'Προβολή', onClick: () => props.onView?.(), href: props.onViewLink, hidden: !props.onView && !props.onViewLink })}
        {props.childrenLocation === 'middle' && props.elements
            ? mapEntryToListItem(...props.elements)
            : null}
        {mapEntryToListItem({ icon: <Delete />, label: 'Διαγραφή', onClick: () => props.onDelete?.(), hidden: !props.onDelete })}
        {props.childrenLocation === 'end' && props.elements
            ? mapEntryToListItem(...props.elements)
            : null}
    </List>