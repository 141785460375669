import { Box, Button, Card, CardActions, CardContent, Chip, ClickAwayListener, Fade, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputBase, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Radio, RadioGroup, Toolbar, Tooltip, Typography } from '@material-ui/core'
import { createStyles, fade, makeStyles } from '@material-ui/core/styles'
import { Clear, Search } from '@material-ui/icons'
import { mdiAsterisk, mdiFilterMenuOutline, mdiSort, mdiSortAscending, mdiSortDescending } from '@mdi/js'
import Icon from '@mdi/react'
import { Base64 } from 'js-base64'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BlobResponse } from '../../api/ApiClient'
import { ApiDescription, FilterDescription, FilterOperation, FilterWithValue, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject, parseSorting, SortCriterionDescription, SortCriterionWithValue, SortDirection } from '../../api/Search'
import { useInternationalization } from '../../internationalization/Internationalization'
import { openApplicationIdb, StoredSettings } from '../../local-store/IndexedDb'
import { useNotificationsContext } from '../../notifications/SnackbarContext'
import { useDialogToggle } from '../../refresh/Toggle'
import { caseNever } from '../../utils/never'
import { isFuzzyTextMatch, unexpected } from '../../utils/utils'
import { viewOrDownloadFile } from '../../utils/viewOrDownloadFile'
import { ButtonDrawer, ButtonDrawerEntry } from '../button-drawer/ButtonDrawer'
import { ConfirmationDialog, DialogHandle } from '../confirmation-dialog/ConfirmationDialog'
import { Dropdown } from '../dropdowns/Dropdowns'
import { FormAutocomplete, FormDatePicker, FormTextField } from '../forms/FormComponents'
import { useCommonStyles } from '../styling/commonStyles'
import { SelectionState } from './DataGridCheckboxController'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            maxHeight: 48
        },
        grow: {
            flexGrow: 1,
        },
        summary: {
            borderBottom: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.divider,
            padding: theme.spacing(0),
        },
        search: {
            position: 'relative',
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            // marginLeft: theme.spacing(1),
            // marginRight: theme.spacing(1),
            width: '100%',
            flexGrow: 1,
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
            flexGrow: 1,
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 1),
            transition: theme.transitions.create('width'),
            width: '100%',
            flexGrow: 1,
        },
        filterPopperCard: {
            width: '256px',
        },
        filterPopperHeader: {
            background: theme.palette.primary.main,
        },
        filterPopperTitle: {
            fontWeight: 500,
            color: theme.palette.grey[100],
        },
        searchIcon: {
            margin: theme.spacing(0),
            width: theme.spacing(3),
            height: theme.spacing(3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        searchIconCta: {
            zIndex: 1,
            animation: `0.4s $jump ease infinite alternate`,
        },
        "@keyframes jump": {
            "0%": {
                transform: 'scale(1)',
                boxShadow: '0 0px 1px rgba(0,51,0,.8)',
            },
            "100%": {
                transform: 'scale(1.4)',
                boxShadow: '0 0px 10px rgba(0,51,0,.1)',
            },
        }
    }),
)

export type DataGridToolbarProps = {
    dataGridId: string
    select?: { selectionValue: SelectionState, onSelectionChanged?: (selection: SelectionState) => void }
    export?: () => Promise<BlobResponse | null>
    getApiDescription?: () => Promise<ApiDescription>
    onStateUpdate: (filters: FilterWithValue[], sort: SortCriterionWithValue[]) => void
    otherActions?: ButtonDrawerEntry[]
    columns: ColumnDefinition[]
    onSettingsUpdated: () => void
    refForFullscreen?: any
}

export type SearchStateInput =
    | { kind: 'omnisearch', term: string }
    | { kind: 'filter', filterDescription: FilterDescription, operation: FilterOperation, value: unknown }
    | { kind: 'sort', sortCriterionDescription: SortCriterionDescription, direction: SortDirection }

type CurrentSearchState =
    | { kind: 'filter', filterDescription: FilterDescription }
    | { kind: 'sort', sortCriterionDescription: SortCriterionDescription }

const FILTER_OVERFLOW = 3
const INPUT_ID = 'searchfield'

export const DataGridToolbar: React.FC<DataGridToolbarProps> = props => {
    const classes = useStyles()
    const commonClasses = useCommonStyles()
    const history = useHistory()
    const notifications = useNotificationsContext()
    const { intl } = useInternationalization()

    const [searchState, setSearchState] = useState<{ state: SearchStateInput[], apiDescription?: ApiDescription, loading: boolean }>({ state: [], loading: false })
    const [searchInput, setSearchInput] = useState('')
    const [propertiesFocused, setPropertiesFocused] = useState(false)
    const [propertiesAnchorEl, setPropertiesAnchorEl] = useState<Element | null>(null)
    const [currentSearchState, setCurrentSearchState] = useState<CurrentSearchState>()
    const [filterPopperOpen, setFilterPopperOpen] = useState<{ open: boolean, id?: number, filterOrSorting?: FilterOrSortingPopperState, anchorEl?: HTMLElement }>({ open: false })
    const settingsDialog = useDialogToggle()

    const prefix = props.dataGridId + '.q'
    const encode = (s: {}) => Object.keys(s).length ? Base64.encode(JSON.stringify(s)) : null
    const decode = (s: string) => {
        try {
            return JSON.parse(Base64.decode(s)) as {}
        }
        catch (error) {
            notifications.warningNotification(intl('MessageDataGridToolbarWarning'), <Typography>'{s}' : {error.toString?.()} {error.code}</Typography>)
            return {}
        }
    }

    const onSearchBarKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        switch (event.key) {
            case 'Tab':
            case 'Enter':
                // Intercept Enter so that it doesn't submit
                if (event.key === 'Enter') {
                    event.preventDefault()
                    event.stopPropagation()
                }
                if (!searchState.apiDescription?.filters?.some(f => !!f.operations.some(o => o.isOmniSearch)))
                    return

                const term = event.currentTarget.value
                if (term)
                    setSearchState(st => ({ ...st, state: [...st.state.filter(ss => ss.kind !== 'omnisearch'), { kind: 'omnisearch', term: term }] }))
                setSearchInput('')
                setPropertiesAnchorEl(null)
                break
            case 'Escape':
                event.preventDefault()
                setPropertiesAnchorEl(null)
                break
            case 'ArrowDown':
                setPropertiesFocused(true)
                break
            default:
                if (!event.metaKey && !event.shiftKey && !event.altKey && !event.ctrlKey)
                    setPropertiesAnchorEl(event.currentTarget)
                break
        }
    }, [searchState.apiDescription])

    useEffect(() => {
        const columnIds = props.columns.map(c => c.id)
        if (columnIds.length !== _.uniq(columnIds).length)
            notifications.errorNotification(intl('MessageDataGridToolbarErrorDoubleIds'))

        return () => {
            const params = new URLSearchParams(history.location.search)
            params.delete(prefix)
            history.push({ search: params.toString() })
        }
    }, [])

    useEffect(() => {
        if (props.getApiDescription && !searchState.apiDescription) {
            setSearchState(st => ({ ...st, loading: true }))

            props.getApiDescription?.().then(ad => {
                const currentParams = new URLSearchParams(history.location.search)
                const encoded = currentParams.get(prefix)
                const existing = encoded ? decode(encoded) as {} : {}

                const urlFilters = _.map(existing, (v, k) => ({ key: k, value: v }))

                let newState: SearchStateInput[] = ad.filters.flatMap(f =>
                    f.operations
                        .map(o => ({ operation: o, queryValue: urlFilters.find(kvp => kvp.key === o.queryParam)?.value! }))
                        .filter(m => m.queryValue)
                        .map(m => ({ kind: 'filter' as 'filter', filterDescription: f, operation: m.operation, value: m.queryValue })))

                const sortFilter = urlFilters.find(kvp => kvp.key === 'sort')?.value

                if (sortFilter) {
                    const m: SearchStateInput[] = parseSorting(sortFilter, ad.sortCriteria).map(s => ({ kind: 'sort' as 'sort', sortCriterionDescription: s, direction: s.direction }))
                    newState.push(...m)
                }

                if (newState.length === 0) {
                    const fs: SearchStateInput[] = ad.filters
                        .flatMap(f =>
                            f.operations
                                .filter(o => o.default)
                                .map(o => ({ kind: 'filter' as 'filter', filterDescription: f, operation: o, value: o.default })))
                    const ss: SearchStateInput[] = ad.sortCriteria
                        .filter(s => s.hasDefault)
                        .map(s => ({ kind: 'sort', sortCriterionDescription: s, direction: s.hasDefault === s.queryParamValueAscending ? 'asc' : 'desc' }))
                    newState = [...fs, ...ss]
                }
                setSearchState(st => ({ ...st, state: newState, apiDescription: ad, loading: false }))
            })
        }
        else {
            const search = transformState(searchState.state)
            const currentParams = new URLSearchParams(history.location.search)
            const nextParams: { [key: string]: any } = {}

            _.forEach(mapFiltersToQueryParamsObject(search[0]), (value, key) => {
                nextParams[key] = value?.toString?.()
            })
            const sort = mapSortingToQueryParamObject(search[1]).sort
            if (sort) nextParams['sort'] = sort

            const nextSearchParams = new URLSearchParams()

            const encoded = encode(nextParams)

            currentParams.forEach((value, key) => {
                if (!key.startsWith(prefix))
                    nextSearchParams.append(key, value)
                else if (encoded)
                    nextSearchParams.append(prefix, encoded)
            })
            if (!nextSearchParams.has(prefix) && encoded)
                nextSearchParams.append(prefix, encoded)

            history.push({ search: nextSearchParams.toString() })
            props.onStateUpdate(...search)
        }
    }, [searchState.state])

    const removeFilter = (p: { queryParam?: string, direction?: SortDirection, omniSearch?: boolean }) => {
        setSearchState(st => ({
            ...st,
            state: st.state.filter(x => !(x.kind === 'filter' && x.operation.queryParam === p.queryParam) && !(x.kind === 'sort' && x.direction === p.direction) && !(x.kind === 'omnisearch' && p.omniSearch))
        }))
    }

    const getFilterAndSortAdornmentsElems = useCallback(function* () {
        for (const el of _.sortBy(searchState.state.map((x, i) => ({ elem: x, idx: i })), x => x.elem.kind)) {
            const { elem: ss, idx } = el
            switch (ss.kind) {
                case 'omnisearch':
                    yield <Chip key={el.idx} className={commonClasses.chip} icon={<Icon path={mdiAsterisk} color='white' size={0.5} />} onDelete={() => removeFilter({ omniSearch: true })} color='primary' label={`:'${ss.term}'`} />; break
                case 'filter':
                    yield <Chip key={el.idx} className={commonClasses.chip}
                        onDelete={() => removeFilter({ queryParam: ss.operation.queryParam })}
                        onClick={e => setFilterPopperOpen({ open: true, id: idx, filterOrSorting: ss, anchorEl: e.currentTarget })}
                        color='primary'
                        label={`${ss.filterDescription.propertyName} '${ss.operation.operationDisplay}'`} />
                    break
                case 'sort':
                    yield <Chip key={el.idx} className={commonClasses.chip}
                        icon={<Icon path={ss.direction === 'asc' ? mdiSortAscending : mdiSortDescending} color='white' size={0.5} />}
                        onDelete={() => removeFilter({ direction: ss.direction })}
                        onClick={e => setFilterPopperOpen({ open: true, id: idx, filterOrSorting: ss, anchorEl: e.currentTarget })}
                        color='primary'
                        label={`${ss.sortCriterionDescription.propertyName}`} />
                    break
            }
        }
    }, [searchState.state])

    const getEndAdornmentsElems = useCallback(function* () {
        if (searchState.state.length <= FILTER_OVERFLOW) {
            for (const x of Array.from(getFilterAndSortAdornmentsElems()))
                yield x
        }
        yield <IconButton key='clear' color='primary' onClick={() => { setSearchState(st => ({ ...st, state: [] })); setSearchInput('') }} ><Clear /></IconButton>
        // if (props.refForFullscreen)
        //     yield <FullscreenButton key='fscreen' fontSize='small' element={props.refForFullscreen} />
        const entries = Array.from(function* () {
            if (props.export)
                yield {
                    label: <Typography>{intl('ExportExcel')}</Typography>,
                    onClick: () => props.export?.()?.then(blob => blob && viewOrDownloadFile(blob, 'download', blob.filename ?? "export.xlsx"))
                }
            if (searchState.apiDescription?.filters?.length || searchState.apiDescription?.sortCriteria?.length)
                yield {
                    label: <Typography>{intl('FilterAdd')}</Typography>,
                    onClick: () => setPropertiesAnchorEl(document.getElementById(INPUT_ID))
                }
            yield {
                label: <Typography>{intl('Settings')}</Typography>,
                onClick: () => settingsDialog.openDialog()
            }
            for (const a of props.otherActions ?? [])
                yield a
        }())
        if (entries.length > 0)
            yield <ButtonDrawer key='drawer' size='medium' entries={entries} />
    }, [getFilterAndSortAdornmentsElems, searchState.state.length, searchState.apiDescription?.filters?.length, searchState.apiDescription?.sortCriteria?.length, settingsDialog, props.otherActions, props.refForFullscreen, FILTER_OVERFLOW])

    const getEndAdornments = useCallback(() => Array.from(getEndAdornmentsElems()), [getEndAdornmentsElems])

    const getFilterAndSortAdornments = useCallback(() => Array.from(getFilterAndSortAdornmentsElems()), [getFilterAndSortAdornmentsElems])

    const transformState = useCallback((state: SearchStateInput[]): [FilterWithValue[], SortCriterionWithValue[]] => {
        const filters: FilterWithValue[] = []
        const sort: SortCriterionWithValue[] = []

        for (const s of state) {
            switch (s.kind) {
                case 'filter':
                    filters.push({ operationType: s.operation.operationType, queryParam: s.operation.queryParam, value: s.value })
                    continue
                case 'sort':
                    sort.push({ queryParamValueAscending: s.sortCriterionDescription.queryParamValueAscending, queryParamValueDescending: s.sortCriterionDescription.queryParamValueDescending, direction: s.direction })
                    continue
                case 'omnisearch':
                    const omni = searchState.apiDescription?.filters?.flatMap(f => f.operations)?.find(o => o.isOmniSearch)
                    if (omni === undefined) unexpected()
                    filters.push({ operationType: omni!.operationType, queryParam: omni!.queryParam, value: s.term })
                    continue
                default: return caseNever(s)
            }
        }

        return [filters, sort]
    }, [searchState])

    return (
        <React.Fragment>
            <Toolbar style={{ margin: 0, paddingLeft: 8, paddingRight: 0 }} variant='dense'>
                {props.getApiDescription &&
                    <div className={classes.search}>
                        <InputBase
                            id={INPUT_ID}
                            value={searchInput}
                            autoComplete='off'
                            startAdornment={<IconButton disabled={searchState.loading} className={classes.searchIcon}
                                onClick={() => props.onStateUpdate(...transformState(searchState.state))}>
                                <Search color='primary' />
                            </IconButton>}
                            endAdornment={getEndAdornments()}
                            color='primary'
                            placeholder={intl('Search')}
                            classes={{ root: classes.inputRoot, input: classes.inputInput, }}
                            onChange={e => setSearchInput(e.target.value.trimStart())}
                            onFocus={e => { e.preventDefault(); e.stopPropagation(); setPropertiesAnchorEl(e.currentTarget) }}
                            onKeyDown={onSearchBarKeyDown} />
                    </div>}
            </Toolbar>
            {searchState.state.length > FILTER_OVERFLOW ?
                <div style={{ width: '100%', marginLeft: 0, marginRight: 0, marginBottom: 0 }} className={commonClasses.chipContainer}>
                    {getFilterAndSortAdornments()}
                </div>
                : null}
            {propertiesAnchorEl ? <Popper open={Boolean(propertiesAnchorEl)} anchorEl={propertiesAnchorEl} placement='bottom-start' onClick={e => e.preventDefault()} style={{ maxHeight: '50vh', zIndex: 120 }} >
                <ClickAwayListener onClickAway={e => { const target: any = e.target; if (target.id !== INPUT_ID) setPropertiesAnchorEl(null) }}>
                    <Paper elevation={3} style={{ maxHeight: '50vh', overflowY: 'auto' }} onKeyDown={e => e.key === 'Escape' && setPropertiesAnchorEl(null)} >
                        <MenuList variant='menu' autoFocusItem={propertiesFocused} onBlur={() => setPropertiesFocused(false)}>
                            {
                                searchState.apiDescription
                                    ?.filters
                                    ?.filter(f => !searchInput || isFuzzyTextMatch(searchInput, f.propertyName))
                                    ?.map(f => <MenuItem key={'f' + f.propertyName} button onClick={e => { e.preventDefault(); setCurrentSearchState({ kind: 'filter', filterDescription: f }); setPropertiesAnchorEl(null); setFilterPopperOpen({ open: true }) }}>
                                        <ListItemIcon><Icon path={mdiFilterMenuOutline} color='primary' size={0.9} /></ListItemIcon>
                                        <ListItemText color='primary'><Typography>{f.propertyName}</Typography></ListItemText>
                                    </MenuItem>)
                            }
                            {
                                searchState.apiDescription
                                    ?.sortCriteria
                                    ?.filter(f => !searchInput || isFuzzyTextMatch(searchInput, f.propertyName))
                                    ?.map(f => <MenuItem key={'s' + f.propertyName} button onClick={e => { e.preventDefault(); setCurrentSearchState({ kind: 'sort', sortCriterionDescription: f }); setPropertiesAnchorEl(null); setFilterPopperOpen({ open: true }) }}>
                                        <ListItemIcon><Icon path={mdiSort} color='primary' size={0.9} /></ListItemIcon>
                                        <ListItemText color='primary'><Typography>{f.propertyName}</Typography></ListItemText>
                                    </MenuItem>)
                            }
                            {
                                searchState.apiDescription
                                    ?.filters
                                    ?.filter(f => !!f.operations.find(o => o.isOmniSearch))
                                    ?.map((f, i) =>
                                        <Tooltip key={i} title={intl('MessageDataGridToolbarOmniSearch')}>
                                            <MenuItem key={i}>
                                                <ListItemIcon><Icon path={mdiAsterisk} color='primary' size={0.9} /></ListItemIcon>
                                                <ListItemText>
                                                    <i>{intl('SearchTerm')} '{searchInput}' {intl('FieldIn')} '{f.propertyName}'</i>
                                                </ListItemText>
                                            </MenuItem>
                                        </Tooltip>
                                    )
                            }
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper> : null}

            {filterPopperOpen.open ? <FilterOrSortingPopper
                open={filterPopperOpen.open} anchorEl={filterPopperOpen.anchorEl ?? document.getElementById(INPUT_ID)}
                filterOrSorting={filterPopperOpen.filterOrSorting ?? currentSearchState ?? unexpected()}
                id={filterPopperOpen.id}
                onCancel={() => setFilterPopperOpen({ open: false })}
                onSubmit={(sid, s) => {
                    setSearchState(st => {
                        const other = st.state.filter((_ss, id) => id !== sid)
                        switch (s.kind) {
                            case 'filter':
                                setFilterPopperOpen({ open: false })
                                setCurrentSearchState(undefined)
                                return {
                                    ...st,
                                    state: [...other, { kind: 'filter', filterDescription: s.filterDescription, operation: s.operation, value: s.value }]
                                }
                            case 'sort':
                                setFilterPopperOpen({ open: false })
                                setCurrentSearchState(undefined)
                                return {
                                    ...st,
                                    state: [...other, { kind: 'sort', sortCriterionDescription: s.sortCriterionDescription, direction: s.direction }]
                                }
                            default: return unexpected()
                        }
                    })
                }} /> : null}
            <DataGridSettingsDialog dialogHandle={settingsDialog} dataGridId={props.dataGridId} columns={props.columns} onSettingsUpdated={props.onSettingsUpdated} />
        </React.Fragment>
    )
}

type FilterOrSortingPopperState =
    | { kind: 'filter', filterDescription: FilterDescription, operation?: FilterOperation, value?: unknown }
    | { kind: 'sort', sortCriterionDescription: SortCriterionDescription, direction?: SortDirection }

const FilterOrSortingPopper = (props: {
    open: boolean
    id: number | undefined
    anchorEl: HTMLElement | null
    filterOrSorting: FilterOrSortingPopperState
    onSubmit: (id: number | undefined, e: Required<FilterOrSortingPopperState>) => void
    onCancel: () => void
}) => {
    const classes = useStyles()
    const commonStyles = useCommonStyles()
    const { intl } = useInternationalization()

    const [state, setState] = useState<FilterOrSortingPopperState>()

    useEffect(() => { setState(props.filterOrSorting) }, [props.filterOrSorting])

    const onSubmit = () => {
        switch (state?.kind) {
            case 'filter':
                if (state.operation && state.value)
                    return props.onSubmit(props.id, { ...state, operation: state.operation, value: state.value })
                return unexpected()
            case 'sort':
                if (state.direction)
                    return props.onSubmit(props.id, { ...state, direction: state.direction })
                return unexpected()
            default:
                return unexpected()
        }
    }

    return (
        <Popper open={props.open} anchorEl={props.anchorEl} placement='bottom-start' style={{ zIndex: 110 }} transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Card className={classes.filterPopperCard}>
                        {state?.kind === 'filter' ?
                            <React.Fragment>
                                <CardContent className={classes.filterPopperHeader}>
                                    <Typography className={classes.filterPopperTitle} component='h2'>{state.filterDescription.propertyName}</Typography>
                                </CardContent>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Dropdown grid-item xs={12} hideEmpty label={intl('Filter')} selectIfSingle
                                            value={state.operation?.queryParam}
                                            onChange={e => { setState({ ...state, operation: state.filterDescription.operations.find(o => o.queryParam === e) }) }}
                                            getData={async () => state.filterDescription.operations}
                                            idSelector={x => x.queryParam} displaySelector={x => x.operationDisplay} />

                                        {(() => {
                                            const typ = state.operation?.operationType
                                            const inputType = state.operation?.operationInputType
                                            switch (typ) {
                                                case undefined:
                                                case null:
                                                    return null
                                                case 'eq':
                                                case 'sw':
                                                case 'lte':
                                                case 'lt':
                                                case 'cont':
                                                case 'gte':
                                                case 'gt':
                                                    switch (inputType) {
                                                        case 'text':
                                                            return <FormTextField grid-item xs={12} label={intl('Value')} value={state.value} onChange={e => setState({ ...state, value: e.target.value })} />
                                                        case 'number':
                                                            return <FormTextField grid-item xs={12} label={intl('Value')} type='number' value={state.value} onChange={e => setState({ ...state, value: e.target.value })} />
                                                        case 'date':
                                                            return <FormDatePicker grid-item xs={12} label={intl('Date')} value={state.value as Date || null} onChange={e => setState({ ...state, value: e })} />
                                                        case undefined:
                                                        case 'text-list':
                                                            return unexpected()
                                                        default:
                                                            return caseNever(inputType)
                                                    }
                                                case 'one-of':
                                                case 'all-of':
                                                case 'not-one-of':
                                                    switch (inputType) {
                                                        case 'number':
                                                            return <FormAutocomplete grid-item xs={12} label={intl('Value')}
                                                                getData={async () => {
                                                                    const r = state.operation?.restrictions
                                                                    if (!r) return []
                                                                    if (r.type === 'range') throw Error(intl('MessageNotImplemented'))
                                                                    else
                                                                        return r.permittedValues.map(v => (typeof v === 'string' || typeof v === 'number') ? null! : v).filter(x => x)
                                                                }}
                                                                displaySelector={x => x.display} idSelector={x => x.id}
                                                                autocompleteProps={{ disableCloseOnSelect: true, openOnFocus: true, clearOnBlur: true, handleHomeEndKeys: true }}
                                                                valueIds={(state.value as string | undefined)?.split(',')?.map(x => Number.parseInt(x)) ?? []} onChange={e => setState({ ...state, value: e?.map(x => x.id).join(',') })} />
                                                        case 'text-list':
                                                            return <FormTextField
                                                                label={intl('Values')} helperText={intl('MessageDataGridToolbarDiffLines')} multiline rowsMax={5}
                                                                value={(state.value as any)?.split(',').join('\n') || ''}
                                                                onChange={e => setState({ ...state, value: e.target.value.split('\n').join(',') })} />
                                                        case undefined:
                                                        case 'date':
                                                        case 'text':
                                                            return unexpected()
                                                        default: return caseNever(inputType)

                                                    }
                                                default: return caseNever(typ)
                                            }
                                        })()}
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <div className={commonStyles.grow} />
                                    <Button onClick={props.onCancel}>{intl('CANCEL')}</Button>
                                    <Button disabled={!state.operation || !state.value} color='primary' onClick={onSubmit}>{intl('OK')}</Button>
                                </CardActions>
                            </React.Fragment>
                            : state?.kind === 'sort' ?
                                <React.Fragment>
                                    <CardContent className={classes.filterPopperHeader}>
                                        <Typography className={classes.filterPopperTitle} component='h2'>{state.sortCriterionDescription.propertyName}</Typography>
                                    </CardContent>
                                    <CardContent>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{intl('Sorting')}</FormLabel>
                                            <RadioGroup value={state.direction} onChange={e => setState({ ...state, direction: e.target.value as SortDirection })}>
                                                <FormControlLabel value='asc' control={<Radio />} label={intl('Ascending')} />
                                                <FormControlLabel value='desc' control={<Radio />} label={intl('Descending')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </CardContent>
                                    <CardActions>
                                        <div className={commonStyles.grow} />
                                        <Button onClick={props.onCancel}>{intl('CANCEL')}</Button>
                                        <Button disabled={!state.direction} color='primary' onClick={onSubmit}>{intl('OK')}</Button>
                                    </CardActions>
                                </React.Fragment>
                                : undefined}
                    </Card>
                </Fade>
            )}
        </Popper>
    )
}

type ColumnDefinition = { id: React.Key, columnName: React.ReactNode }
export const columnKey = (d: ColumnDefinition) => d.id

export const loadSettings = async (id: string) => {
    const db = await openApplicationIdb()
    return await db.get('datagrid-settings', id)
}

const DataGridSettingsDialog = (props: { dialogHandle: DialogHandle, dataGridId: string, columns: ColumnDefinition[], onSettingsUpdated: () => void }) => {
    const { intl } = useInternationalization()

    const [settings, setSettings] = useState<StoredSettings>({ dataGridId: props.dataGridId, columnsOrdered: [] })

    useEffect(() => {
        if (props.dialogHandle.dialog.open)
            loadSettings(props.dataGridId).then(ts => {
                if (ts)
                    setSettings({ ...ts, columnsOrdered: [...ts.columnsOrdered, ...new Array(props.columns.length - ts.columnsOrdered.length)] })
                else
                    setSettings({ dataGridId: props.dataGridId, columnsOrdered: props.columns.map(c => columnKey(c)) })
            })
    }, [props.dataGridId, props.dialogHandle.dialog.open])

    const handleChange = useCallback((index: number) => (c?: React.Key) => {
        setSettings(st => {
            return {
                ...st,
                columnsOrdered: st.columnsOrdered.map((cl, i) => i === index ? c === undefined ? undefined! : columnKey(props.columns.find(col => columnKey(col) === c) ?? unexpected()) : cl)
            }
        })
    }, [props.columns])

    const saveSettings = useCallback(async () => {
        const db = await openApplicationIdb()
        await db.put('datagrid-settings', { ...settings, columnsOrdered: settings.columnsOrdered.filter(x => x) })
        props.onSettingsUpdated()
        return true
    }, [settings])

    return <ConfirmationDialog handle={props.dialogHandle} onConfirm={saveSettings}>
        <Box>
            <Typography>{intl('Columns')}</Typography>
            {props.columns
                .map((_, i) =>
                    <Dropdown<ColumnDefinition, React.Key>
                        key={i} label={intl('Column') + ` ${i + 1}`}
                        value={settings.columnsOrdered[i]}
                        getData={() => Promise.resolve(props.columns)}
                        idSelector={x => columnKey(x)}
                        displaySelector={x => props.columns.find(c => columnKey(c) === columnKey(x))?.columnName}
                        onChange={handleChange(i)}
                    />
                )}
        </Box>
    </ConfirmationDialog>
}