import { AccountInfo, LogLevel, PublicClientApplication } from "@azure/msal-browser"
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { Environment } from "../../ConfigurationInjection"
import { caseNever } from "../utils/never"

export const getLoggerOptions = (env: Environment) =>
    ({
        logLevel: env === Environment.Production ? LogLevel.Warning : LogLevel.Verbose,
        piiLoggingEnabled: env !== Environment.Production,
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
            if (env === Environment.Production && containsPii) return
            switch (level) {
                case LogLevel.Error: return console.error(`[MSAL] ${message}`)
                case LogLevel.Info: return console.log(`[MSAL] ${message}`)
                case LogLevel.Verbose: return console.debug(`[MSAL] ${message}`)
                case LogLevel.Warning: return console.warn(`[MSAL] ${message}`)
                default: return caseNever(level)
            }
        }
    })

export type AuthorizationContextProps =
    {
        config: {
            msal: PublicClientApplication
            loginScopes: string[]
            tokenScopes: string[]
        }
    }

export type AuthorizationContextType =
    {
        login: () => Promise<void>
        logout: () => Promise<void>
        getAccessToken: () => Promise<string>
        accountInfo: AccountInfo | undefined
    }

export const useAuthenticationContext = () => useContext(AuthenticationContext)

const AuthenticationContext = createContext<AuthorizationContextType>(undefined as any)

export const AuthenticationContextProvider: React.FC<AuthorizationContextProps> = props => {
    const { config: { msal, loginScopes, tokenScopes } } = props
    const [accountInfo, setAccountInfo] = useState<AccountInfo>()
    const extraParams = { ui_locales: 'el' }

    useEffect(() => {
        msal.handleRedirectPromise().then(response => setAccountInfo(response?.account ?? msal.getAllAccounts()[0]))
    }, [])

    const login = useCallback(() => msal.loginRedirect({ account: accountInfo, scopes: loginScopes, extraQueryParameters: extraParams }), [accountInfo])

    const logout = () => msal.logout()

    const getAccessToken = useCallback(async () => {
        let acc = accountInfo ? msal.getAccountByHomeId(accountInfo.homeAccountId) : undefined
        acc = acc ?? msal.getAllAccounts()[0]
        const accessTokenRequest = { account: acc, scopes: tokenScopes }
        try {
            const rs = await msal.acquireTokenSilent(accessTokenRequest)
            return rs.accessToken
        }
        catch (e) {
            if (e?.errorMessage?.indexOf("interaction_required") !== -1) {
                try {
                    const token = await msal.acquireTokenPopup(accessTokenRequest)
                    return token.accessToken
                }
                catch (e) {
                    console.error(e)
                    throw e
                }
            }
            console.error(e)
            throw e
        }
    }, [accountInfo])

    const context = {
        login,
        logout,
        getAccessToken,
        accountInfo,
    }

    return <AuthenticationContext.Provider value={context}>
        {props.children}
    </AuthenticationContext.Provider>
}