import { useCallback, useState } from "react";

export type DialogHandle = {
    dialog: {
        open: boolean;
        id: number | undefined;
    },
    openDialog: (id?: number | undefined) => void,
    closeDialog: () => void,
}

export type RefreshHandle = 
    {
        toggle: boolean
        switchToggle: (value?: boolean | undefined) => void
    }

export const useToggle = (initial?: boolean) : RefreshHandle => {
    const [toggle, setToggle] = useState(initial ?? false)

    const switchToggle = useCallback((value?: boolean) => setToggle(st => value === undefined ? !st : value), [setToggle])

    return {
        toggle,
        switchToggle,
    }
}

export const useDialogToggle : () => DialogHandle = () => {
    const [dialog, setDialog] = useState({ open: false, id: undefined as number | undefined })

    const openDialog = useCallback((id?: number) => setDialog({ open: true, id: id }), [])
    const closeDialog = useCallback(() => setDialog({ open: false, id : undefined }), [])

    return {
        dialog,
        openDialog,
        closeDialog
    }
}