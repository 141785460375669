import React, { createContext, useCallback, useContext, useState } from "react"

type FullscreenContextType =
    {
        enterFullscreen: (elem: HTMLElement) => void
        exitFullscreen: () => void
        isFullscreen: boolean
        isCurrentElement: (e : HTMLElement) => boolean
    }

const FullscreenContext = createContext(null as unknown as FullscreenContextType)

export const useFullscreenContext = () => {
    const ctx = useContext(FullscreenContext)
    return ctx
}

export const FullscreenContextProvider : React.FC = props => {
    const [state, setState] = useState({ fullscreen: false, element: null as HTMLElement | null})

    const value : FullscreenContextType = {
        enterFullscreen: useCallback((elem: HTMLElement) => setState({ fullscreen: true, element: elem }), []),
        exitFullscreen: useCallback(() => setState({ fullscreen: false, element: null }), []),
        isFullscreen: state.fullscreen,
        isCurrentElement: (elem : HTMLElement) => state.element === elem,
    }

    return (
        <FullscreenContext.Provider value={value}>
            {props.children}
        </FullscreenContext.Provider>
    )
}