import { Badge, Card, CardHeader, Grid, Tooltip, Typography } from "@material-ui/core";
import { Assignment, CloudUpload, Inbox } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useApiContext } from "../../wtf/api/ApiContext";
import { ExternalUserSummary } from "../../wtf/api/ExternalUsers";
import routes from "../Shared/routes";
import { useStyles } from "./App";



export const HomePage = () => {
    const classes = useStyles();
    const api = useApiContext();
    const history = useHistory();

    const [summary, setSummary] = useState<ExternalUserSummary>();

    useEffect(() => {
        api.externalUsers.getSummary().then(s => setSummary(s));
    }, []);

    return <div className={classes.homeRoot}>
        <Grid container spacing={4} alignItems="center" justify="center" className={classes.actionsGrid}>

            <Grid item xs={12} md={4}>
                <Card className={summary?.canCreateApplication ? classes.cardHover : classes.cardHoverDisabled} onClick={() => summary?.canCreateApplication ? history.push(routes.APPLICATION.template) : {}}>
                    <CardHeader
                        title={<Typography color='primary' variant='h5'>Αίτηση Ένταξης</Typography>}
                        avatar={<Badge color='error' invisible={!summary?.applicationId} variant='dot'><Assignment color='primary' /></Badge>}
                        subheader='Συμπλήρωση και υποβολή Αίτησης Ένταξης στο Σύστημα Πιστοποίησης Βιολογικών Προϊόντων' />
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Tooltip title={summary?.canUploadDocuments ? '' : 'Μπορείτε να κατεβάσετε έγγραφα μόνο αφού αν έχετε υποβάλει Αίτηση Ένταξης ή είστε υπάρχων πελάτης'}>
                    <Card className={summary?.canUploadDocuments ? classes.cardHover : classes.cardHoverDisabled} onClick={() => summary?.canUploadDocuments ? history.push(routes.INBOX.template) : {}}>
                        <CardHeader
                            title={<Typography color='primary' variant='h5'>Εισερχόμενα Έγγραφα</Typography>}
                            avatar={<Badge badgeContent={summary?.unreadDocuments}><Inbox color='primary' /></Badge>}
                            subheader='Κατεβάστε τα αποδεικτικά έγγραφα συμμόρφωσης, τις βεβαιώσεις, τη σύμβασή σας, κ.α.' />
                    </Card>
                </Tooltip>
            </Grid>
            <Grid item xs={12} md={4}>
                <Tooltip title={summary?.canUploadDocuments ? '' : 'Μπορείτε να ανεβάσετε έγγραφα μόνο αφού αν έχετε υποβάλει Αίτηση Ένταξης ή είστε υπάρχων πελάτης'}>
                    <Card className={summary?.canUploadDocuments ? classes.cardHover : classes.cardHoverDisabled} onClick={() => summary?.canUploadDocuments ? history.push(routes.UPLOAD_DOCS.template) : {}}>
                        <CardHeader
                            title={<Typography color='primary' variant='h5'>Ανέβασμα Εγγράφων</Typography>}
                            avatar={<CloudUpload color='primary' />}
                            subheader='Υποβάλετε τα απαραίτητα έγγραφα στον Φορέα (αποδεικτικά πληρωμής, τεκμηρίωση κτλ)' />
                    </Card>
                </Tooltip>
            </Grid>
        </Grid>
    </div>;
};
