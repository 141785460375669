import { stringify } from "querystring"
import { HrefOrExplicitRequest } from "../components/datagrid/ReadOnlyDataGrid"
import { caseNever } from "../utils/never"
import { toISOStringWIthTimezone } from "../utils/utils"
import { ApiClient, BlobResponse } from "./ApiClient"

export type Operation = "eq" | "sw" | 'one-of' | 'all-of' | 'not-one-of' | 'lte' | 'gte' | 'lt' | 'gt' | 'cont'

export type ApiDescription =
    {
        filters: FilterDescription[]
        sortCriteria: SortCriterionDescription[]
    }

export const EMPTY_API_DESCRIPTION: ApiDescription =
{
    filters: [],
    sortCriteria: []
}

export type FilterDescription =
    {
        propertyName: string
        operations: FilterOperation[]
    }

export type FilterOperation =
    {
        queryParam: string
        operationType: Operation
        operationDisplay: string
        operationInputType: 'text' | 'number' | 'date' | 'text-list'
        isOmniSearch: boolean
        default?: any
        restrictions?: FilterRestriction<string | number | { id: number, display: string }>
    }

export type FilterRestriction<T> =
    | { type: "values", permittedValues: T[] }
    | { type: "range", min?: T, max?: T }

export type FilterWithValue = { queryParam: string, operationType: Operation, value: any }

export const mkFilterQueryParam = (op: Operation, param: string) => param + '.' + op

export const mkFilterWithValue = (op: Operation, param: string, value: any): FilterWithValue =>
    ({ queryParam: mkFilterQueryParam(op, param), operationType: op, value: value })

export function mapFiltersToQueryParamsObject(filters: FilterWithValue[]) {
    let x: { [param: string]: any } = {}
    for (const f of filters) {
        switch (f.operationType) {
            case 'eq':
            case 'sw':
            case 'cont':
            case 'lte':
            case 'gte':
            case 'lt':
            case 'gt':
            case 'one-of':
            case 'all-of':
            case 'not-one-of':
                x[f.queryParam] = f.value.toISOString ? toISOStringWIthTimezone(f.value) : f.value
                break
            default: caseNever(f.operationType)
        }
    }

    return x;
}

export type SortCriterionDescription =
    {
        propertyName: string
        queryParamValueAscending: string
        queryParamValueDescending: string
        hasDefault?: string
    }

export type SortDirection = "asc" | "desc"

export type SortCriterionWithValue = { queryParamValueAscending: string, queryParamValueDescending: string, direction: SortDirection }

export const mkSortingWithValue = (param: string, direction: SortDirection): SortCriterionWithValue =>
    ({ direction: direction, queryParamValueAscending: param, queryParamValueDescending: `-${param}` })

export const mapSortingToQueryParamObject = (sorting: SortCriterionWithValue[]) =>
    ({
        sort: sorting.map(s => s.direction === 'asc' ? s.queryParamValueAscending : s.queryParamValueDescending).join(',')
    })

export const parseSorting = (str: string, allSorting: SortCriterionDescription[]): (SortCriterionDescription & SortCriterionWithValue)[] =>
    str.split(',')
        .map(s => {
            const found = allSorting.find(m => m.queryParamValueAscending === s || m.queryParamValueDescending === s)
            if (found?.queryParamValueAscending === s) return { ...found, direction: 'asc' }
            if (found?.queryParamValueDescending === s) return { ...found, direction: 'desc' }
        })
        .filter(s => s) as any

export type PaginationRequest =
    {
        page: number
        size: number
    }

export type Hrefs =
    {
        first: string
        next?: string
        previous?: string
        last?: string
    }

export type PaginationResponse =
    {
        page: number
        size: number
        totalCount: number
        hrefs: Hrefs
    }

// NB Both an internal client type (used by components) and a client-server contract
export type Paginated<T> =
    {
        items: T[]
        pagination: PaginationResponse
    }

export const fakePaginated = <T>(items: T[]): Paginated<T> => {
    return {
        items: items,
        pagination: {
            page: 0,
            size: items.length,
            totalCount: items.length,
            hrefs: null as unknown as Hrefs
        }
    }
}

export const paginationAll: PaginationRequest =
{
    page: 0,
    size: -1,
}

export const getHref = (req: HrefOrExplicitRequest, baseUrl: string) => {
    if (typeof req === 'string') return req
    const fs = mapFiltersToQueryParamsObject(req.filters)
    const sort = mapSortingToQueryParamObject(req.sorting)
    const queryParams = { ...req.pagination, ...fs, ...sort }
    return `${baseUrl}?${stringify(queryParams)}`
}

export const getExport = (api: ApiClient, baseUrl: string, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) =>
    api.onUnexpected(api.execute<BlobResponse>(200, 'GET', baseUrl, { ...mapFiltersToQueryParamsObject(filters), ...mapSortingToQueryParamObject(sorting) }, undefined, 60_000, undefined, undefined, true), "Could not export given search to Excel", null)

export const getPaginated = <T>(api: ApiClient, href: string) =>
    api.onUnexpected(api.execute<Paginated<T>>(200, 'GET', href), "Could not load resource", fakePaginated([] as T[]))

export const getApiDescription = (api: ApiClient, url: string) =>
    api.onUnexpected(api.execute<ApiDescription>(200, 'OPTIONS', url), "Could not load api description", EMPTY_API_DESCRIPTION)