import { Button, Grid } from '@material-ui/core'
import { Add, Refresh } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useInternationalization } from '../../internationalization/Internationalization'
import { useToggle } from '../../refresh/Toggle'
import { useCommonStyles } from '../styling/commonStyles'
import { ReadOnlyDataGrid, ReadOnlyDataGridProps } from './ReadOnlyDataGrid'

export type ReadonlyDataGridWithActionsProps<T> =
    ReadOnlyDataGridProps<T> &
    {
        onAddClick?: () => void
        addText?: string
        showRefresh?: boolean
        refreshText?: string
        onAfterRefresh?: () => void
    }

export function ReadonlyDataGridWithActions<T>(props: ReadonlyDataGridWithActionsProps<T>) {
    const classes = useCommonStyles()
    const { intl } = useInternationalization()

    const { toggle: dataToggle, switchToggle: refreshData } = useToggle()
    const { onAddClick, showRefresh, onAfterRefresh, refreshToggle, addText, refreshText, ...other } = props

    useEffect(() => { refreshData() }, [refreshToggle])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <ReadOnlyDataGrid refreshToggle={showRefresh ? dataToggle : undefined} {...other} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.buttonContainer}>
                    {showRefresh && <Button key={1} color="default" variant="contained" size="small" className={classes.button} onClick={() => { refreshData(); onAfterRefresh?.() }} endIcon={<Refresh />}>{refreshText ?? intl('REFRESH')}</Button>}
                    {onAddClick && <Button key={2} color="primary" variant="contained" size="small" className={classes.button} onClick={() => onAddClick?.()} endIcon={<Add />}>{addText ?? intl('ADD')}</Button>}
                </div>
            </Grid>
        </Grid>
    )
}