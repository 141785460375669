import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from "react";
import { BUILD_DATE, COMMIT, ENVIRONMENT, VERSION } from "../../ConfigurationInjection";

const useStyles = makeStyles(theme => ({
    footer: {
        color: theme.palette.primary.main,
        cursor: 'grab',
        bottom: 9,
        left: '50%',
        transform: 'translateX(-50%)',
        position: 'fixed',
        zIndex: 1024,
    },
}));

export const Footer = () => {
    const classes = useStyles()
    const year = new Date().getFullYear()
    const message = year === 2021 ? '2021' : '2021 - ' + year
    const [copyrights, setCopyrights] = useState(true)

    return <div className={classes.footer} onClick={() => setCopyrights(st => !st)}>
        {copyrights ? `Bio Hellas S.A. © ${message}.` : `Developed by techyotic v${VERSION} - ${COMMIT} - ${BUILD_DATE} - env${ENVIRONMENT}`}
    </div>
}