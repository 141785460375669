import { ApiClient, BlobResponse } from "./wtf/api/ApiClient"

export type FirstApplication =
    {
        applicationId?: number
        dateOfApplication: Date
        details: CustomerDetailsModel
        taxRegistrationNumber?: string
        note?: string
        activityGroups: BasicActivityGroupId[]
    }

export type CustomerDetailsModel = {
    legalTypeId: LegalTypeId
    firstName?: string
    distinctiveTitle: string
    lastName?: string
    dateOfBirth?: Date
    previousOrganizationId?: number
    previousOrganizationDate?: Date
    taxOfficeId?: number
    contactInformation?: ContactInformation
    address?: Partial<CustomerAddress>
    mailingAddress?: Partial<CustomerAddress>

    businessTitle?: string
    legalRepresentative?: Partial<LegalRepresentativeBase>

    distinctiveTitleLatin?: string
    businessTitleLatin?: string
    firstNameLatin?: string
    lastNameLatin?: string
}

export type LegalRepresentativeBase =
    {
        firstName: string
        lastName: string
        middleName?: string
        nationalIdentityNumber: string
        contactInformation: Partial<ContactInformation>
    }

export type ContactInformation =
    {
        landlineNumber: ContactInformationComponent[]
        mobileNumber: ContactInformationComponent[]
        faxNumber: ContactInformationComponent[]
        email: ContactInformationComponent[]
        website: ContactInformationComponent[]
    }

export type ContactInformationComponent =
    {
        value: string
    }

export const EMPTY_CUSTOMER_DETAILS: CustomerDetailsModel =
{
    distinctiveTitle: '',
    legalTypeId: 0,
}

export const EMPTY_APPLICATION_FORM: FirstApplication =
{
    dateOfApplication: new Date(),
    details: EMPTY_CUSTOMER_DETAILS,
    activityGroups: []
}

export enum LegalTypeId {
    NaturalPerson = 1,
    LegalPerson = 2,
}


export enum BasicActivityGroupId {
    Crop = 1,
    Livestock = 2,
    Processing = 3,
    Beekeeping = 4,
    Aquaculture = 5,
}

export type AddressLikeBindingModel =
    {
        addressLine1: string
        addressLine2?: string
        postalCode: string
        communityId: number
    }

export type AddressLikeFullModel =
    AddressLikeBindingModel &
    {
        regionName: string
        regionUnitName: string
        municipalityName: string
        municipalityUnitName: string
        communityName: string
    }

export type CustomerAddress =
    AddressLikeBindingModel &
    AddressLikeFullModel &
    {
        customerAddressId?: number
        addressLine1Latin?: string
    }

export type CertificationOrganizationViewModel =
    {
        certificationOrganizationId: number
        name: string
    }

export type TaxOffice =
    {
        taxOfficeId: number
        name: string
    }


export class ApplicationsApi {
    constructor(private readonly api: ApiClient) { }

    public upsertFirstApplication = (c: FirstApplication) =>
        this.api.onUnexpected(
            this.api.execute<{ applicationId: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/external-users/current/application`, undefined, c)
            , "Δεν είναι δυνατή η αποθήκευση των αλλαγών", null)

    public getApplication = () =>
        this.api.onUnexpected(
            this.api.execute<FirstApplication>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/current/application`)
            , "Δεν υπάρχει ενεργή αίτηση.", EMPTY_APPLICATION_FORM)

    static FILE_TIMEOUT_MS = 5 * 60 * 1000

    public generateApplication = async (applicationId: number) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'POST', `api/tenants/${this.api.tenantId}/external-users/current/application/${applicationId}/generation/application`, undefined, undefined, ApplicationsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Δεν είναι δυνατή η παραγωγή αρχείου", null)

    public getCertificationOrganizations = () =>
        this.api.onUnexpected(
            this.api.execute<CertificationOrganizationViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/requirements/organizations`),
            "Δεν είναι δυνατή η εμφάνιση των φορέων πιστοποίησης",
            []
        )

    public getTaxOffices = () =>
        this.api.onUnexpected(
            this.api.execute<TaxOffice[]>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/requirements/tax-offices`),
            "Δεν είναι δυνατή η εμφάνιση των ΔΟΥ",
            []
        )
}