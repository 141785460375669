import { ApiClient } from "./ApiClient"

export type RegistrationResult =
    {
        isRegistrationComplete: boolean
    }

export type ExternalUserSummary =
    {
        canCreateApplication: boolean
        applicationId?: number
        customerId?: number
        unreadDocuments: number
        canUploadDocuments: boolean
        taxRegistrationNumber: string
        email: string
    }

export class ExternalUsersApi {
    constructor(private readonly api: ApiClient) { }

    public register = () =>
        this.api.onUnexpected(
            this.api.execute<RegistrationResult>(200, 'PUT', `api/tenants/${this.api.tenantId}/external-users/current/registration`), "Could not get registration status.", { isRegistrationComplete: false } as RegistrationResult, false)

    public getSummary = () =>
        this.api.onUnexpected(
            this.api.execute<ExternalUserSummary>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/current/summary`), "Could not get user summary.", { canCreateApplication: false, applicationId: undefined, unreadDocuments: 0 } as ExternalUserSummary
            , false)
}