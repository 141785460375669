import { Grid, Grow, Paper, Typography } from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import { makeStyles } from '@material-ui/core/styles'
import React from "react"
import { useAuthenticationContext } from "../../wtf/authentication/Authentication"
import { FormButton } from "../../wtf/components/forms/FormComponents"

const useStyles = makeStyles(theme => ({
    background: {
        backgroundColor: green[900],
        width: '100vw',
        minHeight: '100vh',
        justifyItems: 'center',
    },
    welcome: {
        borderRadius: '3%',
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
        width: '600px',
        padding: theme.spacing(4),
        maxHeight: '90vh',
        overflowY: 'auto',
    },
    content: {
        textAlign: 'justify',
    },
    welcomeText: {
        textAlign: 'justify',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        width: '500px',
        "& b": {
            color: theme.palette.primary.main,
        }
    }
}))

export const Welcome = () => {
    const classes = useStyles()
    const auth = useAuthenticationContext()

    return <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.background}>
        <Grid item xs={12}>
            <Grow in timeout={500}>
                <Paper elevation={16} className={classes.welcome}>
                    <Grid container spacing={4} direction="column" alignItems="center" justify="center" className={classes.content}>
                        <Grid item xs={12}>
                            <img src="/assets/bio-hellas-logo.png" width={152} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6' color='primary' component='span' className={classes.welcomeText}>
                                Καλωσορίσατε στην υπηρεσία <b>my bio hellas</b>!
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component='span' className={classes.welcomeText}>
                                Μέσω της υπηρεσίας <b>my bio hellas</b> μπορείτε να <b>παρακολουθείτε και να διαχειρίζεστε online</b> το λογαριασμό σας έχοντας τη δυνατότητα να:
                                <ul>
                                    <li><b>υποβάλετε αίτηση ένταξης</b> στο σύστημα πιστοποίησης βιολογικών προϊόντων του BIO HELLAS.</li>
                                    <li>έχετε πρόσβαση σε όλα τα απαραίτητα έγγραφα σας όπως τα <b>πιστοποιητικά σας</b></li>
                                    <li><b>υποβάλετε έγγραφα</b> απαραίτητα για την διεκπεραίωση των <b>αιτημάτων</b> σας</li>
                                </ul>
                            </Typography>
                            <Typography className={classes.welcomeText}>
                                Για να δημιουργήσετε έναν καινούριο προσωπικό λογαριασμό BIOHELLAS ή να συνδεθείτε πατήστε <i>'Συνέχεια'</i>.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormButton variant='contained' color='primary' onClickAsync={auth.login}>Συνέχεια</FormButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='caption'>
                                Πατώντας <i>'Συνέχεια'</i> δηλώνω ότι συμφωνώ στους <b>Όρους και Προϋποθέσεις</b>.
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grow>
        </Grid>
    </Grid>
}