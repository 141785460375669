import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import { ArrowLeft, CloudUpload } from "@material-ui/icons"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { DocumentsGrid } from "../../DocumentsGrid"
import { useApiContext } from "../../wtf/api/ApiContext"
import { ExternalUserSummary } from "../../wtf/api/ExternalUsers"
import { ConfirmationDialog, DialogHandle } from "../../wtf/components/confirmation-dialog/ConfirmationDialog"
import { Dropdown } from "../../wtf/components/dropdowns/Dropdowns"
import { useForm, validatorOptional, valueToEvent } from "../../wtf/components/forms/Form"
import { FormButton } from "../../wtf/components/forms/FormComponents"
import { withAppServicesProvider } from "../../wtf/progress-reporting/ProgressReporting"
import { useDialogToggle } from "../../wtf/refresh/Toggle"
import routes from "../Shared/routes"


const useStyles = makeStyles(theme => ({
    actionsGrid: {
        minWidth: '100%',
        minHeight: '100%',
        justifyItems: 'center',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    fileInput: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}))


export const DocumentsUpload = () => {
    const classes = useStyles()
    const api = useApiContext()
    const history = useHistory()
    const [refreshToggle, setRefreshToggle] = useState(false)
    const [summary, setSummary] = useState<ExternalUserSummary>()
    const handle = useDialogToggle()

    useEffect(() => {
        api.externalUsers.getSummary().then(s => setSummary(s))
    }, [refreshToggle])

    useEffect(() => { if (!handle.dialog.open) setRefreshToggle(!refreshToggle) }, [handle.dialog.open])

    return <Card style={{ marginTop: 16, marginBottom: 16, padding: 8 }}>
        <CardContent>
            <Grid container spacing={4} alignItems="center" justify="center" className={classes.actionsGrid}>
                <Grid item xs={12} className={classes.divider}>
                    <Typography variant="h3">Εξερχόμενα</Typography>
                </Grid>

                <Grid item xs={12} ></Grid>

                {(summary?.customerId || summary?.applicationId) &&
                    <>
                        <Grid item xs={12} className={classes.divider}>
                            <Typography variant="h5">Ανεβασμένα Έγγραφα</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <DocumentsGrid dataGridId='outdocs' customerId={summary?.customerId} applicationId={summary?.applicationId} type={'outbox'} refreshToggle={refreshToggle} />
                        </Grid>
                    </>
                }
            </Grid >
        </CardContent>
        <CardActions className={classes.divider}>
            <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item>
                    <FormButton variant='outlined' color='inherit' startIcon={<ArrowLeft />} onClick={() => history.push(routes.HOME.template)}>ΕΠΙΣΤΡΟΦΗ</FormButton>
                </Grid>
                <Grid item>
                    <FormButton variant='outlined' color='inherit' startIcon={<CloudUpload />} onClick={() => handle.openDialog()}>ΑΝΕΒΑΣΜΑ ΕΓΓΡΑΦΟΥ</FormButton>
                </Grid>
            </Grid>
        </CardActions>
        {handle.dialog.open && <UploadDocumentDialog handle={handle} customerId={summary?.customerId} applicationId={summary?.applicationId} />}
    </Card>
}

export const UploadDocumentDialog = withAppServicesProvider((props: { handle: DialogHandle, customerId?: number, applicationId?: number }) => {
    const api = useApiContext()

    const fileForm = useForm({ file: undefined as File | undefined, documentTypeId: undefined as number | undefined }, {
        file: validatorOptional<File>().required().build(),
        documentTypeId: validatorOptional<number>().required().build(),
    }, {
        submitAction: async file => {
            if (!!file.file && !!file.documentTypeId) {
                const x = await api.documents.uploadDocument(file.file, file.documentTypeId, props.customerId, props.applicationId)
                if (x?.documentId) return true
            }
        }
    })

    return <ConfirmationDialog handle={props.handle} subtitle='Επιτρεπτές μορφές: pdf, αρχεία εικόνων' title='Ανέβασμα Νέου Εγγράφου' onConfirm={fileForm.submit}>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <input type='file' accept=".pdf,image/*" onChange={e => { const f = Array.from(e.target.files ?? [])[0]; fileForm.handleChange('file')(valueToEvent(f)); }} />
                <Dropdown getData={() => api.documents.getDocumentTypes().then(t => _.orderBy(t, t => t.name))} idSelector={x => x.documentTypeId} displaySelector={x => x.name} hideEmpty label={"Είδος εγγράφου*"} onChange={x => fileForm.handleChange('documentTypeId')(x)} value={fileForm.state.documentTypeId} />
            </Grid>
        </Grid>
    </ConfirmationDialog>
})

