import { LinearProgress, LinearProgressProps } from "@material-ui/core"
import _ from "lodash"
import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { ApiClient } from "../api/ApiClient"
import { ApiContextProvider } from "../api/ApiContext"
import { AttachNotificationsHandler, AttachProgressReporting } from "../notifications/AttachApiNotificationsHandler"

export type ReportProgressF = (operation: string, percentage: number | undefined) => void

type ProgressReportingContextType =
    {
        reportProgress: ReportProgressF
        getOverallProgress: () => number | undefined | 'inactive'
    }

const ProgressReportingContext = createContext<ProgressReportingContextType>({
    reportProgress: () => { throw new Error('Uninitialized context') },
    getOverallProgress: () => { throw new Error('Uninitialized context') },
})

export const useProgressReporting = () => {
    const ctx = useContext(ProgressReportingContext)
    return {
        reportProgress: ctx.reportProgress
    }
}

export const ProgressReportingBar = (props: Omit<LinearProgressProps, 'value'>) => {
    const { getOverallProgress } = useContext(ProgressReportingContext)

    const overallProgress = getOverallProgress()

    return overallProgress === 'inactive'
        ? null
        : <LinearProgress variant={overallProgress === undefined ? 'indeterminate' : 'determinate'} value={overallProgress} {...props} />
}

export const ProgressReportingContainer: React.FC = props => {
    const [progressState, setProgressState] = useState<{ [operation: string]: number | undefined }>({})

    const reportProgress = useCallback((operation: string, percentage: number | undefined) => {
        if (percentage !== undefined)
            if (!Number.isFinite(percentage) || percentage < 0 || percentage > 100)
                throw new Error(`Invalid percentage '${percentage}' for operation ${operation}`)
        if (percentage === 100)
            setProgressState(st => _.omit(st, operation))
        else
            setProgressState(st => {
                return { ...st, [operation]: percentage }
            })
    }, [setProgressState])

    const getOverallProgress = useCallback(() => {
        const ops = Object.keys(progressState)
        if (ops.length === 0) return 'inactive'
        if (ops.length === 1) return progressState[ops[0]]
        return undefined
    }, [progressState])

    const provider: ProgressReportingContextType =
    {
        reportProgress: reportProgress,
        getOverallProgress: getOverallProgress,
    }

    return <ProgressReportingContext.Provider value={provider}>
        {props.children}
    </ProgressReportingContext.Provider>
}

export type ApiClientFactory = {
    apiClientFactory: () => ApiClient
}

const ApiClientWithProgressReporting: React.FC<ApiClientFactory> = props => {
    const [api, setApi] = useState<ApiClient>()

    useEffect(() => setApi(props.apiClientFactory()), [props.apiClientFactory])

    return api ? <ApiContextProvider value={api}>
        <AttachNotificationsHandler api={api} />
        <AttachProgressReporting api={api} />
        {props.children}
    </ApiContextProvider> : null
}

export const ApiServicesProvider: React.FC = props => {
    const { factory } = useApiServicesContext()

    return <ProgressReportingContainer>
        <ApiClientWithProgressReporting apiClientFactory={factory}>
            {props.children}
        </ApiClientWithProgressReporting>
    </ProgressReportingContainer>
}

export function withAppServicesProvider<P extends object>(Component: React.ComponentType<P>): React.FC<React.PropsWithChildren<P>> {
    return (props: React.PropsWithChildren<P>) => <ApiServicesProvider> <Component {...props}></Component> </ApiServicesProvider>
}

export const ApiServicesContext = createContext<() => ApiClient>(null as any)

export const useApiServicesContext = () => {
    const factory = useContext(ApiServicesContext)

    return {
        factory
    }
}