
export enum Environment {
    Production = 0,
    Qa = 1,
    Dev = 2,
}

const ifEmptyDefault = (setting: string, defaultValue: string) => setting === "" || setting.startsWith("__INJECT_") ? defaultValue : setting

export const TENANT_ID = ifEmptyDefault("51374ca4-522b-4f1e-91f4-d62318559627", process.env.REACT_APP_BIO_APP_AAD_TENANT_ID!)
export const APIAPP_ID = ifEmptyDefault("02726da2-05ef-44f9-8b1e-a0d347bf1e2b", process.env.REACT_APP_BIO_APP_AAD_APIAPP_ID!)
export const WEBAPP_ID = ifEmptyDefault("9d5d8593-18f6-45b9-963f-0da9a988069f", process.env.REACT_APP_BIO_APP_AAD_WEBAPP_ID!)
export const B2C_AUTHORITY = ifEmptyDefault("biohellasb2c", process.env.REACT_APP_BIO_APP_B2C_AUTHORITY!)
export const B2C_TENANT_ID = ifEmptyDefault("4f996001-69bd-477c-a5a4-faa5df7d610b", process.env.REACT_APP_BIO_APP_B2C_TENANT_ID!)
export const B2C_APIAPP_ID = ifEmptyDefault("c8553199-5d4f-4298-a2ca-ad9c313b8b44", process.env.REACT_APP_BIO_APP_B2C_APIAPP_ID!)
export const B2C_WEBAPP_ID = ifEmptyDefault("70a74231-9f1a-47d2-9237-f075874d6259", process.env.REACT_APP_BIO_APP_B2C_WEBAPP_ID!)
export const API_URL = ifEmptyDefault("https://app.bio-hellas.gr", process.env.REACT_APP_BIO_APP_FALLBACK_API_URL!)
export const VERSION = process.env.REACT_APP_BIO_APP_VERSION ?? "0.0.0"
export const COMMIT = process.env.REACT_APP_BIO_APP_COMMIT ?? "0000000"
export const BUILD_DATE = process.env.REACT_APP_BIO_APP_BUILD_DATE ?? "00000000"
const ENVIRONMENT_RAW = ifEmptyDefault("Production", process.env.REACT_APP_BIO_APP_ENVIRONMENT || "Production")

export const ENVIRONMENT = ENVIRONMENT_RAW === "Development" ? Environment.Dev : ENVIRONMENT_RAW === "Qa" ? Environment.Qa : Environment.Production