import { useEffect } from 'react'
import { ApiClient } from '../api/ApiClient'
import { useProgressReporting } from '../progress-reporting/ProgressReporting'
import { useApiNotifications } from './SnackbarContext'

export const AttachNotificationsHandler = ({ api }: { api?: ApiClient }) => {
    const { wrapError, onSuccess } = useApiNotifications()

    useEffect(() => {
        if (api) {
            api.onUnexpected = wrapError
            api.onSuccess = onSuccess
        }
    }, [api])

    return null
}

export const AttachProgressReporting = ({ api }: { api?: ApiClient }) => {
    const { reportProgress } = useProgressReporting()

    useEffect(() => {
        if (api) api.onProgressReported = reportProgress
    }, [api])

    return null
}