import { Box, Typography } from "@material-ui/core";
import React from "react";
import { ApiResponse } from "../api/ApiClient";
import { SnackbarContextType } from "../notifications/SnackbarContext";
import { caseNever } from "./never";

export type ErrorHandler = <U, V>(promise: Promise<ApiResponse<U, "unexpected">>, message: string, onErrorValue?: V | undefined, onSuccessNotify?: boolean, onNotFoundSupressNotifications?: boolean, onBadRequestSupressNotifications?: boolean) => Promise<U | V>

export const onUnexpected = (context: SnackbarContextType) => async <U, V>(promise: Promise<ApiResponse<U>>, message: string, onErrorValue?: V, onSuccessNotify?: boolean, onNotFoundSupressNotifications?: boolean, onBadRequestSupressNotifications?: boolean): Promise<U | V> => {
    const result = await promise
    const detailsCorrelationId = `CorrelationId: ${result.correlationId}\n`
    switch (result.kind) {
        case 'success':
            if (onSuccessNotify)
                context.successNotification("Επιτυχής καταχώρηση", detailsCorrelationId, undefined, { autoHide: true })
            return result.data
        case 'unexpected':
            const details = `${detailsCorrelationId}Status ${result.status ?? '-'}\n${result.message}\n${result.trace ?? '-'}`
            switch (result.status) {
                case 400: if (!onBadRequestSupressNotifications) context.warningNotification(message, <>
                    {!result.failures ? `Δεν βρέθηκε λιστα παραλήψεων.` : <ul>{Object.values(result.failures).flatMap(x => x).flatMap(x => x.split("\n")).filter(x => x).map(x => <li key={x}>{x}</li >)}</ul >}
                    {detailsCorrelationId}</>); break;
                case 403: context.errorNotification(message,
                    <Box>
                        <Typography>{result.message ?? ''}</Typography>
                        <Typography>{detailsCorrelationId}</Typography>
                    </Box>); break;
                case 404: if (!onNotFoundSupressNotifications) context.errorNotification("Η επιλεγμένη εγγραφή δεν βρέθηκε", details); break;
                case 204: context.warningNotification("Η ενέργεια δεν επέφερε κάποια μεταβολή.", details); break;
                case 501: context.errorNotification("Η ενέργεια δεν υποστηρίζεται ή δεν έχει υλοποιηθεί.", details); break;
                default: context.errorNotification(message, details); break;
            }
            if (onErrorValue !== undefined)
                return onErrorValue
            else
                throw new Error(message)
        default: caseNever(result)
    }
}

export const onUnexpectedIgnore: ErrorHandler = async (promise, message, onErrorValue) => {
    const result = await promise
    switch (result.kind) {
        case 'success': return result.data
        case 'unexpected':
            if (onErrorValue !== undefined)
                return onErrorValue
            else
                throw new Error(message)
        default: caseNever(result)
    }
}