import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useInternationalization } from '../../internationalization/Internationalization';
import { ProgressReportingBar } from '../../progress-reporting/ProgressReporting';

export type DialogHandle = { dialog: { open: boolean }, closeDialog: () => void }

type Props =
    {
        title?: React.ReactNode
        subtitle?: React.ReactNode
        disableConfirm?: boolean
        maxWidth?: false | "xl" | "xs" | "sm" | "md" | "lg"
        fullScreen?: boolean
        options?: 'YesNo' | 'ConfirmCancel' | 'SubmitCancel' | 'OkCancel' | { confirmText?: string, cancelText?: string }
        otherActions?: React.ReactNode[]
        onConfirm?: () => Promise<boolean | undefined>
        onCancel?: () => void
        handle: DialogHandle
    }

enum ConfirmState { Confirming = 1, Confirmed = 2 }

const ConfirmationDialogImpl: React.FC<Props> = props => {
    const { intl } = useInternationalization()

    const [state, setState] = useState<{ confirmState?: ConfirmState }>({})

    useEffect(() => {
        switch (state.confirmState) {
            case undefined: return
            case ConfirmState.Confirming:
                props.onConfirm?.()
                    .then(r => setState(st => ({ ...st, confirmState: r === true ? ConfirmState.Confirmed : undefined })))
                    .catch(() => setState(st => ({ ...st, confirmState: undefined })))
                return
            case ConfirmState.Confirmed:
                setState(st => ({ ...st, confirmState: undefined }))
                props.handle.closeDialog()
                return
        }
    }, [state.confirmState])

    const [confirmText, cancelText] = (() => {
        switch (props.options) {
            case undefined:
            case 'ConfirmCancel': return [intl('CONFIRM'), intl('CANCEL')]
            case 'YesNo': return [intl('YES'), intl('NO')]
            case 'SubmitCancel': return [intl('SUBMIT'), intl('CANCEL')]
            case 'OkCancel': return ['ΟΚ', intl('CANCEL')]
            default: return [props.options.confirmText, props.options.cancelText]
        }
    })()

    const onCancel = () => { props.onCancel?.(); props.handle.closeDialog() }
    const onConfirm = () => setState(st => ({ ...st, confirmState: ConfirmState.Confirming }))

    return (
        <Dialog open={props.handle.dialog.open} onClose={props.onCancel} fullWidth maxWidth={props.maxWidth} fullScreen={props.fullScreen}>
            {props.title && <DialogTitle>{props.title}</DialogTitle>}
            <ProgressReportingBar />
            <DialogContent dividers>
                {props.subtitle && <DialogContentText>{props.subtitle}</DialogContentText>}
                {props.children}
            </DialogContent>
            <DialogActions>
                {cancelText && <Button onClick={onCancel} disabled={state.confirmState === ConfirmState.Confirming} color="primary">{cancelText}</Button>}
                {props.otherActions}
                {confirmText && <Button onClick={onConfirm} disabled={!!props.disableConfirm || state.confirmState === ConfirmState.Confirming} color="primary" variant="contained">{confirmText}</Button>}
            </DialogActions>
        </Dialog>
    )
}

export const ConfirmationDialog = ConfirmationDialogImpl