import { Environment, ENVIRONMENT } from "../../ConfigurationInjection";

export const cleanse = <T>(x: T, ...fields: (keyof T)[]) => {
    let copy = { ...x }
    for (const field of fields) delete copy[field]
    return copy
}

export const id = <T>(x: T) => x;

export const numberToTextFieldValue = (x?: number) => x === undefined || x === null ? '' : x.toString()

const maybeParse = (e: string | Date) => typeof e === 'string' ? new Date(e) : e
export const toLocaleDate = (e: string | null | undefined | Date) => !e ? '-' : maybeParse(e).toLocaleDateString(["el-GR", "en-GB"], { month: '2-digit', day: '2-digit', year: 'numeric' })
export const toLocaleTime = (e: string | null | undefined | Date) => !e ? '-' : maybeParse(e).toLocaleTimeString(["el-GR", "en-GB"])
export const toLocaleDateTime = (e: string | null | undefined | Date) => `${toLocaleDate(e)} ${toLocaleTime(e)}`

export const idOrEmptyRoute = (id: number | undefined) => id ? '/' + id : ''

export const unexpected = (message?: string) => { throw new Error(message) }

export const isValidDate = (v: any): v is Date => v instanceof Date && !isNaN(v.getTime())
export const datePart = (d: Date) => {
    const dd = new Date(d.getTime())
    dd.setHours(0, 0, 0, 0)
    return dd
}

export function isNotEmpty<T>(x: T | null | undefined): x is Exclude<Exclude<T, null>, undefined> {
    return x === null || x === undefined ? false : Object.keys(x).length > 0
}

export function isBoolean(x: boolean | null | undefined): x is boolean {
    return x === true || x === false
}

export const maybeParseInt = (v: string) => { const i = Number.parseInt(v); return Number.isNaN(i) ? undefined : i }

export const isFuzzyTextMatch = (term: string, actual: string) => {
    const normalize = (s: string) => removeAccents(s.trim()).toUpperCase()
    const actualNormalized = normalize(actual)
    const termNormalized = normalize(term)
    return actualNormalized.indexOf(termNormalized) > -1
        || toGreeklish(actualNormalized).indexOf(toGreeklish(termNormalized)) > -1
}

export const debounce = (cb: (...args: any[]) => any, duration: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            cb(...args);
        }, duration);
    };
}


export const removeAccents = (s: string) => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const toISOStringWIthTimezone = (date: Date) => {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: number) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export const emptyDateISOString = toISOStringWIthTimezone(new Date(0))

export function toGreeklish(text: string) {
    var grCaps = stringToSet('ΑΆΒΓΔΕΈΖΗΉΘΙΊΪΚΛΜΝΞΟΌΠΡΣΤΥΎΫΦΧΨΩΏ')
    var replacements = [
        { greek: 'αι', greeklish: 'ai' },
        { greek: 'αί', greeklish: 'ai' },
        { greek: 'οι', greeklish: 'oi' },
        { greek: 'οί', greeklish: 'oi' },
        { greek: 'ου', greeklish: 'ou' },
        { greek: 'ού', greeklish: 'ou' },
        { greek: 'ει', greeklish: 'ei' },
        { greek: 'εί', greeklish: 'ei' },
        { greek: 'αυ', fivi: 1 },
        { greek: 'αύ', fivi: 1 },
        { greek: 'ευ', fivi: 1 },
        { greek: 'εύ', fivi: 1 },
        { greek: 'ηυ', fivi: 1 },
        { greek: 'ηύ', fivi: 1 },
        { greek: 'ντ', greeklish: 'nt' },
        { greek: 'μπ', bi: 1 },
        { greek: 'τσ', greeklish: 'ts' },
        { greek: 'τς', greeklish: 'ts' },
        { greek: 'ΤΣ', greeklish: 'ts' },
        { greek: 'τζ', greeklish: 'tz' },
        { greek: 'γγ', greeklish: 'ng' },
        { greek: 'γκ', greeklish: 'gk' },
        { greek: 'θ', greeklish: 'th' },
        { greek: 'χ', greeklish: 'ch' },
        { greek: 'ψ', greeklish: 'ps' },
    ]
    // Remove extraneus array element
    if (!replacements[replacements.length - 1]) replacements.pop()
    // Enchance replacements
    for (var i = 0, replacement; replacement = replacements[i]; i++) {
        replacements[replacement.greek as any] = replacement
    }
    // Append single letter replacements
    var grLetters = 'αάβγδεέζηήθιίϊΐκλμνξοόπρσςτυύϋΰφχψωώ'
    var engLetters = 'aavgdeezii.iiiiklmnxooprsstyyyyf..oo'
    for (var i = 0; i < grLetters.length; i++) {
        if (!replacements[grLetters.charAt(i) as any]) {
            replacements.push({ greek: grLetters.charAt(i), greeklish: engLetters.charAt(i) })
        }
    }
    // Enchance replacements, build expression
    var expression = []
    for (var i = 0, replacement; replacement = replacements[i]; i++) {
        replacements[replacement.greek as any] = replacement
        expression[i] = replacement.greek
    }
    const regex = new RegExp(expression.join('|'), 'gi')
    // Replace greek with greeklsh
    var greekSet = stringToSet(grLetters)
    var viSet = stringToSet('αβγδεζηλιmμνορω')
    text = text.replace(regex, function ($0, index) {
        var replacement = replacements[$0.toLowerCase() as any]
        if (replacement.bi) {
            var bi = (greekSet[text.charAt(index - 1).toLowerCase()] && greekSet[text.charAt(index + 2).toLowerCase()]) ? 'mp' : 'b'
            return fixCase(bi, $0)
        } else if (replacement.fivi) {
            var c1 = replacements[$0.charAt(0).toLowerCase() as any].greeklish
            var c2 = viSet[text.charAt(index + 2).toLowerCase() as any] ? 'v' : 'f'
            return fixCase(c1 + c2, $0)
        } else {
            return fixCase(replacement.greeklish as any, $0 + text.charAt(index + $0.length))
        }
    })
    return text
    function fixCase(text: string, mirror: string) {
        if (grCaps[mirror.charAt(0)]) {
            if (mirror.length === 1 || grCaps[mirror.charAt(1)]) {
                return text.toUpperCase()
            } else {
                return text.charAt(0).toUpperCase() + text.substr(1)
            }
        } else {
            return text
        }
    }
    function stringToSet(s: string) {
        var o: { [s: string]: number } = {}
        for (var i = 0; i < s.length; i++) {
            o[s.charAt(i)] = 1
        }
        return o
    }
}

export const hash = (str: string) => {
    var hash = 0, i, chr;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}


export const mangle = (key: number, e: number) => {
    if (key > Math.pow(2, 32)) throw new Error('No supported key ' + key)
    try {
        return btoa((e ^ key).toString(16))
    }
    catch (err) {
        console.error('Could not mangle ' + e)
        throw err
    }
}
export const unmangle = (key: number, e: string) => {
    if (key > Math.pow(2, 32)) throw new Error('No supported key ' + key)
    try {
        return key ^ Number.parseInt(atob(e), 16)
    }
    catch (err) {
        console.error('Could not unmangle ' + e)
        throw err
    }
}

export const maybeDisableReactDevTools = (): void => {
    if (ENVIRONMENT === Environment.Production) {
        const noop = (): void => undefined;
        const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;
        if (typeof DEV_TOOLS === 'object') {
            for (const [key, value] of Object.entries(DEV_TOOLS)) {
                DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
            }
        }
    }
}

export const isIE = () => {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ") > -1;
    var msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
}