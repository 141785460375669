import { DBSchema, IDBPDatabase, openDB, StoreNames } from 'idb'
import { SupportedLanguage, TenantId } from '../api/WellKnowIds'

export type StoredSettings =
    {
        dataGridId: string
        columnsOrdered: React.Key[]
    }

interface MyDbSchema extends DBSchema {
    'datagrid-settings': {
        key: string
        value: StoredSettings
    },
    'release-notes': {
        key: string
        value: string
    },
    'settings': {
        key: 'internationalization'
        value: {
            tenant?: TenantId
            language?: SupportedLanguage
        }
    }
}

const createObjectStoreIffNotExists = (db: IDBPDatabase<MyDbSchema>, store: StoreNames<MyDbSchema>, optionalParameters?: IDBObjectStoreParameters) => {
    if (!db.objectStoreNames.contains(store))
        db.createObjectStore(store, optionalParameters)
}

export const openApplicationIdb = () => openDB<MyDbSchema>('bio-app', 5,
    {
        upgrade: (db, _oldVersion, _newVersion) => {
            createObjectStoreIffNotExists(db, 'datagrid-settings', { keyPath: 'dataGridId' })
            createObjectStoreIffNotExists(db, 'release-notes')
            createObjectStoreIffNotExists(db, 'settings')
        },
    })