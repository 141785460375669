import { BlobResponse } from "../../wtf/api/ApiClient"
import { caseNever } from "../../wtf/utils/never"

export const viewOrDownloadFile = (blob: BlobResponse, t: 'view' | 'download', filename: string) => {
    const url = window.URL.createObjectURL(blob.blob)
    try {
        switch (t) {
            case 'view': return window.location.href = url
            case 'download':
                var link = document.createElement('a')
                link.href = url
                link.target = "_self"
                link.download = filename
                link.click()
                link.remove()
                break;
            default: return caseNever(t)
        }
    }
    finally {
        window.URL.revokeObjectURL(url)
    }
}