import { MenuItem } from '@material-ui/core'
import React, { Key, useEffect, useState } from 'react'
import { FormGridProps, FormMultiSelect, FormTextField, PermissionProps, ValidationProps } from '../forms/FormComponents'

type CommonDropdownProps<Id extends Key = number, Value = unknown> =
    {
        label?: string
        onChange?: (x?: Id, v?: Value) => void
        disabled?: boolean
        readOnly?: boolean
        value?: Id
        className?: any
        hideEmpty?: boolean
        selectIfSingle?: boolean
    } & FormGridProps & ValidationProps & PermissionProps

export type DropdownProps<T, Id extends Key = number> =
    {
        idSelector: (data: T) => Id
        displaySelector: (data: T) => React.ReactNode
        getData: () => Promise<T[]>
        reloadToggle?: boolean
    } & CommonDropdownProps<Id, T>

type CommonMultiselectProps =
    {
        label?: string
        onChange?: (x?: number[]) => void
        disabled?: boolean
        readOnly?: boolean
        value?: number[]
        className?: any
    } & FormGridProps & ValidationProps

export type MultiSelectProps<T> =
    {
        idSelector: (data: T) => number
        displaySelector: (data: T) => string
        getData: () => Promise<T[]>
    } & CommonMultiselectProps

export function Dropdown<T, V extends Key = number>(props: DropdownProps<T, V>) {
    const { label, disabled, readOnly, className, getData, onChange, value, idSelector, displaySelector, hideEmpty, reloadToggle, errorState, ...otherProps } = props

    const [data, setData] = useState<T[]>([])
    const [internalValue, setInternalValue] = useState(value)

    useEffect(() => { setInternalValue(value) }, [value])
    useEffect(() => { getData().then(c => setData(c)) }, [getData, reloadToggle])
    useEffect(() => {
        if (props.selectIfSingle && data.length === 1) {
            const d = idSelector(data[0])
            setInternalValue(d)
            props.onChange?.(d, data[0])
        }
    }, [props.selectIfSingle, data])

    return <FormTextField select value={!internalValue ? '' : internalValue}
        label={label} disabled={disabled} readOnly={readOnly} className={className} errorState={errorState} {...otherProps}
        onChange={e => {
            const parsed = Number(e.target.value)
            if (Number.isInteger(parsed))
                onChange?.(parsed as any, data.find(d => idSelector(d) == parsed))
            else if (e.target.value)
                onChange?.(e.target.value as any, data.find(d => idSelector(d) == e.target.value))
            else onChange?.(undefined, undefined)
        }}>
        {!hideEmpty && <MenuItem value={undefined}><em>--</em></MenuItem>}
        {data.map(c => <MenuItem key={idSelector(c)} value={idSelector(c)}>{displaySelector(c)}</MenuItem>)}
    </FormTextField>
}

export function MultiSelect<T>(props: MultiSelectProps<T>) {
    const { label, disabled, readOnly, className, getData, onChange, value, idSelector, displaySelector, errorState, ...otherProps } = props

    const [data, setData] = useState<T[]>([])
    const [internalValue, setInternalValue] = useState<number[] | undefined>(value)

    useEffect(() => { setInternalValue(value) }, [value])
    useEffect(() => { getData().then(c => setData(c)) }, [getData])

    return <FormMultiSelect value={internalValue || []}
        label={label} errorState={errorState} disabled={disabled} readOnly={readOnly} className={className}
        onChange={(e, _) => {
            onChange?.(e.target.value as number[])
        }} {...otherProps}>
        {data.map(c => <MenuItem key={`key-${idSelector(c)}`} value={idSelector(c)}>{displaySelector(c)}</MenuItem>)}
    </FormMultiSelect >
}