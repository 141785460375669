import { Button, ButtonProps, Checkbox, CheckboxProps, Chip, FormControl, FormControlLabel, FormControlLabelProps, FormHelperText, Grid, GridSpacing, Input, InputAdornment, InputLabel, InputProps, ListSubheader, Select, SelectProps, TextField, TextFieldProps, Theme, Tooltip, useMediaQuery, WithStyles } from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Autocomplete, { AutocompleteProps, AutocompleteRenderGroupParams } from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import clsx from "clsx";
import React, { Key, useCallback, useEffect, useState } from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import PermissionType from "../../user-info/PermissionType";
import { useUserInfoContext } from "../../user-info/UserInfoContext";
import { useCommonStyles } from "../styling/commonStyles";

export interface StyleProps extends WithStyles<typeof commonFormStyles> {
    children?: React.ReactNode;
    className?: string;
}

export const commonFormStyles = (theme: Theme) => ({
    root: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 4,
    },
    fullWidth: {
        width: '100%',
    },
})

export const useCommonFormStyles = makeStyles(commonFormStyles)

export type PermissionProps =
    {
        readOnly?: boolean
        canRead?: PermissionType
        canEdit?: PermissionType
    }

export type ValidationProps =
    {
        errorState?: null | { error: string }
    }

type Size = boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined

export type FormGridProps = { 'grid-item'?: boolean, xs?: Size, sm?: Size, md?: Size, lg?: Size, xl?: Size, spacing?: GridSpacing }

export const MaybeWrapToGrid: React.FC<FormGridProps & { hide?: boolean }> = props => {
    return props["grid-item"]
        ? <Grid item spacing={props.spacing} xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>{!props.hide && props.children}</Grid>
        : <React.Fragment>{!props.hide && props.children}</React.Fragment>
}

export type FormComponentProps<T> = StyleProps & FormGridProps & PermissionProps & ValidationProps & T
export type FormTextFieldProps = FormComponentProps<TextFieldProps & { noTrimStart?: boolean, autoCaps?: boolean }>

export const FormTextField = withStyles(commonFormStyles)((props: FormTextFieldProps) => {
    const { classes, children, className, 'grid-item': gridItem, value, onChange, readOnly, noTrimStart, autoCaps, errorState, helperText, InputProps, xs, sm, md, lg, xl, ...other } = props
    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    return (
        <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
            <TextField className={clsx(classes.root, classes.fullWidth, className)}
                InputProps={{ ...InputProps, readOnly: isReadOnly }}
                value={value === undefined || value === null ? '' : value} fullWidth margin="dense"
                error={!!errorState}
                helperText={!!errorState ? errorState.error : helperText}
                onChange={e => {
                    if (!noTrimStart) {
                        if (e.target.value?.trimStart) e.target.value = e.target.value.trimStart()
                        if (e.currentTarget.value?.trimStart) e.currentTarget.value = e.currentTarget.value.trimStart()
                    }
                    if (autoCaps) {
                        if (e.target.value?.toLocaleUpperCase) e.target.value = e.target.value.toLocaleUpperCase()
                        if (e.currentTarget.value?.toLocaleUpperCase) e.currentTarget.value = e.currentTarget.value.toLocaleUpperCase()
                    }
                    return onChange?.(e)
                }}
                {...other}>
                {children}
            </TextField>
        </MaybeWrapToGrid>
    )
})

export const FormSelect = withStyles(commonFormStyles)((props: StyleProps & TextFieldProps & FormGridProps & PermissionProps & { label?: string } & ValidationProps) => {
    const { classes, children, className, 'grid-item': gridItem, value, readOnly, InputProps, xs, sm, md, lg, xl, errorState, ...other } = props
    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    return (
        <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
            <TextField select className={clsx(classes.root, classes.fullWidth, className)} InputProps={{ ...InputProps, readOnly: isReadOnly }}
                error={!!errorState}
                helperText={!!errorState ? errorState.error : undefined}
                value={value || ''} fullWidth margin="dense" {...other}>{children}</TextField>
        </MaybeWrapToGrid>
    )
})

export const FormMultiSelect = withStyles(commonFormStyles)((props: StyleProps & SelectProps & FormGridProps & PermissionProps & { label?: string } & ValidationProps) => {
    const { classes, children, className, 'grid-item': gridItem, xs, sm, md, lg, xl, label, readOnly, onChange, errorState, ...other } = props
    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    return (
        <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
            <FormControl className={clsx(classes.root, classes.fullWidth, className)}>
                {label && <InputLabel>{label}</InputLabel>}
                <Select multiple fullWidth margin="dense" onChange={isReadOnly ? undefined : onChange} error={!!errorState} {...other}>{children}</Select>
                {errorState && <FormHelperText error >{errorState.error}</FormHelperText>}
            </FormControl>
        </MaybeWrapToGrid>
    )
})

export const FormDatePicker = withStyles(commonFormStyles)((props: StyleProps & KeyboardDatePickerProps & FormGridProps & PermissionProps & ValidationProps) => {
    const { classes, children, className, 'grid-item': gridItem, xs, sm, md, lg, xl, errorState, readOnly, helperText, ...other } = props
    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    return (
        <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
            <KeyboardDatePicker className={clsx(classes.root, classes.fullWidth, className)}
                InputProps={{ ...other.InputProps, readOnly: isReadOnly }}
                fullWidth
                margin="dense"
                {...other}
                error={Boolean(errorState)}
                helperText={errorState ? errorState.error : helperText}
                disableToolbar
                onChange={(e, v) => props.onChange(e, v)}
                variant="inline" format="dd/MM/yyyy">{children}</KeyboardDatePicker>
        </MaybeWrapToGrid>
    )
})

export const FormYearPicker = withStyles(commonFormStyles)((props: StyleProps & KeyboardDatePickerProps & FormGridProps & PermissionProps & ValidationProps) => {
    const { classes, children, className, 'grid-item': gridItem, xs, sm, md, lg, xl, errorState, readOnly, helperText, ...other } = props
    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    return (
        <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
            <KeyboardDatePicker className={clsx(classes.root, classes.fullWidth, className)}
                InputProps={{ ...other.InputProps, readOnly: isReadOnly }}
                fullWidth
                margin="dense"
                {...other}
                error={Boolean(errorState)}
                helperText={errorState ? errorState.error : helperText}
                disableToolbar
                openTo="year"
                views={["year"]}
                onChange={(e, v) => props.onChange(e, v)}
                variant="inline" format="yyyy">{children}</KeyboardDatePicker>
        </MaybeWrapToGrid>
    )
})

export const FormCheckbox = withStyles(commonFormStyles)((props: StyleProps & CheckboxProps & FormGridProps & PermissionProps & ValidationProps & { dense?: boolean, label?: React.ReactNode, controlProps?: Partial<FormControlLabelProps>, startPlacement?: boolean }) => {
    const { classes, children, className, 'grid-item': gridItem, xs, sm, md, lg, xl, readOnly, label, disabled, controlProps, checked, errorState, dense, startPlacement, ...other } = props
    const commonClasses = useCommonStyles()
    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit)) // TODO implement
    const hide = props.canRead && !hasPermission(props.canRead)

    return (
        <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
            <FormControl>
                <FormControlLabel className={dense ? undefined : commonClasses.fixedMarginTop} control={<Checkbox className={className} checked={!!checked} disabled={isReadOnly || disabled} onChange={isReadOnly ? undefined : props.onChange} color='primary' {...other} />} label={label} labelPlacement={startPlacement ? "start" : "end"} {...controlProps} />
                {errorState && <FormHelperText error >{errorState.error}</FormHelperText>}
            </FormControl>
        </MaybeWrapToGrid>)
})


//
// Autocomplete
//

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        },
    });
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}>
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export type FormAutocompleteProps<T> =
    {
        label?: string
        onChange?: (x?: T[], ids?: Key[]) => void
        disabled?: boolean
        readOnly?: boolean
        value?: T[]
        valueIds?: Key[]
        className?: any
        idSelector: (data: T) => Key
        displaySelector: (data: T) => string
        tagSelector?: (data: T) => React.ReactNode
        renderTagTooltip?: (data: T) => React.ReactNode
        getData?: () => Promise<T[]>
        data?: T[]
        endAdornment?: JSX.Element
        virtualized?: boolean
        autocompleteProps?: Partial<AutocompleteProps<T, true, true, false>>
    } & PermissionProps & FormGridProps & ValidationProps

export function FormAutocomplete<T>(props: FormAutocompleteProps<T>) {
    const { label, disabled, readOnly, className, getData, onChange, value, idSelector, valueIds, displaySelector, tagSelector, renderTagTooltip, virtualized, 'grid-item': gridItem, xs, sm, md, lg, xl, autocompleteProps, errorState, endAdornment, ...otherProps } = props

    const [data, setData] = useState<T[]>([])
    const [internalValue, setInternalValue] = useState<T[]>([])

    useEffect(() => { if (props.data) setData(props.data) }, [props.data])
    useEffect(() => { getData?.().then(c => setData(c)) }, [getData])

    useEffect(() => { setInternalValue(data.filter(d => valueIds?.includes(idSelector(d)))) }, [valueIds, data])

    useEffect(() => { setInternalValue(value ?? []) }, [value])

    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    return <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
        <Autocomplete
            multiple
            disableListWrap
            disableCloseOnSelect
            ListboxComponent={virtualized ? ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>> : undefined}
            renderGroup={renderGroup}
            getOptionLabel={o => displaySelector(o)}
            options={data}
            loading={data.length === 0}
            value={internalValue}
            loadingText=""
            onChange={disabled ? undefined : (_e, xs) => props.onChange?.(xs, xs.map(m => idSelector(m)))}
            renderInput={(params) => <FormTextField
                {...params}
                InputProps={{
                    ...params.InputProps, endAdornment: <>
                        {params.InputProps.endAdornment}
                        <InputAdornment position='end'>
                            {endAdornment}
                        </InputAdornment>
                    </>
                }}
                errorState={errorState} readOnly={isReadOnly} disabled={disabled} label={label} />}
            disableClearable={disabled}
            renderTags={tagSelector
                ? (params, getTagProps) =>
                    <>
                        {params.map((x, index) => {
                            const elem = <Chip key={index} label={tagSelector(x)} {...getTagProps({ index })} />
                            return renderTagTooltip ? <Tooltip key={index} title={renderTagTooltip(x)!}>{elem}</Tooltip> : elem
                        })}
                    </>
                : undefined}
            {...autocompleteProps}
        />
    </MaybeWrapToGrid>
}

export type FormAutocompleteSingleProps<T> =
    {
        label?: string
        onChange?: (x?: T, id?: Key) => void
        disabled?: boolean
        readOnly?: boolean
        value?: T
        valueId?: Key
        className?: any
        idSelector: (data: T) => Key
        displaySelector: (data: T) => string
        renderOption?: (data: T) => React.ReactNode
        getData?: () => Promise<T[]>
        data?: T[]
        virtualized?: boolean
        autocompleteProps?: Partial<AutocompleteProps<T, false, false, false>>
    } & PermissionProps & FormGridProps & ValidationProps


export function FormAutocompleteSingle<T>(props: FormAutocompleteSingleProps<T>) {
    const { label, disabled, readOnly, className, getData, onChange, value, idSelector, valueId, renderOption, displaySelector, virtualized, 'grid-item': gridItem, xs, sm, md, lg, xl, autocompleteProps, errorState, ...otherProps } = props

    const [data, setData] = useState<T[]>([])
    const [internalValue, setInternalValue] = useState<T>()

    useEffect(() => { if (props.data) setData(props.data) }, [props.data])
    useEffect(() => { getData?.().then(c => setData(c)) }, [getData])

    useEffect(() => { setInternalValue(data.find(d => idSelector(d) === valueId)!) }, [valueId, data])

    useEffect(() => { setInternalValue(value) }, [value])

    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    return <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
        <Autocomplete
            disableListWrap
            ListboxComponent={virtualized ? ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>> : undefined}
            renderGroup={renderGroup}
            getOptionLabel={o => displaySelector(o)}
            renderOption={renderOption}
            options={data}
            loading={data.length === 0}
            value={internalValue || null}
            onChange={disabled || isReadOnly ? undefined : (_e, x) => props.onChange?.(x || undefined, x ? idSelector(x) : undefined)}
            renderInput={(params) => <FormTextField {...params} errorState={errorState} readOnly={isReadOnly} disabled={disabled} label={label} />}
            {...autocompleteProps}
        />
    </MaybeWrapToGrid>
}

interface TextMaskCustomProps extends MaskedInputProps {
    inputRef: (ref: HTMLInputElement | null) => void
}

const UnitIdentifierTextMask = (props: TextMaskCustomProps) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null)
            }}
        />
    );
}

type FormTextfieldMaskedImplProps = { label?: string } & FormComponentProps<InputProps> & { maskProps: MaskedInputProps }
export type FormTextFieldMaskedProps = Omit<FormTextfieldMaskedImplProps, 'classes'>

export const FormTextfieldMasked = withStyles(commonFormStyles)((props: FormTextfieldMaskedImplProps) => {
    const { classes, children, className, 'grid-item': gridItem, value, label, onChange, readOnly, errorState, xs, sm, md, lg, xl, maskProps, ...other } = props
    const { hasPermission } = useUserInfoContext()
    const isReadOnly = readOnly || (props.canEdit && !hasPermission(props.canEdit))
    const hide = props.canRead && !hasPermission(props.canRead)

    const inputComponent = useCallback(p => UnitIdentifierTextMask({ ...maskProps, ...p }), [])

    return (
        <MaybeWrapToGrid grid-item={gridItem} xs={xs} sm={sm} md={md} lg={lg} xl={xl} hide={hide}>
            <FormControl className={clsx(classes.root, classes.fullWidth, className)} fullWidth>
                {label && <InputLabel>{label}</InputLabel>}
                <Input value={value || ''} error={!!errorState} onChange={onChange} readOnly={isReadOnly} margin='dense' inputComponent={inputComponent as any} {...other} />
                {errorState ? <FormHelperText error>{errorState.error}</FormHelperText> : null}
            </FormControl>
        </MaybeWrapToGrid>
    )
})

export interface FormButtonProps extends ButtonProps {
    onClickAsync?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
}

export const FormButton = (props: FormButtonProps) => {
    const { disabled, onClickAsync, onClick, ...other } = props
    const styles = useCommonStyles()
    const [lock, setLock] = useState(false)

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClickAsync) {
            setLock(true)
            onClickAsync(e).finally(() => setLock(false))
        }
        else
            onClick?.(e)
    }

    return <Button className={styles.button} disabled={disabled || lock} onClick={handleClick} {...other} />
}