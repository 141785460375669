import { createContext, useContext } from "react"
import { SupportedLanguage, TenantId } from "../api/WellKnowIds"

type ContentLanguage = 'el-GR' | 'en-UK' | 'bg-BG'

export const toContentLanguage = (l: SupportedLanguage): ContentLanguage | null =>
    l === SupportedLanguage.Greek
        ? 'el-GR' : l === SupportedLanguage.English
            ? 'en-UK' : l === SupportedLanguage.Bulgarian
                ? 'bg-BG' : null

export type InternationalizationContextType =
    {
        language: SupportedLanguage,
        tenant: TenantId,
        setLanguage: (l: SupportedLanguage) => void
        setTenant: (l: TenantId) => void
    }

export const InternationalizationContext = createContext<InternationalizationContextType>(null as any)

export const useInternationalizationContext = () => {
    const internationalization = useContext(InternationalizationContext)

    return {
        contentLanguage: internationalization.language,
        tenantId: internationalization.tenant,
        setLanguage: internationalization.setLanguage,
        setTenant: internationalization.setTenant,
    }
}