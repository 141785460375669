import { Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { DocumentsGrid } from "../../DocumentsGrid"
import { useApiContext } from "../../wtf/api/ApiContext"
import { ExternalUserSummary } from "../../wtf/api/ExternalUsers"
import { FormButton } from "../../wtf/components/forms/FormComponents"
import routes from "../Shared/routes"



const useStyles = makeStyles(theme => ({
    actionsGrid: {
        minWidth: '100%',
        minHeight: '100%',
        justifyItems: 'center',
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}))


export const DocumentsDownload = () => {
    const classes = useStyles()
    const api = useApiContext()
    const history = useHistory()
    const [summary, setSummary] = useState<ExternalUserSummary>()

    useEffect(() => {
        api.externalUsers.getSummary().then(s => setSummary(s))
    }, [])

    return <Card style={{ marginTop: 16, marginBottom: 16, padding: 8 }}>
        <CardContent>
            <Grid container spacing={4} alignItems="center" justify="center" className={classes.actionsGrid}>
                <Grid item xs={12} className={classes.divider}>
                    <Typography variant="h3">Εισερχόμενα</Typography>
                </Grid>
                {(summary?.customerId || summary?.applicationId) &&
                    <>
                        <Grid item xs={12}>
                            <DocumentsGrid dataGridId='indocs' customerId={summary?.customerId} applicationId={summary?.applicationId} type="inbox" />
                        </Grid>
                    </>
                }
            </Grid>
        </CardContent>
        <CardActions className={classes.divider}>
            <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item>
                    <FormButton variant='outlined' color='inherit' onClick={() => history.push(routes.HOME.template)}>Επιστροφή</FormButton>
                </Grid>
            </Grid>
        </CardActions>
    </Card>

}

