import { Theme } from "@material-ui/core"
import { fade, makeStyles } from '@material-ui/core/styles'

export const useCommonStyles = makeStyles((theme: Theme) => ({
    mainToolbar: {
        color: theme.palette.primary.main,
        flexGrow: 1,
        width: '100%'
    },
    buttonContainer: {
        flex: 1,
        float: "right"
    },
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    popoverEntry: {
        marginLeft: theme.spacing(0.1)
    },
    adornmentIconButton: {
        margin: 0,
        padding: 0,
        '&:hover': {
            background: theme.palette.background.default,
        },
        '&:focus': {
            background: theme.palette.background.default,
        },
        '&:active': {
            background: theme.palette.background.default,
        },
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        //padding: theme.spacing(0.5),
    },
    chip: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0.5),
    },
    iconWithTextRight: {
        marginRight: theme.spacing(0.5),
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    iconWithTextLeft: {
        marginLeft: theme.spacing(0.5),
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    grow: {
        flexGrow: 1,
    },
    fixedMarginTop: {
        marginTop: 19
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 100,
    },
    noMargin: {
        margin: theme.spacing(0),
    },
    selectedTableRow: {
        background: fade(theme.palette.primary.main, 0.1),
    },
    dataGridCheckbox: {
        alignContent: 'center',
        textAlign: 'center',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
    },
    dataGridCheckboxCell: {
        maxWidth: '9px',
    },
    datagridToolbarIconButton: {
        marginLeft: theme.spacing(0.5),
        marginRIght: theme.spacing(0.5),
        color: theme.palette.primary.main,
    },
    circularProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    appBarTitle: {
        flexGrow: 1,
    },
    appBarMenuButton: {
        marginRight: theme.spacing(2),
    },
    pulseEffect: {
        display: 'inline-block',
        position: 'relative',
        '&::before': {
            content: "''",
            display: 'inline-block',
            position: 'absolute',
            //top: '50%',
            left: '25%',
            width: '50%',
            height: '50%',
            borderRadius: '50%',
            animation: '$pulseEffect 3s ease-in-out .1s infinite',
        }
    },
    "@keyframes pulseEffect": {
        "0%": {
            opacity: 0.2,
        },
        "40%": {
            opacity: 0.4,
            boxShadow: '0 0 0 1px rgb(0, 51, 0), 0 0 5px 5px rgb(0, 51, 0), 0 0 0 5px rgb(0, 51, 0)',
        },
        "100%": {
            opacity: 0,
            transform: 'scale(1.5)',
            boxShadow: '0 0 0 1px rgb(0, 51, 0), 0 0 5px 5px rgb(0, 51, 0), 0 0 0 5px rgb(0, 51, 0)',
        }
    }
}))