import { Box, Divider, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { ApiClient, BlobResponse } from '../../api/ApiClient'
import { useApiContext } from '../../api/ApiContext'
import { ApiDescription, FilterWithValue, Hrefs, Paginated, PaginationRequest, PaginationResponse, SortCriterionWithValue } from '../../api/Search'
import { ApiServicesProvider, ProgressReportingBar } from '../../progress-reporting/ProgressReporting'
import { useToggle } from '../../refresh/Toggle'
import { caseNever } from '../../utils/never'
import { ButtonDrawerEntry } from '../button-drawer/ButtonDrawer'
import { DataGridCheckboxController, SelectionState } from './DataGridCheckboxController'
import { columnKey, DataGridToolbar, loadSettings } from './DataGridToolbar'
import { TablePaginationActions } from './TablePaginationActions'
import { ColumnDescriptor, DataGridRow, StyledTableCell } from './TableStyling'

const useStyles = makeStyles(theme => ({
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 200px)',
    },
    pagination: {
        right: 0,
    },
    tableContainer: {
        flexGrow: 1,
        minHeight: 0,
        overflow: 'scroll'
    },
    selectedText: {
        minHeight: '52px',
        paddingLeft: 9,
        paddingTop: 18,
    }
}))

export type HrefOrExplicitRequest =
    | string
    | { pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[] }

export function mkPaginationLoader<T>(client: {
    getFromHref: (href: string) => Promise<Paginated<T>>,
    get: (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => Promise<Paginated<T>>
}) {
    return async (request: HrefOrExplicitRequest) =>
        typeof request === "string"
            ? await client.getFromHref(request)
            : await client.get(request.pagination, request.filters, request.sorting)
}

export type Selection<T> = 'all' | 'none' | { [key: number]: T }

export type ReadOnlyDataGridProps<T> =
    {
        dataGridId: string
        columns: ColumnDescriptor<T>[]
        dataLoader: (requestInfo: HrefOrExplicitRequest, api: ApiClient) => Promise<Paginated<T>>
        apiDescriptionLoader?: (api: ApiClient) => Promise<ApiDescription>
        rowPopover?: (x: T) => React.ReactNode
        onRowDoubleClick?: (x: T) => void
        otherActionsFactory?: (pageData: T[], filters: FilterWithValue[], selection: Selection<T>) => ButtonDrawerEntry[]
        hiddenComponents?: (pageData: T[], filters: FilterWithValue[], selection: Selection<T>) => React.ReactNode[]
        select?: (row: T) => number
        onSelectionChanged?: (selection: Selection<T>) => void
        pagination?: boolean
        paginationAll?: boolean
        export?: (filter: FilterWithValue[], sorting: SortCriterionWithValue[], api: ApiClient) => Promise<BlobResponse | null>
        refreshToggle?: boolean
        pageSize?: number
    }

type State<T> =
    {
        data: T[]
        selected: 'all' | 'none' | { [key: number]: T }
        rowsPerPage: number
        pagination: PaginationResponse
        rowsPerPageOptions: (number | { value: number; label: string })[]
        filters?: FilterWithValue[]
        sorting?: SortCriterionWithValue[]
    }

const ReadOnlyDataGridImpl = <T extends {}>(props: ReadOnlyDataGridProps<T>) => {
    const api = useApiContext()
    const classes = useStyles()
    const [visibleColumns, setVisibleColumns] = useState<ColumnDescriptor<T>[]>([])
    const layoutUpdate = useToggle()
    const outerRef = useRef()
    const [gridState, setGridState] = useState<State<T>>({
        data: [],
        selected: {},
        rowsPerPage: 30,
        pagination: {
            size: 30,
            totalCount: 0,
            page: 0,
            hrefs: {
                first: "",
            }
        },
        rowsPerPageOptions: props.paginationAll ? [10, 30, 100, { value: -1, label: 'All' }] : [10, 30, 100],
    })

    useEffect(() => {
        loadSettings(props.dataGridId).then(s => {
            if (s?.columnsOrdered?.length ?? 0 > 0) {
                const cols = s!.columnsOrdered.map(co => props.columns.find(c => columnKey(c) === co)!).filter(x => x)
                setVisibleColumns(cols)
            }
            else
                setVisibleColumns(props.columns)
        })
    }, [props.dataGridId, props.columns, layoutUpdate.toggle])


    useEffect(() => {
        if (gridState.filters && gridState.sorting) {
            props.dataLoader({ pagination: gridState.pagination, filters: gridState.filters, sorting: gridState.sorting }, api)
                .then(response => setGridState(st => ({ ...st, pagination: response.pagination, data: response.items })))
        }
    }, [gridState.filters, gridState.sorting, gridState.pagination.page, gridState.pagination.size])

    useEffect(() => {
        (async () => {
            if ((gridState.filters && gridState.sorting) || !props.apiDescriptionLoader) {
                const getIdSet = (data: T[]) => props.select ? data.map(d => props.select!(d)) : undefined

                const response = await props.dataLoader({ pagination: gridState.pagination, filters: gridState.filters ?? [], sorting: gridState.sorting ?? [] }, api)

                setGridState(st => {
                    const previousIdSet = getIdSet(gridState.data)
                    const currentIdSet = getIdSet(response.items)

                    var selected = st.selected
                    if (previousIdSet === undefined && currentIdSet === undefined) {
                        ;
                    }
                    else if (previousIdSet === undefined || currentIdSet === undefined)
                        selected = 'none'
                    else if (typeof selected === 'object') {
                        const diff = _.differenceWith(previousIdSet, currentIdSet, _.isEqual)
                        if (diff.length) {
                            selected = _.filter(selected, (v, k) => {
                                const key = Number(k)
                                return currentIdSet.includes(key) && // Keep if in current page, clear rest of pages
                                    !diff.some(d => d === Number(k)) // but not in diff
                            })
                        }
                        // None left
                        if (_.keys(selected).length === 0)
                            selected = 'none'
                    }

                    return { ...st, selected: selected, pagination: response.pagination, data: response.items }
                })
            }
        })()
    }, [props.refreshToggle])

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const size = Number.parseInt(event.target.value)
        const newPagination = { page: 0, size: size, totalCount: gridState.pagination.totalCount, hrefs: { first: "" } }
        setGridState({ ...gridState, rowsPerPage: size, pagination: newPagination })
    }

    const handlePageChange = async (hrefName: keyof Hrefs) => {
        const href = gridState.pagination.hrefs[hrefName]
        if (href) {
            const response = await props.dataLoader(href, api)
            setGridState({ ...gridState, pagination: response.pagination, data: response.items })
        }
        else
            throw Error(`Invalid href ${hrefName}`)
    }

    const updateFilters = async (filters: FilterWithValue[], sort: SortCriterionWithValue[]) => {
        setGridState(st => ({ ...st, selected: 'none', pagination: { ...st.pagination, page: 0, size: st.rowsPerPage }, filters: filters, sorting: sort }))
    }

    const handleSelectionChanged = (selection: T, checked: boolean) => {
        if (!props.select) return
        const key = props.select(selection)
        setGridState(st => {
            if (st.selected === 'all') {
                props.onSelectionChanged?.('all')
                return st
            }
            else if (st.selected === 'none')
                if (!checked) {
                    props.onSelectionChanged?.('none')
                    return st
                }
                else {
                    const newState = { [key]: selection }
                    props.onSelectionChanged?.(newState)
                    return { ...st, selected: newState }
                }
            else {
                if (checked) {
                    const newState = { ...st.selected, [key]: selection }
                    props.onSelectionChanged?.(newState)
                    return { ...st, selected: newState }
                }
                else {
                    const { [key]: _, ...newSelectedState } = st.selected
                    if (Object.keys(newSelectedState).length === 0) {
                        props.onSelectionChanged?.('none')
                        return { ...st, selected: 'none' }
                    }
                    props.onSelectionChanged?.(newSelectedState)
                    return { ...st, selected: newSelectedState }
                }
            }
        })
    }

    const handleToolbarSelectionChanged = (e: SelectionState) => {
        setGridState(st => {
            switch (e) {
                case 'none': return { ...st, selected: 'none' }
                case 'page': return { ...st, selected: _.reduce(st.data.map(x => ({ [props.select?.(x) || 0]: x })), (a, c) => _.assign(a, c), {}) }
                case 'all': return { ...st, selected: 'all' }
                case 'partial': return { ...st, selected: st.selected }
                default: return caseNever(e)
            }
        })
    }

    return (
        <Box className={classes.outerContainer} {...{ ref: outerRef }} >
            {
                (props.select || props.otherActionsFactory || props.export || props.apiDescriptionLoader) &&
                <DataGridToolbar
                    dataGridId={props.dataGridId}
                    select={props.select ? {
                        selectionValue: gridState.selected === 'all' ? 'all' : gridState.selected === 'none' ? 'none' : 'partial',
                        onSelectionChanged: handleToolbarSelectionChanged,
                    } : undefined}
                    getApiDescription={props.apiDescriptionLoader ? () => props.apiDescriptionLoader!(api) : undefined}
                    export={props.export && gridState.filters && gridState.sorting ? () => props.export!(gridState.filters!, gridState.sorting!, api) : undefined}
                    onStateUpdate={updateFilters}
                    otherActions={props.otherActionsFactory?.(gridState.data, gridState.filters ?? [], gridState.selected)}
                    columns={props.columns.map(c => ({ id: c.id, columnName: c.columnName }))}
                    refForFullscreen={outerRef.current}
                    onSettingsUpdated={layoutUpdate.switchToggle}
                />
            }
            <ProgressReportingBar />
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell key={2} />
                            {props.select &&
                                <StyledTableCell padding='none' align='left' key={1}>
                                    <DataGridCheckboxController
                                        value={gridState.selected === 'all' ? 'all' : gridState.selected === 'none' ? 'none' : 'partial'}
                                        onChange={handleToolbarSelectionChanged} />
                                </StyledTableCell>
                            }
                            {visibleColumns.map((x, i) => <StyledTableCell key={"th" + i}>{x.columnName}</StyledTableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {gridState.data.map((rowData, i) =>
                            <DataGridRow key={props.select?.(rowData) ?? i}
                                sequenceNumber={1 + i + gridState.pagination.page * gridState.rowsPerPage}
                                onSelectionChanged={props.select ? ch => handleSelectionChanged(rowData, ch) : undefined}
                                selected={gridState.selected === 'all' || (typeof gridState.selected === 'object' && !!gridState.selected[props.select?.(rowData) || 0])}
                                onRowDoubleClick={() => props.onRowDoubleClick?.(rowData)}
                                popover={props.rowPopover} columns={visibleColumns} rowData={rowData} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                (props.pagination || props.select) && <>
                    <Divider />
                    <Box display="flex" flexDirection="row">
                        {props.select &&
                            <Typography className={classes.selectedText} variant='body2' noWrap>
                                {gridState.selected === 'none' ? null
                                    : gridState.selected === 'all' ? 'Όλα'
                                        : `Επιλεγμένα ${Object.keys(gridState.selected).length} (από ${gridState.pagination.totalCount})`
                                }
                            </Typography>}
                        <div style={{ flex: 1 }} />
                        {props.pagination ?
                            <TablePagination
                                className={classes.pagination}
                                rowsPerPageOptions={gridState.rowsPerPageOptions}
                                component={Box}
                                count={gridState.pagination.totalCount}
                                rowsPerPage={gridState.rowsPerPage}
                                page={gridState.pagination.page}
                                backIconButtonProps={{ 'aria-label': 'previous page', }}
                                nextIconButtonProps={{ 'aria-label': 'next page', }}
                                ActionsComponent={TablePaginationActions as any}
                                onChangePage={(_, page: any) => handlePageChange(page)}
                                onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)} /> : null}
                    </Box>
                </>
            }
            {props.hiddenComponents?.(gridState.data, gridState.filters ?? [], gridState.selected)}
        </Box>
    )
}

export const ReadOnlyDataGrid = <T extends {}>(props: ReadOnlyDataGridProps<T>) => {
    return <ApiServicesProvider>
        <ReadOnlyDataGridImpl {...props} />
    </ApiServicesProvider>
}