import _ from 'lodash'
import { useCallback, useDebugValue, useEffect, useState } from 'react'
import { useNotificationsContext } from '../../notifications/SnackbarContext'
import { caseNever } from '../../utils/never'
import { datePart, isValidDate } from '../../utils/utils'

type ErrorState<T, U> = { [K in keyof T]?: string | ErrorState<T[K], {}> } & { customValidation?: { [UKey in keyof U]: string } }

type FormState<T, U> =
    {
        value: T
        errors: ErrorState<T, U>
        pendingChanges: boolean
        lastChangeType: undefined | 'field' | 'reset'
        submitting: boolean
    }

export type PropertyValidator<T> = (value: T) => { valid: true, nested?: FormValidator<T> } | { valid: false, errorMessage: string }

type PropertyBasedValidator<T> = { [K in keyof T]?: PropertyValidator<T[K]> }
type CustomValidator<T, U> = { [K in keyof U]: PropertyValidator<T> }

export type FormValidator<T, U = {}> = PropertyBasedValidator<T> & { customValidation?: CustomValidator<T, U> }

export type FormChangeEvent = string | number | undefined | boolean | { target: { value: unknown } } // TODO strongly typed

export const valueToEvent = (value: any) => ({ target: { value: value } }) // TODO remove

export const eventToValue = (e: FormChangeEvent) => typeof e === 'string' ? e : typeof e === 'number' ? e : typeof e === 'boolean' ? e : e?.target.value

const validate = <T>(key: keyof T, newValue: unknown, errors: ErrorState<T, {}>, validator: FormValidator<T>) => {
    const validation = validator?.[key]?.(newValue as any)
    const oldError = errors[key]
    let newErrors: ErrorState<T, {}>;

    if (validation?.valid || validation === undefined) {
        if (validation?.nested === undefined)
            newErrors = (!oldError ? errors : { ...errors, [key]: undefined })
        else {
            let acc = {} as ErrorState<T, {}>
            const nv = newValue as any

            for (const key in validation.nested) {
                //const nestedValidator = (validation.nested as any)[key]
                const value = nv?.[key]
                const errs = validate<any>(key as any, value, acc, validation.nested as any)
                if (!errs || !errs?.[key]) continue
                acc = { ...acc, [key]: errs[key] }
            }

            newErrors = Object.keys(acc).length > 0 ? { ...errors, [key]: acc } : {}
        }
    }
    else
        newErrors = { ...errors, [key]: validation.errorMessage }

    return _.pickBy(newErrors, (value, _key) => value !== undefined) as ErrorState<T, {}>
}

export const combineErrorState = (...errorStates: ({ error: string; } | null)[]): { error: string; } | null => {
    for (const es of errorStates)
        if (es !== null) return es
    return null
}

const validateCustom = <T, U>(custom: CustomValidator<T, U>, key: keyof U, state: T) => {
    const v = custom[key](state)
    return v.valid ? null : { error: v.errorMessage }
}

export const useValidator = <T, U = {}>(validator: FormValidator<T, U>) => {
    const n = useNotificationsContext()

    const validateAll = (state: T) => {
        let errors: ErrorState<T, U> = {}

        const { customValidation, ...propertyValidator } = validator

        for (const key in customValidation) {
            const vr = customValidation[key](state)
            if (vr.valid) continue
            const cv = { ...errors.customValidation, [key]: vr.errorMessage }
            errors = { ...errors, customValidation: cv as any }
        }

        for (const key in propertyValidator as unknown as PropertyBasedValidator<T>) {
            const value = state[key]
            const errs = validate(key, value, errors, validator)
            errors = { ...errors, ...errs }
        }

        if (Object.keys(errors).length > 0) {
            n.errorNotification('Η επαλήθευση των πεδίων απέτυχε', _.map(errors, (v, j) => `${JSON.stringify(v)} (${j})`).join(', '))
        }


        return errors
    }

    return {
        isValid: (state: T) => Object.keys(validateAll(state)).length === 0,

        validateAll: validateAll,

        errorState: (key: keyof T, value: T[keyof T], errorState?: ErrorState<T, {}>) => {
            const errors = validate(key, value, errorState ?? {}, validator)
            return errors[key] !== undefined ? { error: errors[key] as string } : null
        },

        errorStateNested: <U = T[keyof T]>(key: keyof T, nestedKey: keyof U, value: U, errorState?: ErrorState<T, {}>) => {
            const errors = validate(key, value, errorState ?? {}, validator)
            const nested = errors[key] as any
            if (nested?.[nestedKey] && Object.keys(nested[nestedKey]).length > 0) return { error: nested[nestedKey] }
            else return null
        },

        errorStateCustom: (key: keyof U, state: T) => {
            if (!validator.customValidation) return null
            const cv = validateCustom(validator.customValidation, key, state)
            return cv !== null ? { error: cv.error } : null
        }
    }
}

export const useForm = <T, U = {}>(initial: T, formValidator?: FormValidator<T, U>, config?: { submitAction?: (value: T) => Promise<boolean | undefined | void> }) => {
    const [formState, setFormState] = useState<FormState<T, U>>({ value: initial, errors: {}, pendingChanges: false, lastChangeType: undefined, submitting: false })

    useDebugValue(formState.value)

    const validator = useValidator(formValidator ?? {})

    useEffect(() => {
        setFormState(st => {
            switch (formState.lastChangeType) {
                case undefined: return st
                case 'field': return { ...st, pendingChanges: true }
                case 'reset': return { ...st, pendingChanges: false }
                default: return caseNever(formState.lastChangeType)
            }
        })
    }, [formState.value, formState.lastChangeType])

    const handleChange = useCallback((key: keyof T) => (e: FormChangeEvent) => {
        const newValue = eventToValue(e)
        const newErrors = formValidator ? validate(key, newValue, formState.errors, formValidator) : {}
        setFormState(st => ({ value: { ...st.value, [key]: newValue }, errors: { ...newErrors, customValidation: undefined }, pendingChanges: st.pendingChanges, lastChangeType: 'field', submitting: false }))
    }, [])

    const reset = useCallback((resetState?: React.SetStateAction<T>) =>
        _.isFunction(resetState)
            ? setFormState(st => ({ value: resetState(st.value), errors: {}, pendingChanges: st.pendingChanges, lastChangeType: 'reset', submitting: false }))
            : setFormState(st => ({ value: resetState ?? initial, errors: {}, pendingChanges: st.pendingChanges, lastChangeType: 'reset', submitting: false })), [])

    const errorState = useCallback((key: keyof T) => formState.errors[key] !== undefined ? { error: formState.errors[key] as string } : null, [formState.errors])

    const errorStateNested = useCallback(<U extends T[keyof T]>(key: keyof T, nestedKey: keyof U) => {
        const nested = formState.errors[key] as any
        if (nested?.[nestedKey] && Object.keys(nested[nestedKey]).length > 0) return { error: nested[nestedKey] }
        else return null
    }, [formState.errors])

    const errorStateCustom = useCallback((key: keyof U) => {
        if (!formState.errors.customValidation) return null
        const errs = formState.errors.customValidation[key]
        return errs !== undefined ? { error: errs } : null
    }, [formState])

    const validateAll = useCallback(() => {
        const errors = validator.validateAll(formState.value)
        setFormState(st => ({ value: st.value, pendingChanges: st.pendingChanges, errors: errors, lastChangeType: st.lastChangeType, submitting: st.submitting }))
        return Object.keys(errors).length === 0
    }, [validator.validateAll, setFormState])

    const submit = useCallback(async () => {
        if (!config?.submitAction) return
        setFormState(st => ({ ...st, submitting: true }))

        try {
            if (!validateAll()) return false
            const result = await config?.submitAction?.(formState.value)
            return !!result
        }
        finally {
            setFormState(st => ({ ...st, submitting: false }))
        }

    }, [setFormState, formState.value, config?.submitAction, validateAll])

    return {
        state: formState.value,
        pendingChanges: formState.pendingChanges,
        handleChange,
        errorState,
        errorStateNested,
        errorStateCustom,
        validateAll,
        reset,
        submit,
        locked: formState.submitting,
    }
}

export class Validator<T>
{
    private validators: PropertyValidator<any>[] = []

    public constructor(protected name?: string) { }

    private mkError(message: string) {
        return { valid: false, errorMessage: `Το πεδίο ${this.name ? `'${this.name}' ` : ' '}${message}` }
    }

    public push(errorMessage: string, custom: (v: T) => boolean): this {
        this.validators.push(v => custom(v) ? { valid: true } : this.mkError(errorMessage))
        return this
    }

    public forAll<U>(childValidator: Validator<U>): this {
        const validator = childValidator.build()
        this.validators.push((v: U[]) => {
            const e = v?.map(x => validator(x))?.find(x => !x.valid)
            return e === undefined ? { valid: true } : e
        })
        return this
    }

    public build(): PropertyValidator<T> {
        return this.validators.reduce((acc, validator) => v => {
            const accV = acc(v)
            return accV.valid ? validator(v) : accV
        }, _ => ({ valid: true }))
    }

    public composite<U>(f: FormValidator<U>): PropertyValidator<T> {
        return (_value: T) => ({ valid: true, nested: f }) as any
    }

    public required(): this {
        this.validators.push(v => v === null || v === undefined || v as any === '' || v === 0 ? this.mkError('είναι υποχρεωτικό') : { valid: true })
        return this
    }

    public equalsTo(value: T): this {
        this.validators.push(v => v !== null && v !== undefined && v !== value ? this.mkError(`δεν έχει τιμή ${value}`) : { valid: true })
        return this
    }

    public nonDefaultDate(): this {
        this.validators.push((v: Date) => v !== null && v !== undefined && !isValidDate(v) ? this.mkError(`πρέπει να έχει έγκυρη τιμή`) : { valid: true })
        return this
    }

    public noFutureDate(): this {
        this.validators.push((v: Date) => v !== null && v !== undefined && v.getTime() > new Date().getTime() ? this.mkError(`δεν επιτρέπει μελλοντικές τιμές`) : { valid: true })
        return this
    }

    public noPastDate(): this {
        this.validators.push((v: Date) => v !== null && v !== undefined && datePart(v).getTime() < datePart(new Date()).getTime() ? this.mkError(`δεν επιτρέπει παρελθοντικές τιμές`) : { valid: true })
        return this
    }

    public nonEmpty(): this {
        this.validators.push((v: any[]) => v.length === 0 ? this.mkError(`πρέπει να έχει τουλάχιστον μια τιμή`) : { valid: true })
        return this
    }

    public empty(): this {
        this.validators.push((v: any[]) => v.length !== 0 ? this.mkError(`πρέπει να μην έχει καμία τιμή`) : { valid: true })
        return this
    }

    public nameLength(): this { return this.maxLength(limits.NAME_MAX_LENGTH).minLength(limits.NAME_MIN_LENGTH) }

    public taxRegistrationNumber(): this { return this.exactLength(9) }

    public nationalIdentityNumber(): this { return this.maxLength(limits.NATIONAL_IDENTITY_MAX_LENGTH) }

    public addressLine(): this { return this.maxLength(100) }
    public postalCode(): this { return this.regexExact(postalCodeRex, 'πρέπει να αποτελείται απο 5 ψηφία') }
    public email(): this { return this.maxLength(50) }
    public website(): this { return this.maxLength(50) }
    public note(): this { return this.maxLength(limits.NOTE_TEXT_MAX_LENGTH) }
    public iban(): this { return this.regexExact(new RegExp(`GR\\d{25}`), "πρεπει να εχει την σωστή μορφή") }

    public exactLength(l: number): this {
        this.validators.push(v => (v === null || v === undefined || v as any === '' || v?.length === l) ? { valid: true } : this.mkError(`πρέπει να έχει μήκος ακριβώς ${l}`))
        return this
    }

    public minLength(l: number): this {
        this.validators.push(v => v !== undefined && v !== null && v.length < l ? this.mkError(`πρέπει να έχει μήκος τουλάχιστον ${l}`) : { valid: true })
        return this
    }

    public maxLength(l: number): this {
        this.validators.push(v => v !== undefined && v !== null && v.length > l ? this.mkError(`πρέπει να έχει μήκος το πολύ ${l} (${v.length})`) : { valid: true })
        return this
    }

    public regexExact(m: RegExp, message: string): this {
        this.validators.push(v => {
            if (!v) return { valid: true }
            const match = v.match(m)
            return !match || match[0] !== v ? this.mkError(message) : { valid: true }
        })
        return this
    }

    public phone(): this {
        return this.digits(10)
    }

    public digits(length: number): this {
        return this.regexExact(new RegExp(`\\d{${length}}`), `πρέπει να αποτελείται από ${length} ψηφία`)
    }

    public positiveNumbers(): this {
        this.validators.push(v => v < 0 || (!Number.isFinite(v) && !(v === null || v === undefined)) ? this.mkError(`πρέπει να έχει θετική τιμή`) : { valid: true })
        return this
    }

    public isTrue(msg: string): this {
        this.validators.push(v => v !== null && v !== undefined && v !== true ? this.mkError(msg) : { valid: true })
        return this
    }
}

export const validator = <T = any>(name?: string) => new Validator<T>(name)
export const validatorOptional = <T = any>(name?: string) => new Validator<T | undefined>(name)
export const validatorCustom = <T>(name?: string) => new Validator<T>(name)

export const unitIdentifier = (name: string) => validatorOptional<string>(name).required().exactLength(limits.UNIT_IDENTIFIER_MAX_LENGTH).regexExact(/\d{3}-\d{3}-\d{4}-\d{3}/, 'πρέπει να έχει τη σωστή μορφή').build()

export const limits =
{
    UNIT_IDENTIFIER_MAX_LENGTH: 16,
    BUSINESS_NAME_MAX_LENGTH: 128,
    GENERIC_STRING_MAX_LENGTH: 128,
    NAME_MIN_LENGTH: 3,
    NAME_MAX_LENGTH: 30,
    FRAGMENT_CODE_LENGTH: 15,
    NOTE_TEXT_MAX_LENGTH: 65536,
    NATIONAL_IDENTITY_MAX_LENGTH: 10,
    NATIONAL_IDENTITY_MIN_LENGTH: 5
}

const postalCodeRex = /\d{5}/
export const postalCodeCheck = (input?: string) => {
    const match = input?.match(postalCodeRex)
    return (!match || match[0] !== input)
}