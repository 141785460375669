import { Card, CardActions, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, Radio, RadioGroup, Tooltip, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import { ArrowLeft, CloudDownload, Info, Refresh, Save } from "@material-ui/icons"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { BasicActivityGroupId, CertificationOrganizationViewModel, ContactInformationComponent, CustomerAddress, CustomerDetailsModel, EMPTY_APPLICATION_FORM, FirstApplication, LegalRepresentativeBase, LegalTypeId, TaxOffice } from "../../Application"
import { RegionCascadeSelect } from "../../RegionToCommunityDropdown"
import { useApiContext } from "../../wtf/api/ApiContext"
import { eventToValue, FormChangeEvent, limits, postalCodeCheck, useForm, validator, validatorCustom, validatorOptional, valueToEvent } from "../../wtf/components/forms/Form"
import { FormAutocompleteSingle, FormButton, FormDatePicker, FormTextField } from "../../wtf/components/forms/FormComponents"
import { isNotEmpty, toGreeklish } from "../../wtf/utils/utils"
import { viewOrDownloadFile } from "../../wtf/utils/viewOrDownloadFile"
import routes from "../Shared/routes"


const useStyles = makeStyles(theme => ({
    // actionsContainer: {
    //     marginTop: 48,
    //     minHeight: '100%',
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center"
    // },
    headerText: {
        textAlign: 'justify',
    },
    actions: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    divider: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}))

type ContactInfoUnprocessed = { landlineNumber?: string, mobileNumber?: string, faxNumber?: string, email?: string, website?: string }

export const Application = () => {
    const classes = useStyles()
    const api = useApiContext()
    const history = useHistory()

    const [refreshToggle, setRefreshToggle] = useState(false)
    const contactInfo = useForm<ContactInfoUnprocessed>({ mobileNumber: "" }, { mobileNumber: validatorOptional<string>('Κινητό τηλέφωνο').required().minLength(10).build() }, { submitAction: async _ => { return true } })
    const legalRepresentativeContactInfo = useForm<ContactInfoUnprocessed>({})
    const [prevStateForLatin, setPrevStateForLatin] = useState<{ firstName?: string, lastName?: string, middleName?: string, distinctiveTitle?: string, businessTitle?: string, addressLine1?: string }>({})
    const applicationForm = useForm(EMPTY_APPLICATION_FORM,
        {
            dateOfApplication: validator<Date>("Ημερομηνία αίτησης").required().nonDefaultDate().build(),
            details: validator<CustomerDetailsModel>().composite(
                {
                    distinctiveTitle: validator<string>('Διακριτικός Τίτλος').required().minLength(limits.NAME_MIN_LENGTH).maxLength(limits.BUSINESS_NAME_MAX_LENGTH).build(),
                    dateOfBirth: validatorOptional<Date>("Ημερομηνία Γέννησης").nonDefaultDate().build(),
                    legalTypeId: validatorOptional<LegalTypeId | undefined>("Νομική μορφή").required().build(),
                    businessTitle: validatorOptional<string>('Επωνυμία').minLength(limits.NAME_MIN_LENGTH).maxLength(limits.BUSINESS_NAME_MAX_LENGTH).build(),
                }),
            customValidation: {
                addressLine1: validatorCustom<FirstApplication>('Διεύθυνση').push('είναι υποχρεωτικό', x => !x.details.address || (isNotEmpty(x.details.address) && !!x.details.address.addressLine1)).build(),
                postalCode: validatorCustom<FirstApplication>('Τ.Κ.').push('είναι υποχρεωτικό στη σωστή μορφή', x => !x.details.address || (isNotEmpty(x.details.address) && !!x.details.address.postalCode && !postalCodeCheck(x.details.address.postalCode))).build(),
                communityId: validatorCustom<FirstApplication>('Κοινότητα').push('είναι υποχρεωτικό', x => !x.details.address || (isNotEmpty(x.details.address) && !!x.details.address.communityId)).build(),
                mailingAddressLine1: validatorCustom<FirstApplication>('Διεύθυνση').push('είναι υποχρεωτικό', x => !x.details.mailingAddress || (isNotEmpty(x.details.mailingAddress) && !!x.details.mailingAddress.addressLine1)).build(),
                mailingPostalCode: validatorCustom<FirstApplication>('Τ.Κ.').push('είναι υποχρεωτικό στη σωστή μορφή', x => !x.details.mailingAddress || (isNotEmpty(x.details.mailingAddress) && !!x.details.mailingAddress.postalCode)).build(),
                mailingCommunityId: validatorCustom<FirstApplication>('Κοινότητα').push('είναι υποχρεωτικό', x => !x.details.mailingAddress || (isNotEmpty(x.details.mailingAddress) && !!x.details.mailingAddress.communityId)).build(),
                firstNameLegal: validatorCustom<FirstApplication>('Όνομα Νόμιμου εκπροσώπου').push('είναι υποχρεωτικό στη σωστή μορφή', x => !x.details.legalRepresentative || (isNotEmpty(x.details.legalRepresentative) && !!x.details.legalRepresentative.firstName && x.details.legalRepresentative.firstName.length <= limits.NAME_MAX_LENGTH && x.details.legalRepresentative.firstName.length >= limits.NAME_MIN_LENGTH)).build(),
                lastNameLegal: validatorCustom<FirstApplication>('Επώνυμο Νόμιμου εκπροσώπου').push('είναι υποχρεωτικό στη σωστή μορφή', x => !x.details.legalRepresentative || (isNotEmpty(x.details.legalRepresentative) && !!x.details.legalRepresentative.lastName && x.details.legalRepresentative.lastName.length <= limits.NAME_MAX_LENGTH && x.details.legalRepresentative.lastName.length >= limits.NAME_MIN_LENGTH)).build(),
                nationalIdentityNumberLegal: validatorCustom<FirstApplication>('ΑΔΤ').push('είναι υποχρεωτικό στη σωστή μορφή', x => !x.details.legalRepresentative || (isNotEmpty(x.details.legalRepresentative) && !!x.details.legalRepresentative.nationalIdentityNumber && x.details.legalRepresentative.nationalIdentityNumber.length <= limits.NATIONAL_IDENTITY_MAX_LENGTH && x.details.legalRepresentative.nationalIdentityNumber.length >= limits.NATIONAL_IDENTITY_MIN_LENGTH)).build(),
            },
        },
        {
            submitAction: async app => {
                const legalContactInfo = getLegalRepresentativeContactInfo()
                const customerContactInfo = getContactInfo()
                const legalRepresentativeDetails = app.details.legalRepresentative === undefined || app.details.legalRepresentative === null || app.details.legalTypeId === LegalTypeId.NaturalPerson ? undefined : { ...app.details.legalRepresentative, contactInformation: legalContactInfo }
                const application = {
                    ...app, details: {
                        ...app.details, contactInformation: customerContactInfo, legalRepresentative: legalRepresentativeDetails
                    }
                }
                const response = await api.applications.upsertFirstApplication(application)
                if (response) {
                    refreshApplication()
                    return true
                }
            }
        })


    const getLegalRepresentativeContactInfo = useCallback(() => {
        return {
            mobileNumber: stringToContactInfo(legalRepresentativeContactInfo.state.mobileNumber),
            email: stringToContactInfo(legalRepresentativeContactInfo.state.email),
            faxNumber: stringToContactInfo(legalRepresentativeContactInfo.state.faxNumber),
            landlineNumber: stringToContactInfo(legalRepresentativeContactInfo.state.landlineNumber),
            website: []
        }
    }, [legalRepresentativeContactInfo.state])


    const getContactInfo = useCallback(() => {
        return {
            mobileNumber: stringToContactInfo(contactInfo.state.mobileNumber),
            email: stringToContactInfo(contactInfo.state.email),
            faxNumber: stringToContactInfo(contactInfo.state.faxNumber),
            landlineNumber: stringToContactInfo(contactInfo.state.landlineNumber),
            website: stringToContactInfo(contactInfo.state.website)
        }
    }, [contactInfo.state])

    const handleCustomerDetailsChange = (key: keyof CustomerDetailsModel) => (e: FormChangeEvent) => applicationForm.handleChange('details')(valueToEvent({ ...applicationForm.state.details, [key]: eventToValue(e) }))
    const handleLegalRepresentativeChange = (key: keyof LegalRepresentativeBase) => (e: FormChangeEvent) => handleCustomerDetailsChange('legalRepresentative')(valueToEvent({ ...applicationForm.state.details.legalRepresentative, [key]: eventToValue(e) }))
    const handleAddressChange = (key: keyof CustomerAddress) => (e: FormChangeEvent) => handleCustomerDetailsChange('address')(valueToEvent({ ...applicationForm.state.details.address, [key]: eventToValue(e) }))
    const handleMailingAddressChange = (key: keyof CustomerAddress) => (e: FormChangeEvent) => handleCustomerDetailsChange('mailingAddress')(valueToEvent({ ...applicationForm.state.details.mailingAddress, [key]: eventToValue(e) }))
    const handleGroupsChange = useCallback((key: BasicActivityGroupId) => (input: boolean) => {
        if (input)
            applicationForm.handleChange('activityGroups')(valueToEvent([...applicationForm.state.activityGroups, key]))
        else
            applicationForm.handleChange('activityGroups')(valueToEvent(applicationForm.state.activityGroups.filter(x => x !== key)))
    }, [applicationForm.state, applicationForm.state.activityGroups])


    useEffect(() => {
        api.applications.getApplication().then(s => {
            if (!!s.applicationId) {
                applicationForm.reset(s);
                const infos = s.details.contactInformation
                contactInfo.reset({
                    mobileNumber: contactInfoToString(infos?.mobileNumber),
                    faxNumber: contactInfoToString(infos?.faxNumber),
                    landlineNumber: contactInfoToString(infos?.landlineNumber),
                    email: contactInfoToString(infos?.email),
                    website: contactInfoToString(infos?.website)
                })
                if (s.details.legalTypeId === LegalTypeId.LegalPerson) {
                    const legalInfos = s.details.legalRepresentative?.contactInformation
                    legalRepresentativeContactInfo.reset({
                        mobileNumber: contactInfoToString(legalInfos?.mobileNumber),
                        faxNumber: contactInfoToString(legalInfos?.faxNumber),
                        landlineNumber: contactInfoToString(legalInfos?.landlineNumber),
                        email: contactInfoToString(legalInfos?.email),
                        website: ""
                    })
                }
            }
            else {
                api.externalUsers.getSummary().then(x => { applicationForm.handleChange('taxRegistrationNumber')(x.taxRegistrationNumber); contactInfo.reset({ email: x.email }) })
            }
        })
    }, [refreshToggle])


    useEffect(() => {
        if (applicationForm.state.details.legalTypeId === LegalTypeId.NaturalPerson && (!!applicationForm.state.details.legalRepresentative || !!legalRepresentativeContactInfo)) {
            applicationForm.reset({
                ...applicationForm.state, details: { ...applicationForm.state.details, legalRepresentative: undefined, businessTitle: undefined }
            })
            legalRepresentativeContactInfo.reset({})
        }
        if (applicationForm.state.details.legalTypeId === LegalTypeId.LegalPerson) {
            applicationForm.reset({
                ...applicationForm.state, details: { ...applicationForm.state.details, legalRepresentative: undefined, firstName: undefined, lastName: undefined }
            })
        }

    }, [applicationForm.state.details.legalTypeId])

    useEffect(() => {
        handleLatin('distinctiveTitle', 'distinctiveTitleLatin', applicationForm.state.details.distinctiveTitle, applicationForm.state.details.distinctiveTitleLatin, prevStateForLatin.distinctiveTitle)
    }, [applicationForm.state.details.distinctiveTitle])

    useEffect(() => {
        handleLatin('firstName', 'firstNameLatin', applicationForm.state.details.firstName, applicationForm.state.details.firstNameLatin, prevStateForLatin.firstName)
    }, [applicationForm.state.details.firstName])

    useEffect(() => {
        handleLatin('lastName', 'lastNameLatin', applicationForm.state.details.lastName, applicationForm.state.details.lastNameLatin, prevStateForLatin.lastName)
    }, [applicationForm.state.details.lastName])

    useEffect(() => {
        handleLatin('businessTitle', 'businessTitleLatin', applicationForm.state.details.businessTitle, applicationForm.state.details.businessTitleLatin, prevStateForLatin.businessTitle)
    }, [applicationForm.state.details.businessTitle])

    useEffect(() => {
        if (applicationForm.state.details.address?.addressLine1Latin === toGreeklish(prevStateForLatin.addressLine1 || '') || !applicationForm.state.details.address?.addressLine1Latin)
            handleAddressChange('addressLine1Latin')(toGreeklish(applicationForm.state.details.address?.addressLine1 ?? ""))
        setPrevStateForLatin(st => ({ ...st, addressLine1: applicationForm.state.details.address?.addressLine1 ?? "" }))
    }, [applicationForm.state.details.address?.addressLine1])

    const handleLatin = (key: keyof CustomerDetailsModel, keyLatin: keyof CustomerDetailsModel, inputGreek?: string, latinString?: string, prevLatin?: string) => {
        if (latinString === toGreeklish(prevLatin || '') || !latinString)
            handleCustomerDetailsChange(keyLatin)(toGreeklish(inputGreek ?? ""))
        setPrevStateForLatin(st => ({ ...st, [key]: toGreeklish(inputGreek ?? "") }))
    }

    const getOrganizations = useCallback(() => api.applications.getCertificationOrganizations(), [])
    const getTaxOffices = useCallback(() => api.applications.getTaxOffices(), [])
    const stringToContactInfo = (input?: string) => input?.split(',')?.map(x => x.trim())?.filter(x => x)?.map(v => ({ value: v })) || []
    const contactInfoToString = (input?: ContactInformationComponent[]) => input?.map(x => x.value).join(', ') || undefined
    const refreshApplication = () => setRefreshToggle(!refreshToggle)

    const handleSubmit = useCallback(async () => {
        const rs = await contactInfo.submit()
        if (rs !== false)
            applicationForm.submit()
    }, [contactInfo.submit, applicationForm.submit])

    const isChecked = useCallback((id: BasicActivityGroupId) => applicationForm.state.activityGroups.some(x => x === id), [applicationForm.state.activityGroups])

    const Divide = (props: { children?: any }) =>
        <Grid item xs={12} className={classes.divider}>
            <Typography variant="overline">
                {props.children}
            </Typography>
        </Grid>

    const MaybeBold = (props: { bold?: boolean, children?: any }) =>
        props.bold ? <b>{props.children}</b> : props.children

    return <Card style={{ marginTop: 16, marginBottom: 16, padding: 8 }} elevation={16}>
        <CardContent>
            <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item xs={12} className={classes.divider}
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center">
                    <Typography variant="h3">ΑΙΤΗΣΗ{applicationForm.state.applicationId && <Typography variant="caption"> #{applicationForm.state.applicationId}</Typography>}</Typography>
                    <Typography variant="h6">ΓΙΑ ΤΗΝ ΕΝΤΑΞΗ ΣΤΟ ΣΥΣΤΗΜΑ ΠΙΣΤΟΠΟΙΗΣΗΣ ΒΙΟΛΟΓΙΚΩΝ ΠΡΟΪΟΝΤΩΝ ΣΥΜΦΩΝΑ ΜΕ ΤΟΝ ΚΑΝ.Ε.Ε.2018/848</Typography>
                </Grid>

                <Grid item xs={12} md={4} container spacing={1} direction='column'>
                    <Grid item xs={12}>
                        <FormControl component="fieldset" error={!!applicationForm.errorStateNested<CustomerDetailsModel>('details', 'legalTypeId')?.error} >
                            <FormLabel component="legend">Νομική μορφή</FormLabel>
                            <RadioGroup name="controlled-radio-buttons-group" value={applicationForm.state.details.legalTypeId.toString()} onChange={e => { handleCustomerDetailsChange('legalTypeId')(valueToEvent(Number.parseInt(e.target.value))) }} >
                                <FormControlLabel disabled={!!applicationForm.state.applicationId} value={LegalTypeId.NaturalPerson.toString()} control={<Radio color="primary" />} label="Φυσικό πρόσωπο" />
                                <FormControlLabel disabled={!!applicationForm.state.applicationId} value={LegalTypeId.LegalPerson.toString()} control={<Radio color="primary" />} label="Νομικό πρόσωπο" />
                            </RadioGroup>
                            <FormHelperText>{applicationForm.errorStateNested<CustomerDetailsModel>('details', 'legalTypeId')?.error}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6} container spacing={1} direction='column'>
                    <FormDatePicker xs={12} grid-item label="Ημερομηνία Αίτησης" value={applicationForm.state.dateOfApplication} onChange={e => applicationForm.handleChange('dateOfApplication')(valueToEvent(e))} errorState={applicationForm.errorState('dateOfApplication')} />

                    <FormTextField xs={12} grid-item disabled label="ΑΦΜ" value={applicationForm.state.taxRegistrationNumber} onChange={() => { }} InputProps={{ endAdornment: <InputAdornment position='end' ><Tooltip title="Επικοινωνήστε με το Βιο Ελλάς για τροποποίηση του ΑΦΜ"><Info /></Tooltip></InputAdornment> }} />

                    <FormAutocompleteSingle<TaxOffice> grid-item xs={12}
                        valueId={applicationForm.state.details.taxOfficeId}
                        getData={getTaxOffices}
                        label={"ΔΟΥ"}
                        idSelector={x => x.taxOfficeId}
                        displaySelector={x => x.name}
                        onChange={x => handleCustomerDetailsChange('taxOfficeId')(valueToEvent(x?.taxOfficeId))} />
                </Grid>

                <Divide>ΒΑΣΙΚΑ ΣΤΟΙΧΕΙΑ</Divide>

                <Grid item xs={12} md={8} container spacing={1} direction='column'>
                    <FormTextField xs={12} grid-item autoCaps label="Επώνυμο" disabled={applicationForm.state.details.legalTypeId === LegalTypeId.LegalPerson} value={applicationForm.state.details.lastName || ''} onChange={handleCustomerDetailsChange('lastName')} />
                    <FormTextField xs={12} grid-item autoCaps label="Όνομα" disabled={applicationForm.state.details.legalTypeId === LegalTypeId.LegalPerson} value={applicationForm.state.details.firstName || ''} onChange={handleCustomerDetailsChange('firstName')} />
                    <FormTextField xs={12} grid-item autoCaps label="Διακριτικός τίτλος" value={applicationForm.state.details.distinctiveTitle || ''} onChange={handleCustomerDetailsChange('distinctiveTitle')} errorState={applicationForm.errorStateNested<CustomerDetailsModel>('details', 'distinctiveTitle')} />
                    <FormTextField xs={12} grid-item autoCaps label="Επωνυμία" disabled={applicationForm.state.details.legalTypeId === LegalTypeId.NaturalPerson} value={applicationForm.state.details.businessTitle || ''} onChange={handleCustomerDetailsChange('businessTitle')} errorState={applicationForm.errorStateNested<CustomerDetailsModel>('details', 'businessTitle')} />
                </Grid>

                <Grid item xs={12} md={4} container spacing={1} direction='column' >
                    <FormTextField xs={12} grid-item label="Επώνυμο (Λατινικά)" disabled={applicationForm.state.details.legalTypeId === LegalTypeId.LegalPerson} value={applicationForm.state.details.lastNameLatin || ''} onChange={handleCustomerDetailsChange('lastNameLatin')} />
                    <FormTextField xs={12} grid-item label="Όνομα (Λατινικά)" disabled={applicationForm.state.details.legalTypeId === LegalTypeId.LegalPerson} value={applicationForm.state.details.firstNameLatin || ''} onChange={handleCustomerDetailsChange('firstNameLatin')} />
                    <FormTextField xs={12} grid-item label="Διακριτικός τίτλος (Λατινικά)" value={applicationForm.state.details.distinctiveTitleLatin || ''} onChange={handleCustomerDetailsChange('distinctiveTitleLatin')} errorState={applicationForm.errorStateNested<CustomerDetailsModel>('details', 'distinctiveTitle')} />
                    <FormTextField xs={12} grid-item label="Επωνυμία (Λατινικά)" disabled={applicationForm.state.details.legalTypeId === LegalTypeId.NaturalPerson} value={applicationForm.state.details.businessTitleLatin || ''} onChange={handleCustomerDetailsChange('businessTitleLatin')} />
                </Grid>

                <Divide>ΣΤΟΙΧΕΙΑ ΔΙΕΥΘΥΝΣΗΣ</Divide>

                <Grid item xs={12}>
                    <RegionCascadeSelect communityId={applicationForm.state.details?.address?.communityId} onCommunitySelectionChanged={e => handleAddressChange('communityId')(valueToEvent(e))} errorState={applicationForm.errorStateCustom('communityId')} />
                </Grid>

                <FormTextField grid-item xs={12} md={6} autoCaps label={'Διεύθυνση'} value={applicationForm.state.details?.address?.addressLine1 || ''} onChange={handleAddressChange('addressLine1')} errorState={applicationForm.errorStateCustom('addressLine1')} />
                <FormTextField grid-item xs={12} md={2} autoCaps label={'Τ.Κ.'} value={applicationForm.state.details?.address?.postalCode || ''} onChange={handleAddressChange('postalCode')} errorState={applicationForm.errorStateCustom('postalCode')} />
                <FormTextField grid-item xs={12} md={4} label={'Διεύθυνση (Λατινικά)'} value={applicationForm.state.details?.address?.addressLine1Latin || ''} onChange={handleAddressChange('addressLine1Latin')} errorState={applicationForm.errorStateCustom('addressLine1')} />
                <FormTextField grid-item xs={12} md={12} autoCaps label={'Λεπτομέρειες'} helperText={"Π.χ. αριθμός πόρτας, διαμερίσματος, όροφος, σημειώσεις"} value={applicationForm.state.details?.address?.addressLine2 || ''} onChange={handleAddressChange('addressLine2')} />

                <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary">
                        Διεύθυνση αλληλογραφίας (συμπληρώνεται εαν διαφέρει)
                        <Checkbox checked={!!applicationForm.state.details?.mailingAddress} onChange={e => handleCustomerDetailsChange('mailingAddress')(valueToEvent(e.target.checked ? {} : undefined))} color="primary" />
                    </Typography>
                </Grid>
                {
                    !!applicationForm.state.details?.mailingAddress &&
                    <>
                        <Grid item xs={12}>
                            <RegionCascadeSelect communityId={applicationForm.state.details?.mailingAddress?.communityId} onCommunitySelectionChanged={e => handleMailingAddressChange('communityId')(valueToEvent(e))} errorState={applicationForm.errorStateCustom('communityId')} />
                        </Grid>
                        <FormTextField grid-item xs={12} md={10} autoCaps label={'Διεύθυνση'} value={applicationForm.state.details?.mailingAddress?.addressLine1 || ''} onChange={handleMailingAddressChange('addressLine1')} errorState={applicationForm.errorStateCustom('mailingAddressLine1')} />
                        <FormTextField grid-item xs={12} md={2} autoCaps label={'Τ.Κ.'} value={applicationForm.state.details?.mailingAddress?.postalCode || ''} onChange={handleMailingAddressChange('postalCode')} errorState={applicationForm.errorStateCustom('mailingPostalCode')} />
                        <FormTextField grid-item xs={12} md={12} autoCaps label={'Λεπτομέρειες'} helperText={"Π.χ. αριθμός πόρτας, διαμερίσματος, όροφος, σημειώσεις"} value={applicationForm.state.details?.mailingAddress?.addressLine2 || ''} onChange={handleMailingAddressChange('addressLine2')} />
                    </>
                }

                <Divide>ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</Divide>

                {/* <FormDatePicker xs={12} md={6} grid-item label="Ημερομηνία Γέννησης" disabled={applicationForm.state.details.legalTypeId === LegalTypeId.LegalPerson} value={applicationForm.state.details.dateOfBirth || null} onChange={e => handleCustomerDetailsChange('dateOfBirth')(valueToEvent(e))} errorState={applicationForm.errorStateNested<CustomerDetailsModel>('details', 'dateOfBirth')} /> */}
                <FormTextField xs={12} md={6} grid-item label="Email" value={contactInfo.state.email} onChange={e => contactInfo.handleChange('email')(e.target.value)} helperText="Email χωρισμένα με ',' π.χ. xx@email.com,yy@email.com" />
                <FormTextField xs={12} md={6} grid-item label="Σταθερό τηλέφωνο" value={contactInfo.state.landlineNumber} onChange={e => contactInfo.handleChange('landlineNumber')(e.target.value)} helperText="Αριθμοί τηλεφώνου χωρισμένοι με  ',' πχ: 210ΧΧΧΧΧΧΧ,211ΧΧΧΧΧΧΧ" />
                <FormTextField xs={12} md={6} grid-item label="Κινητό τηλέφωνο" value={contactInfo.state.mobileNumber} onChange={e => contactInfo.handleChange('mobileNumber')(e.target.value)} errorState={contactInfo.errorState('mobileNumber')} helperText="Αριθμοί τηλεφώνου χωρισμένοι με  ',' πχ: 699ΧΧΧΧΧΧΧ,697ΧΧΧΧΧΧΧ" />
                <FormTextField xs={12} md={6} grid-item label="Φαξ" value={contactInfo.state.faxNumber} onChange={e => contactInfo.handleChange('faxNumber')(e.target.value)} helperText="Αριθμοί Fax χωρισμένοι με ',' πχ: 210ΧΧΧΧΧΧΧ,211ΧΧΧΧΧΧΧ" />
                <FormTextField xs={12} md={12} grid-item label="Ιστότοπος" value={contactInfo.state.website} onChange={e => contactInfo.handleChange('website')(e.target.value)} helperText="Εγγραφές χωρισμένες με ',' π.χ. xx.com,yy.com" />

                {
                    applicationForm.state.details.legalTypeId === LegalTypeId.LegalPerson && <>
                        <Divide>ΣΤΟΙΧΕΙΑ ΝΟΜΙΜΟΥ ΕΚΠΡΟΣΩΠΟΥ (Υπεύθυνου Επιχείρησης ως προς το ΒΙΟΕΛΛΑΣ)</Divide>

                        <FormTextField xs={12} md={3} grid-item autoCaps label="Όνομα" value={applicationForm.state.details.legalRepresentative?.firstName || ''} onChange={handleLegalRepresentativeChange('firstName')} errorState={applicationForm.errorStateCustom('firstNameLegal')} />
                        <FormTextField xs={12} md={3} grid-item autoCaps label="Επώνυμο" value={applicationForm.state.details.legalRepresentative?.lastName || ''} onChange={handleLegalRepresentativeChange('lastName')} errorState={applicationForm.errorStateCustom('lastNameLegal')} />
                        <FormTextField xs={12} md={3} grid-item autoCaps label="Πατρώνυμο" value={applicationForm.state.details.legalRepresentative?.middleName || ''} onChange={handleLegalRepresentativeChange('middleName')} />
                        <FormTextField xs={12} md={3} grid-item autoCaps label="ΑΔΤ" value={applicationForm.state.details.legalRepresentative?.nationalIdentityNumber || ''} onChange={handleLegalRepresentativeChange('nationalIdentityNumber')} errorState={applicationForm.errorStateCustom('nationalIdentityNumberLegal')} />
                        {applicationForm.state.details.legalRepresentative &&
                            <>
                                <FormTextField xs={12} md={3} grid-item label="Σταθερό τηλέφωνο" value={legalRepresentativeContactInfo.state?.landlineNumber ?? ''} onChange={e => legalRepresentativeContactInfo.handleChange('landlineNumber')(e.target.value)} helperText="Αριθμοί τηλεφώνου χωρισμένοι με  ',' πχ: 210ΧΧΧΧΧΧΧ,211ΧΧΧΧΧΧΧ" />
                                <FormTextField xs={12} md={3} grid-item label="Κινητό τηλέφωνο" value={legalRepresentativeContactInfo.state?.mobileNumber ?? ''} onChange={e => legalRepresentativeContactInfo.handleChange('mobileNumber')(e.target.value)} helperText="Αριθμοί τηλεφώνου χωρισμένοι με  ',' πχ: 699ΧΧΧΧΧΧΧ,697ΧΧΧΧΧΧΧ" />
                                <FormTextField xs={12} md={3} grid-item label="Email" value={legalRepresentativeContactInfo.state?.email ?? ''} onChange={e => legalRepresentativeContactInfo.handleChange('email')(e.target.value)} helperText="Email χωρισμένα με  ',' π.χ. xx@email.com,yy@email.com" />
                                <FormTextField xs={12} md={3} grid-item label="Φαξ" value={legalRepresentativeContactInfo.state?.faxNumber ?? ''} onChange={e => legalRepresentativeContactInfo.handleChange('faxNumber')(e.target.value)} helperText="Αριθμοί Fax χωρισμένοι με  ',' πχ: 210ΧΧΧΧΧΧΧ,211ΧΧΧΧΧΧΧ" />
                            </>
                        }
                    </>
                }

                <Divide>ΔΡΑΣΤΗΡΙΟΤΗΤΑ</Divide>

                <Grid item xs={12}>
                    <Typography variant="overline" color="textSecondary">
                        <MaybeBold bold={isChecked(BasicActivityGroupId.Crop)}>ΦΥΤΙΚΗ ΠΑΡΑΓΩΓΗ</MaybeBold>
                        <Checkbox checked={isChecked(BasicActivityGroupId.Crop)} onChange={e => handleGroupsChange(BasicActivityGroupId.Crop)(e.target.checked)} color="primary" />
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                        <MaybeBold bold={isChecked(BasicActivityGroupId.Livestock)}>ΖΩΙΚΗ ΠΑΡΑΓΩΓΗ</MaybeBold>
                        <Checkbox checked={isChecked(BasicActivityGroupId.Livestock)} onChange={e => handleGroupsChange(BasicActivityGroupId.Livestock)(e.target.checked)} color="primary" />
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                        <MaybeBold bold={isChecked(BasicActivityGroupId.Processing)}>ΜΕΤΑΠΟΙΗΣΗ-ΤΥΠΟΠΟΙΗΣΗ-ΔΙΑΘΕΣΗ</MaybeBold>
                        <Checkbox checked={isChecked(BasicActivityGroupId.Processing)} onChange={e => handleGroupsChange(BasicActivityGroupId.Processing)(e.target.checked)} color="primary" />
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                        <MaybeBold bold={isChecked(BasicActivityGroupId.Aquaculture)}>ΙΧΘΥΟΚΑΛΛΙΕΡΓΕΙΑ</MaybeBold>
                        <Checkbox checked={isChecked(BasicActivityGroupId.Aquaculture)} onChange={e => handleGroupsChange(BasicActivityGroupId.Aquaculture)(e.target.checked)} color="primary" />
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                        <MaybeBold bold={isChecked(BasicActivityGroupId.Beekeeping)}>ΜΕΛΙΣΣΟΚΟΜΙΑ</MaybeBold>
                        <Checkbox checked={isChecked(BasicActivityGroupId.Beekeeping)} onChange={e => handleGroupsChange(BasicActivityGroupId.Beekeeping)(e.target.checked)} color="primary" />
                    </Typography>
                </Grid>

                <Divide>ΑΛΛΑ ΣΤΟΙΧΕΙΑ</Divide>

                <FormAutocompleteSingle<CertificationOrganizationViewModel> grid-item xs={12} md={6}
                    valueId={applicationForm.state.details.previousOrganizationId}
                    getData={getOrganizations}
                    label={"Προηγούμενος φορέας πιστοποίησης"}
                    idSelector={x => x.certificationOrganizationId}
                    displaySelector={x => x.name}
                    onChange={x => handleCustomerDetailsChange('previousOrganizationId')(valueToEvent(x?.certificationOrganizationId))}
                />
                <FormDatePicker xs={12} md={6} grid-item label='Ημερομηνία αποχώρησης' value={applicationForm.state.details.previousOrganizationDate || null} onChange={e => handleCustomerDetailsChange('previousOrganizationDate')(valueToEvent(e))} />

                <FormTextField xs={12} multiline rows={4} rowsMax={12} label={'Σημειώσεις'} value={applicationForm.state.note} onChange={applicationForm.handleChange('note')} />
            </Grid>
        </CardContent>
        <CardActions disableSpacing className={classes.actions}>
            <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item>
                    <FormButton variant='outlined' color='inherit' startIcon={<ArrowLeft />} onClick={() => history.push(routes.HOME.template)}>Επιστροφή</FormButton>
                </Grid>
                <Grid item>
                    <FormButton variant='outlined' color='inherit' startIcon={<Refresh />} onClick={refreshApplication}>Ανανέωση</FormButton>
                </Grid>
                <Grid item>
                    <FormButton variant='outlined' color='inherit' startIcon={<Save />} onClick={handleSubmit} disabled={contactInfo.locked || applicationForm.locked}>Αποθήκευση</FormButton>
                </Grid>
                {applicationForm.state.applicationId && <Grid item> <FormButton startIcon={<CloudDownload />} variant='outlined' color='inherit'
                    onClick={async () => {
                        if (!applicationForm.state.applicationId) return
                        const blob = await api.applications.generateApplication(applicationForm.state.applicationId)
                        if (blob) viewOrDownloadFile(blob, 'download', blob.filename ?? "file")
                    }}>Εκτύπωση</FormButton>
                </Grid>}
            </Grid>
        </CardActions>
    </Card>

}

