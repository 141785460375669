import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useCommonStyles } from '../styling/commonStyles'

export type ButtonDrawerEntry = { label: React.ReactNode, onClick?: () => void, hidden?: boolean, disabled?: boolean }

export const ButtonDrawer = (props: { size?: 'small' | 'medium', disabled?: boolean, entries: ButtonDrawerEntry[] }) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined)
    const commonClasses = useCommonStyles()
    const closePopover = () => setAnchorEl(undefined)

    return (
        <React.Fragment>
            <IconButton disabled={props.disabled} size={props.size ?? 'small'} className={clsx(commonClasses.datagridToolbarIconButton)} onClick={e => setAnchorEl(e.currentTarget)}><MoreVert /></IconButton>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} transformOrigin={{ vertical: 'top', horizontal: 'left', }} keepMounted onClose={_ => closePopover()} >
                {props.entries.filter(f => !f.hidden).map((e, i) => <MenuItem key={i} disabled={e.disabled} onClick={_ => { closePopover(); e.onClick?.() }}>{e.label}</MenuItem>)}
            </Menu>
        </React.Fragment>
    )
}