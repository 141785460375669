import { DocumentDirection } from "./WellKnowns"
import { ApiClient, BlobResponse } from "./wtf/api/ApiClient"

export type Document =
    {
        documentId: number
        originalFilename: string
        filename: string
        documentTypeName: string
        canBeDisplayed: boolean
        isExternalUserRead?: boolean
        uploadDate: Date
    }

export type DocumentType =
    {
        documentTypeId: number
        name: string
    }

export type DocumentsQuery =
    {
        customerId?: number
        applicationId?: number
    }

export type DocumentDetails =
    {
        documentDirection?: DocumentDirection
        documentTypeId: number
    }

export type DocumentDetailsResult =
    {
        documentId: number
    }

export class DocumentsApi {
    static FILE_TIMEOUT_MS = 5 * 60 * 1000

    constructor(private readonly api: ApiClient) { }

    public getDocumentsInbox = (query: DocumentsQuery) =>
        this.api.onUnexpected(
            this.api.execute<Document[]>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/current/inbox`, query),
            "Δεν είναι δυνατη η εμφάνιση των αρχείων", [])

    public getDocumentsOutbox = (query: DocumentsQuery) =>
        this.api.onUnexpected(
            this.api.execute<Document[]>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/current/outbox`, query),
            "Δεν είναι δυνατη η εμφάνιση των αρχείων", [])

    public getDocumentInbox = async (documentId: number, query: DocumentsQuery) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/current/inbox/${documentId}`, query, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public getDocumentOutbox = async (documentId: number, query: DocumentsQuery) =>
        this.api.onUnexpected(this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/current/outbox/${documentId}`, query, undefined, DocumentsApi.FILE_TIMEOUT_MS, undefined, undefined, true), "Could not load document", null)

    public getDocumentTypes = () =>
        this.api.onUnexpected(
            this.api.execute<DocumentType[]>(200, 'GET', `api/tenants/${this.api.tenantId}/external-users/requirements/documents/types`),
            "Could not load document types", [] as DocumentType[])

    public uploadDocument = (fileInfo: File, type: number, customerId?: number, applicationId?: number) => {
        const data = new FormData() // use FromData to send data while uploading the document
        data.append('file', fileInfo)
        data.append('documentTypeId', type.toString())

        if (customerId) data.append('customerId', customerId.toString())
        if (applicationId) data.append('applicationId', applicationId.toString())

        return this.api.onUnexpected(
            this.api.execute<{ documentId: number }>(200, 'POST', `api/tenants/${this.api.tenantId}/external-users/current/outbox/upload`, undefined, data, DocumentsApi.FILE_TIMEOUT_MS),
            "Δεν είναι δυνατό το ανέβασμα του αρχείου", null)
    }
}