import { useCallback } from 'react'
import { SupportedLanguage } from '../api/WellKnowIds'
import { caseNever } from '../utils/never'
import translations from './client.internationalization.json'
import { useInternationalizationContext } from './InternationalizationContext'

const getLanguage = (l: SupportedLanguage, tr: { el: string, en: string, bg?: string }) => {
    switch (l) {
        case SupportedLanguage.Greek: return tr.el
        case SupportedLanguage.English: return tr.en
        case SupportedLanguage.Bulgarian: return tr.bg
        default: return caseNever(l)
    }
}

export type Internationalization = (key: keyof typeof translations) => string

export const useInternationalization = () => {
    const { contentLanguage: language } = useInternationalizationContext()

    const intl: Internationalization = useCallback((tk: keyof typeof translations) =>
        getLanguage(language, translations[tk])
        ?? getLanguage(SupportedLanguage.Greek, translations[tk])!
        , [language])

    return {
        intl
    }
}