import { AppBar, Button, Container, IconButton, Menu, MenuItem, Toolbar } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import { AccountCircle, Email, Facebook, Language, LinkedIn, Phone } from "@material-ui/icons"
import React, { useState } from "react"
import { BrowserRouter, Link, Route, Switch } from "react-router-dom"
import { useAuthenticationContext } from "../../wtf/authentication/Authentication"
import { Footer } from "../Shared/Footer"
import routes from "../Shared/routes"
import { Application } from "./Application"
import { DocumentsDownload } from "./DocumentsDownload"
import { DocumentsUpload } from "./DocumentsUpload"
import { HomePage } from "./HomePage"

export const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 'calc(100vh - 48px)',
        backgroundColor: theme.palette.secondary.main,
    },
    homeRoot: {
        height: 'calc(100vh - 48px)',
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        height: 48,
        padding: theme.spacing(0.5)
    },
    actionsContainer: {
        marginTop: 48,
        minHeight: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    actionsGrid: {
        minWidth: '100%',
        minHeight: '100%',
        justifyItems: 'center',
    },
    cardHover: {
        cursor: 'pointer',
        minHeight: 128,
    },
    cardHoverDisabled: {
        cursor: 'not-allowed',
        minHeight: 128,
    },
}))


export const App = () => {
    const classes = useStyles()
    const { logout } = useAuthenticationContext()
    const [menuRef, setMenuRef] = useState<HTMLButtonElement | null>(null)

    return <div className={classes.root}>
        <BrowserRouter>
            <AppBar color='default' position='fixed'>
                <Toolbar variant='dense'>
                    <Link to={routes.HOME.template}>
                        <img className={classes.logo} src="/assets/bio-hellas-logo.png" />
                    </Link>
                    <div className={classes.grow} />
                    <IconButton color='primary' target="_blank" href="http://bio-hellas.gr">
                        <Language />
                    </IconButton>
                    <IconButton color='primary' target="_blank" href="https://www.facebook.com/BioHellasOfficial">
                        <Facebook />
                    </IconButton>
                    <IconButton color='primary' target="_blank" href="https://www.linkedin.com/company/biohellas">
                        <LinkedIn />
                    </IconButton>
                    <IconButton color='primary' href="mailto:info@bio-hellas.gr">
                        <Email />
                    </IconButton>
                    <IconButton color='primary' href="tel:+302108211940">
                        <Phone />
                    </IconButton>
                    <Button startIcon={<AccountCircle />} color='primary' onClick={e => setMenuRef(e.currentTarget)}>
                        Ο ΛΟΓΑΡΙΑΣΜΟΣ ΜΟΥ
                    </Button>
                    <Menu anchorEl={menuRef} open={!!menuRef} onClose={() => setMenuRef(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }}>
                        <MenuItem onClick={() => logout()}>Αποσύνδεση</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Container maxWidth='lg' className={classes.actionsContainer}>
                <Switch>
                    <Route exact path={routes.HOME.template}>
                        <HomePage />
                    </Route>
                    <Route exact path={routes.APPLICATION.template}>
                        <Application />
                    </Route>
                    <Route exact path={routes.INBOX.template}>
                        <DocumentsDownload />
                    </Route>
                    <Route exact path={routes.UPLOAD_DOCS.template}>
                        <DocumentsUpload />
                    </Route>
                </Switch>
            </Container>
        </BrowserRouter>
        <Footer />
    </div>
}