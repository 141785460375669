import { Grid, MenuItem, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Community, Municipality, MunicipalityUnit, Region, RegionUnit } from './Region';
import { useApiContext } from './wtf/api/ApiContext';
import { useValidator, validator } from './wtf/components/forms/Form';
import { FormSelect, ValidationProps } from './wtf/components/forms/FormComponents';
import { useInternationalization } from './wtf/internationalization/Internationalization';
import { maybeParseInt } from './wtf/utils/utils';

type Props =
    {
        communityId?: number
        disabled?: boolean
        readOnly?: boolean
        onCommunitySelectionChanged: (id: number | undefined) => void
    } & ValidationProps

type State =
    {
        regions: Region[]
        regionUnits: RegionUnit[]
        municipalities: Municipality[]
        municipalityUnits: MunicipalityUnit[]
        communities: Community[]
        regionId?: number
        regionUnitId?: number
        municipalityId?: number
        municipalityUnitId?: number
        communityId?: number
    }

const EMPTY_STATE: State = { regions: [], regionUnits: [], municipalities: [], municipalityUnits: [], communities: [] }

export const RegionCascadeSelect = (props: Props) => {
    const api = useApiContext()
    const { intl } = useInternationalization()

    const validation = useValidator<State, { communityId: any, regionUnitId: any, municipalityId: any, municipalityUnitId: any }>({
        customValidation: {
            communityId: validator<State>('Κοινότητα').push('είναι υποχρεωτικό', st => !st?.regionId === !st.communityId).build(),
            regionUnitId: validator<State>('Περιφερειακή ενότητα').push('είναι υποχρεωτικό', st => !st?.regionId === !st.regionUnitId).build(),
            municipalityId: validator<State>('Δήμος').push('είναι υποχρεωτικό', st => !st?.regionId === !st.municipalityId).build(),
            municipalityUnitId: validator<State>('Δημοτική ενότητα').push('είναι υποχρεωτικό', st => !st?.regionId === !st.municipalityUnitId).build(),
        }
    })

    const [state, setState] = useState<State>(EMPTY_STATE)

    // Initial populate
    useEffect(() => {
        api.regions.getRegions().then(r => setState({ ...EMPTY_STATE, regions: r }))
    }, [])

    useEffect(() => {
        if (props.communityId === state.communityId) return

        if (!props.communityId) {
            setState(st => ({ ...EMPTY_STATE, regions: st.regions }))
            return
        }

        api.regions.getCommunityReverseLookup(props.communityId).then(rl => {
            if (!rl) return
            Promise.all([
                rl.regionId ? api.regions.getRegionUnits(rl.regionId) : Promise.resolve([]),
                rl.regionId && rl.regionUnitId ? api.regions.getMunicipalities(rl.regionUnitId) : Promise.resolve([]),
                rl.regionId && rl.regionUnitId && rl.municipalityId ? api.regions.getMunicipalityUnits(rl.municipalityId) : Promise.resolve([]),
                rl.regionId && rl.regionUnitId && rl.municipalityId && rl.municipalityUnitId ? api.regions.getCommunities(rl.municipalityUnitId) : Promise.resolve([]),
            ]).then(([ru, m, mu, c]) => setState(st => ({
                ...st,
                regionId: rl.regionId,
                regionUnits: ru,
                regionUnitId: rl.regionUnitId,
                municipalities: m,
                municipalityId: rl.municipalityId,
                municipalityUnits: mu,
                municipalityUnitId: rl.municipalityUnitId,
                communities: c,
                communityId: rl.communityId
            })))
        })

    }, [props.communityId])

    return (
        <Grid container spacing={1}>
            <FormSelect grid-item md={6} disabled={props.disabled} readOnly={props.readOnly} label={'Περιφέρεια'} value={state.regionId || ''}
                onChange={async (e) => {
                    const id = Number.parseInt(e.target.value)
                    props.onCommunitySelectionChanged(undefined)
                    setState({
                        ...state,
                        regionId: id,
                        regionUnits: await api.regions.getRegionUnits(id),
                        regionUnitId: undefined,
                        municipalities: [],
                        municipalityId: undefined,
                        municipalityUnits: [],
                        municipalityUnitId: undefined,
                        communities: [],
                        communityId: undefined,
                    })
                }}>
                {state.regions.map(x => <MenuItem value={x.regionId} key={`menu-item-${x.regionId}`}>{x.name}</MenuItem>)}
            </FormSelect>
            <FormSelect grid-item md={6} disabled={props.disabled} readOnly={props.readOnly} label={'Περιφερειακή ενότητα'} value={state.regionUnitId || ''} errorState={validation.errorStateCustom('regionUnitId', state)}
                onChange={async (e) => {
                    const id = Number.parseInt(e.target.value)
                    props.onCommunitySelectionChanged(undefined)
                    setState({
                        ...state,
                        regionUnitId: id,
                        municipalities: await api.regions.getMunicipalities(id),
                        municipalityId: undefined,
                        municipalityUnits: [],
                        municipalityUnitId: undefined,
                        communities: [],
                        communityId: undefined,
                    })
                }} >
                {state.regionUnits.map(x => <MenuItem value={x.regionUnitId} key={`menu-item-${x.regionUnitId}`}>{x.name}</MenuItem>)}
            </FormSelect>
            <FormSelect grid-item md={6} disabled={props.disabled} readOnly={props.readOnly} label={'Δήμος'} value={state.municipalityId || ''} errorState={validation.errorStateCustom('municipalityId', state)}
                onChange={async (e) => {
                    const id = Number.parseInt(e.target.value)
                    props.onCommunitySelectionChanged(undefined)
                    setState({
                        ...state,
                        municipalityId: id,
                        municipalityUnits: await api.regions.getMunicipalityUnits(id),
                        municipalityUnitId: undefined,
                        communities: [],
                        communityId: undefined,
                    })
                }} >
                {state.municipalities.map(x => <MenuItem value={x.municipalityId} key={`menu-item-${x.municipalityId}`}>{x.name}</MenuItem>)}
            </FormSelect>
            <FormSelect grid-item md={6} disabled={props.disabled} readOnly={props.readOnly} label={'Δημοτική ενότητα'} value={state.municipalityUnitId || ''} errorState={validation.errorStateCustom('municipalityUnitId', state)}
                onChange={async (e) => {
                    const id = Number.parseInt(e.target.value)
                    props.onCommunitySelectionChanged(undefined)
                    setState({
                        ...state,
                        municipalityUnitId: id,
                        communities: await api.regions.getCommunities(id),
                        communityId: undefined,
                    })
                }} >
                {state.municipalityUnits.map(x => <MenuItem value={x.municipalityUnitId} key={`menu-item-${x.municipalityUnitId}`}>{x.name}</MenuItem>)}
            </FormSelect>
            <FormSelect grid-item md={6} error={!!props.errorState || !!validation.errorStateCustom('communityId', state)} disabled={props.disabled} helperText={props.errorState || validation.errorStateCustom('communityId', state) ? 'Το πεδίο κοινότητα είναι υποχρεωτικό' : null} readOnly={props.readOnly} label={'Κοινότητα'} value={state.communityId || ''}
                onChange={(e) => {
                    const id = maybeParseInt(e.target.value)
                    props.onCommunitySelectionChanged(id)
                    setState({ ...state, communityId: id })
                }}>
                {state.communities.map(x => <MenuItem value={x.communityId} key={`menu-item-${x.communityId}`}>
                    <Tooltip title={x.communityId}>
                        <span>{x.name} ({x.type})</span>
                    </Tooltip>
                </MenuItem>)}
            </FormSelect>
        </Grid >
    )

}