import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Error } from '@material-ui/icons';
import React, { useEffect, useState } from "react";
import { Environment, ENVIRONMENT } from "../../ConfigurationInjection";
import { SupportedLanguage, TenantId } from "../api/WellKnowIds";
import { openApplicationIdb } from "../local-store/IndexedDb";
import { useUserInfoContext } from "../user-info/UserInfoContext";
import { caseNever } from "../utils/never";
import { InternationalizationContext, InternationalizationContextType } from "./InternationalizationContext";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100vw',
        height: '100vh',
        justifyItems: 'center',
        background: theme.palette.primary.main,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        color: 'white',
        justifyContent: 'center',
    },
}))

export const InternationalizationContainer: React.FC = props => {
    const { userInfo } = useUserInfoContext()
    const classes = useStyles()
    const [state, setState] = useState<{ language?: SupportedLanguage; tenant?: TenantId; }>({})

    if (ENVIRONMENT === Environment.Production) document.documentElement.lang = 'el'

    useEffect(() => {
        if (ENVIRONMENT === Environment.Production) return

        openApplicationIdb()
            .then(async (db) => {
                const settings = await db.get('settings', 'internationalization')
                setState(st => st.tenant !== settings?.tenant || st.language !== settings?.language ? {
                    ...st,
                    tenant: settings?.tenant ?? userInfo.preferredTenantId,
                    language: settings?.language ?? userInfo.preferredLanguage,
                } : st)
            })
    }, [userInfo.preferredLanguage, userInfo.preferredTenantId])

    useEffect(() => {
        if (ENVIRONMENT === Environment.Production) return

        const lang = state.language;
        if (!lang) return
        switch (lang) {
            case SupportedLanguage.Greek: document.documentElement.lang = 'el'; break
            case SupportedLanguage.English: document.documentElement.lang = 'en'; break
            case SupportedLanguage.Bulgarian: document.documentElement.lang = 'bg'; break
            default: return caseNever(lang);
        }
        const settings = { tenant: state.tenant, language: state.language }
        openApplicationIdb().then(db => db.put('settings', settings, 'internationalization'));
    }, [state?.language]);

    const setLanguage = (l: SupportedLanguage) => setState(st => ({ ...st, language: l }));
    const setTenant = (t: TenantId) => setState(st => ({ ...st, tenant: t }));

    const provider: InternationalizationContextType | undefined =
        ENVIRONMENT === Environment.Production ? {
            language: SupportedLanguage.Greek,
            tenant: TenantId.Greece,
            setLanguage: setLanguage,
            setTenant: setTenant,
        } :
            userInfo.preferredTenantId ?
                {
                    language: state.language ?? userInfo.preferredLanguage,
                    tenant: state.tenant ?? userInfo.preferredTenantId,
                    setLanguage: setLanguage,
                    setTenant: setTenant,
                } : undefined

    // NB forcing a full reload by using tenant+language as a key
    return provider
        ? <InternationalizationContext.Provider key={`${provider.tenant}-${provider.language}`} value={provider}>
            {props.children}
        </InternationalizationContext.Provider> :
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.container}>
            <div className={classes.wrapper}>
                <Typography variant='h6'><Error color='error' /> Ο διαχειριστής του συστήματος δεν σας έχει δώσει πρόσβαση σε καμία από τις υποστηριζόμενες Περιοχές. Επικοινωνήστε μαζί του άμεσα.</Typography>
            </div>
        </Grid>
}
