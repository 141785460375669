import { Button, CircularProgress, Grid, Link, Typography } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import { ExitToApp } from "@material-ui/icons"
import React, { useEffect, useState } from "react"
import { useApiContext } from "../../wtf/api/ApiContext"
import { useAuthenticationContext } from "../../wtf/authentication/Authentication"
import { Footer } from "../Shared/Footer"
import { App } from "./App"

const useStyles = makeStyles(theme => ({
    background: {
        width: '100vw',
        minHeight: '100vh',
        justifyItems: 'center',
    },
    logoutButton: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: 9,
        right: 9,
    }
}))

export const AppRoot = () => {
    const api = useApiContext()
    const classes = useStyles()
    const auth = useAuthenticationContext()

    const [registrationComplete, setRegistrationComplete] = useState<boolean>()
    const [slowRegistration, setSlowRegistration] = useState(false)

    useEffect(() => {
        api.externalUsers.register().then(r => setRegistrationComplete(r.isRegistrationComplete))
    }, [])

    useEffect(() => {
        const handle = setTimeout(() => setSlowRegistration(true), 1000)
        return () => clearTimeout(handle)
    }, [])

    const LogoutButton = () =>
        <Button size='large' variant='text' className={classes.logoutButton} onClick={() => auth.logout()} endIcon={<ExitToApp />}>ΕΞΟΔΟΣ</Button>

    if (registrationComplete === undefined)
        return <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.background}>
            <LogoutButton />
            <Footer />
            <Grid item xs={12}>
                <CircularProgress size={128} thickness={2} />
            </Grid>
            {slowRegistration && <Grid item xs={12}>
                <Typography>
                    Γίνεται έλεγχος των στοιχείων σας, παρακαλώ περιμένετε.
                </Typography>
            </Grid>}
        </Grid>

    if (registrationComplete === false)
        return <Grid container spacing={0} direction="column" alignItems="center" justify="center" className={classes.background}>
            <LogoutButton />
            <Footer />
            <Grid item xs={12}>
                <Typography>
                    Η εγγραφή σας θα χρειαστεί επιβεβαίωση των στοιχείων σας από το BIO HELLAS. Παρακαλείστε να καλέσετε στο <Link href="tel:+302108211940">2108211940</Link> ή να αποστείλετε email στο <Link href="mailto:info@bio-hellas.gr">info@bio-hellas.gr</Link>
                </Typography>
            </Grid>
        </Grid>

    return <App />
}