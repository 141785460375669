import { PublicClientApplication } from '@azure/msal-browser';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import el from 'date-fns/locale/el';
import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppRoot } from './components/App/AppRoot';
import { Welcome } from './components/Registration/Welcome';
import routes from './components/Shared/routes';
import { B2C_APIAPP_ID, B2C_AUTHORITY, B2C_WEBAPP_ID, Environment, ENVIRONMENT } from './ConfigurationInjection';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ApiClient } from './wtf/api/ApiClient';
import { SupportedLanguage, TenantId } from './wtf/api/WellKnowIds';
import { AuthenticationContextProvider, getLoggerOptions, useAuthenticationContext } from './wtf/authentication/Authentication';
import { FullscreenContextProvider } from './wtf/fullscreen/FullscreenContext';
import { InternationalizationContainer } from './wtf/internationalization/InternationalizationContainer';
import { useInternationalizationContext } from './wtf/internationalization/InternationalizationContext';
import { SnackbarProvider } from './wtf/notifications/SnackbarContext';
import { ApiServicesContext, ApiServicesProvider } from './wtf/progress-reporting/ProgressReporting';
import { UserInfoContextProvider } from './wtf/user-info/UserInfoContext';
import { isIE, maybeDisableReactDevTools } from './wtf/utils/utils';

const theme = createMuiTheme({
    palette: {
        primary: { main: ENVIRONMENT === Environment.Dev ? 'rgb(35,81,51)' : ENVIRONMENT === Environment.Qa ? '#004d40' : 'rgb(35,81,51)' },
        secondary: { main: 'rgb(235, 239, 234)' }
    },
    overrides: {
        MuiLink: {
            root: {
                fontWeight: 'bold'
            }
        }
    }
});

maybeDisableReactDevTools()

document.documentElement.lang = 'el'

const msal = new PublicClientApplication({
    auth: {
        clientId: B2C_WEBAPP_ID,
        authority: `https://${B2C_AUTHORITY}.b2clogin.com/${B2C_AUTHORITY}.onmicrosoft.com/B2C_1_susi`,
        knownAuthorities: [`${B2C_AUTHORITY}.b2clogin.com`],
        redirectUri: routes.HOME.template,
        postLogoutRedirectUri: routes.ROOT.template,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE(),
    },
    system: {
        loggerOptions: getLoggerOptions(ENVIRONMENT)
    }
})

const scopes = [
    'openid',
    `https://${B2C_AUTHORITY}.onmicrosoft.com/${B2C_APIAPP_ID}/access_as_user`
]

const AppBase = () => {
    const auth = useAuthenticationContext()
    const { tenantId, contentLanguage } = useInternationalizationContext()

    const apiClientFactory = useCallback(() => {
        return new ApiClient({ tenantId, contentLanguage, getAccessToken: auth.getAccessToken })
    }, [tenantId, contentLanguage])

    return <ApiServicesContext.Provider value={apiClientFactory}>
        <ApiServicesProvider>
            <CssBaseline />
            <BrowserRouter>
                <Switch>
                    <Route path={routes.HOME.template}>
                        {auth.accountInfo ? <AppRoot /> : null}
                    </Route>
                    <Route exact path={routes.ROOT.template}>
                        <Welcome />
                    </Route>
                    <Route exact path={routes.LOGOUT.template}>
                        <Logout />
                    </Route>
                </Switch>
            </BrowserRouter>
        </ApiServicesProvider>
    </ApiServicesContext.Provider>
}


const Logout = () => {
    const auth = useAuthenticationContext()
    useEffect(() => {
        auth.logout()
    }, [])
    return null
}

const ConfigureApp = () => {
    return <AuthenticationContextProvider config={{ msal, loginScopes: scopes, tokenScopes: scopes }}>
        <UserInfoContextProvider value={{ preferredLanguage: SupportedLanguage.Greek, permissions: [], preferredTenantId: TenantId.Greece }}>
            <InternationalizationContainer>
                <SnackbarProvider>
                    <FullscreenContextProvider>
                        <AppBase />
                    </FullscreenContextProvider>
                </SnackbarProvider>
            </InternationalizationContainer>
        </UserInfoContextProvider>
    </AuthenticationContextProvider>
}

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={el}>
                <ConfigureApp />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'));

serviceWorker.unregister();