import { createContext, useCallback, useContext } from "react"
import { SupportedLanguage, TenantId } from "../api/WellKnowIds"
import PermissionType from "./PermissionType"

export type UserInformation = {
    preferredTenantId?: TenantId
    preferredLanguage: SupportedLanguage
    permissions: string[]
}

const UserInfoContext = createContext<UserInformation>(null as any)

export const UserInfoContextProvider = UserInfoContext.Provider

export const useUserInfoContext = () => {
    const userInfo = useContext(UserInfoContext)
    const hasPermission = useCallback((p: PermissionType) => userInfo?.permissions?.includes(p as unknown as string), [userInfo])
    return {
        userInfo,
        hasPermission,
    }
}