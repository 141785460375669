import { Checkbox, ClickAwayListener, Popover, TableCell, TableCellProps, TableRow, Theme, Typography } from "@material-ui/core";
import { createStyles, fade, makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from 'react';
import { useCommonStyles } from "../styling/commonStyles";

const useStyles = makeStyles((theme: Theme) => ({
    cellPopover: {
        cursor: 'grab',
        //display: 'inline-block',
    },
    sequenceNumber: {
        fontWeight: 'bolder',
        color: fade(theme.palette.primary.dark, 0.6),
        //borderRightWidth: 1,
        //borderRightColor: theme.palette.grey[200],
        //borderRightStyle: 'solid',
    },
    contextMenu: {
        // borderTop: '1px solid',
        // borderBottom: '1px solid',
        backgroundColor: fade(theme.palette.secondary.main, 0.4)
    }
}))

export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            fontWeight: 'bolder',
            backgroundColor: theme.palette.background.paper,
        },
        body: {
            //fontWeight: 'inherit',
        },
    }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:hover': {
                background: fade(theme.palette.primary.main, 0.2),
                //fontWeight: 500,
                cursor: 'default',
            },
        },
    }),
)(TableRow);

export const NoWrapCell: React.FC<TableCellProps> = props => {
    const { children, ...other } = props
    return <TableCell {...other}><Typography noWrap>{children}</Typography></TableCell>
}

export type ColumnDescriptor<T> =
    {
        id: React.Key
        columnName: React.ReactNode
        renderCell: (value: T) => React.ReactNode
    }

type Props<T> =
    {
        columns: ColumnDescriptor<T>[],
        popover?: (x: T) => React.ReactNode
        rowData: T
        onSelectionChanged?: (checked: boolean) => void
        onRowDoubleClick?: () => void
        selected?: boolean
        sequenceNumber: number
    }

export function DataGridRow<T>(props: Props<T>) {
    const commonClasses = useCommonStyles()

    const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 })
    const [showPopover, setShowPopover] = useState(false)
    const [checked, setChecked] = useState(false)

    const classes = useStyles()

    useEffect(() => { setChecked(!!props.selected) }, [props.selected])

    useEffect(() => {
        if (checked !== props.selected)
            props.onSelectionChanged?.(checked)
    }, [checked])

    const onRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.persist()
        e.preventDefault()
        setAnchorPosition({ top: e.pageY - 5, left: e.pageX + 5 })
        setShowPopover(true)
    }

    const closePopover = () => {
        setShowPopover(false)
    }

    return (
        <StyledTableRow key={props.sequenceNumber}
            className={showPopover ? classes.contextMenu : undefined}
            onClick={props.onSelectionChanged ? () => setChecked(!props.selected) : undefined}
            onDoubleClick={props.onRowDoubleClick}>

            <StyledTableCell key={2} align='right' padding='checkbox' className={clsx(props.popover && classes.cellPopover, props.selected && commonClasses.selectedTableRow, classes.sequenceNumber)}
                onContextMenu={e => props.popover && onRowClick(e)}>
                {props.sequenceNumber}
            </StyledTableCell>

            {props.onSelectionChanged &&
                <StyledTableCell key={1} className={clsx(commonClasses.dataGridCheckbox, props.selected && commonClasses.selectedTableRow)}>
                    <Checkbox style={{ margin: 0, padding: 0 }} onChange={e => setChecked(e.target.checked)} checked={props.selected} />
                </StyledTableCell>}

            {props.columns.map((c, i) =>
                <NoWrapCell key={"cell-" + c.columnName + "-" + i} className={clsx(props.popover && classes.cellPopover, props.selected && commonClasses.selectedTableRow)}
                    onContextMenu={e => props.popover && onRowClick(e)}>
                    {c.renderCell(props.rowData)}
                </NoWrapCell>)
            }

            {
                props.popover &&
                <Popover open={showPopover}
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorReference="anchorPosition"
                    anchorPosition={anchorPosition}
                    elevation={16}
                    onClose={closePopover} onClick={closePopover}>
                    <ClickAwayListener onClickAway={closePopover}>
                        <div> {/* Needs to be here to be able to hold a ref for ClickAwayListener */}
                            {props.popover?.(props.rowData)}
                        </div>
                    </ClickAwayListener>
                </Popover>
            }
        </StyledTableRow>
    )
}