const routePrefix = 'web'

export const routes =
{
    "ROOT":
    {
        template: `/`
    },
    "LOGOUT":
    {
        template: `/logout`
    },
    "HOME":
    {
        template: `/${routePrefix}`
    },
    "APPLICATION":
    {
        template: `/${routePrefix}/application`
    },
    "INBOX":
    {
        template: `/${routePrefix}/inbox`
    },
    "UPLOAD_DOCS":
    {
        template: `/${routePrefix}/upload-docs`
    },
}

export default routes;