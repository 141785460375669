import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, SnackbarContent, SnackbarOrigin, Theme, Typography } from "@material-ui/core";
import { amber, green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { CheckCircle, Close, Error as ErrorIcon, Info, Warning } from "@material-ui/icons";
import clsx from "clsx";
import React, { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { SuccessHandler } from "../api/ApiClient";
import { onUnexpected } from "../utils/apiNotificationExtensions";

type CustomActions = {
    label: string
    closeOnClick?: boolean
    onClick?: () => void
}

export type NotificationOptions =
    {
        autoHide?: boolean
        autoHideDuration?: number
        preventAccidentalClose?: boolean
    }

export type SnackbarContextType =
    {
        successNotification: (message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => void,
        warningNotification: (message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => void,
        errorNotification: (message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => void,
        infoNotification: (message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => void,
        quickInfoNotification: (message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => void,
        actionNotification: (message: string, actions: CustomActions[], details?: ReactNode, options?: NotificationOptions) => void
    }

export const SnackBarContext = createContext<SnackbarContextType>(undefined as any)

export const useNotificationsContext = () => useContext(SnackBarContext)

export const useApiNotifications = () => {
    const notifications = useNotificationsContext()
    const wrapError = onUnexpected(notifications)
    const onSuccess: SuccessHandler = (method) => {
        switch (method) {
            case 'PUT':
            case 'POST':
            case 'DELETE':
                notifications.quickInfoNotification('Η ενέργεια εκτελέστηκε με επιτυχία', undefined, undefined, { autoHideDuration: 1500 })
                break;
            default:
                break;
        }
    }
    return { wrapError, onSuccess }
}

type NotificationType = 'success' | 'warning' | 'error' | 'info' | 'quick-info' | 'action-notification'

type Notification =
    {
        type: NotificationType
        message: string
        details?: ReactNode
        onClose?: () => void
        actions?: CustomActions[]
        options?: NotificationOptions
    }

const variantIcon: { [P in NotificationType]: ((props: SvgIconProps) => JSX.Element) } = {
    success: CheckCircle,
    warning: Warning,
    error: ErrorIcon,
    info: Info,
    'quick-info': Info,
    'action-notification': Info,
};

const useStyles = makeStyles((theme: Theme) => ({
    success: {
        backgroundColor: green[900],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    'quick-info': {},
    'action-notification': {},
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export interface NotificationProps {
    className?: string
    actions?: CustomActions[]
    onClose?: () => void
    message?: string
    details?: ReactNode
    variant: keyof typeof variantIcon
}

const Notification = (props: NotificationProps) => {
    const classes = useStyles()
    const { className, message, onClose, variant, details, actions, ...other } = props
    const Icon = variantIcon[variant]

    const [showDetails, setShowDetails] = useState(false)

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            message={
                <span className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message && <Typography>{message}</Typography>}
                    {showDetails &&
                        <Dialog open={showDetails} maxWidth='xl'>
                            <DialogTitle>{message}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {details}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={_ => { setShowDetails(false); onClose?.() }} color="primary">ΟΚ</Button>
                            </DialogActions>
                        </Dialog>
                    }
                </span>
            }
            action={
                (actions?.map(x => <Button key={x.label} color="inherit" onClick={_ => { if (x.closeOnClick) onClose?.(); x.onClick?.() }}>{x.label}</Button>) || [] as React.ReactNode[])
                    .concat(
                        [
                            (details && <Button key="detailed" color="inherit" onClick={_ => setShowDetails(true)}>ΛΕΠΤΟΜΕΡΕΙΕΣ</Button>),
                            <IconButton key="close" color="inherit" onClick={onClose}><Close className={classes.icon} /></IconButton>,
                        ])}
            {...other}
        />
    );
}

export const SnackbarProvider: React.FC = (props) => {
    const [notificationState, setNotificationState] = useState<{ currentNotification?: Notification, notificationQueue: Notification[] }>({ currentNotification: undefined, notificationQueue: [] })

    const addNotification = (type: NotificationType, message: string, details?: ReactNode, onClose?: () => void, actions?: CustomActions[], options?: NotificationOptions) => {
        let newNotification = { type: type, message: message, details: details, onClose: onClose, actions: actions, options: options }
        setNotificationState(({ currentNotification, notificationQueue }) => {
            if (!currentNotification) return { currentNotification: newNotification, notificationQueue: notificationQueue }
            else return { currentNotification: currentNotification, notificationQueue: [...notificationQueue, newNotification] }
        })
    }

    const handleNotificationClose = (onClose?: () => void) => () => {
        if (onClose) onClose()

        setNotificationState(({ notificationQueue: [next, ...rest] }) => {
            return { currentNotification: next, notificationQueue: rest }
        })
    }

    const successNotification = useCallback((message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => addNotification('success', message, details, onClose, undefined, options), [])
    const warningNotification = useCallback((message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => addNotification('warning', message, details, onClose, undefined, options), [])
    const errorNotification = useCallback((message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => addNotification('error', message, details, onClose, undefined, options), [])
    const infoNotification = useCallback((message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => addNotification('info', message, details, onClose, undefined, options), [])
    const actionNotification = useCallback((message: string, actions: CustomActions[], details?: ReactNode, options?: NotificationOptions) => addNotification('action-notification', message, details, undefined, actions, options), [])
    const quickInfoNotification = useCallback((message: string, details?: ReactNode, onClose?: () => void, options?: NotificationOptions) => addNotification('quick-info', message, details, onClose, undefined, options), [])

    const value = {
        successNotification: successNotification,
        warningNotification: warningNotification,
        errorNotification: errorNotification,
        infoNotification: infoNotification,
        quickInfoNotification: quickInfoNotification,
        actionNotification: actionNotification,
    }

    const currentNotification = notificationState.currentNotification
    const autoHideDuration = currentNotification?.type === 'quick-info' || currentNotification?.options?.autoHide ? currentNotification?.options?.autoHideDuration ?? 3000 : undefined
    const anchorOrigin: SnackbarOrigin = currentNotification?.type === 'quick-info' || currentNotification?.type === 'action-notification' ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'bottom', horizontal: 'right', }
    const preventAccidentalClose = !!currentNotification?.options?.preventAccidentalClose

    return <SnackBarContext.Provider value={value}>
        {props.children}
        {
            currentNotification &&
            <Snackbar open={true} autoHideDuration={autoHideDuration} anchorOrigin={anchorOrigin} onClose={preventAccidentalClose ? undefined : handleNotificationClose(currentNotification.onClose)}>
                <Notification variant={currentNotification.type} message={currentNotification.message} details={currentNotification.details} onClose={handleNotificationClose(currentNotification.onClose)} actions={currentNotification.actions} />
            </Snackbar>
        }
    </SnackBarContext.Provider>
}