import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';
import _ from 'lodash';
import * as uuid from 'uuid';
import { ApplicationsApi } from '../../Application';
//import { getToken } from '../../components/Registration/Auth';
import { API_URL } from '../../ConfigurationInjection';
import { DocumentsApi } from '../../Document';
import { RegionsApi } from '../../Region';
import { toContentLanguage } from '../internationalization/InternationalizationContext';
import { ReportProgressF } from '../progress-reporting/ProgressReporting';
import { ErrorHandler, onUnexpectedIgnore } from '../utils/apiNotificationExtensions';
import { caseNever } from '../utils/never';
import { isValidDate, toISOStringWIthTimezone } from '../utils/utils';
import { ExternalUsersApi } from './ExternalUsers';
import { SupportedLanguage, TenantId } from './WellKnowIds';

export type BlobResponse =
    {
        blob: Blob
        filename?: string
    }

export type ApiResponse<T, U extends string = "unexpected"> =
    | { kind: "success", correlationId: string, data: T }
    | { kind: U, correlationId: string, message?: string, trace?: any, data?: any, status?: number, failures: undefined }
    | { kind: "unexpected", correlationId: string, message?: string, trace?: any, data?: any, failures?: { [property: string]: string[] }, status?: number }

export type RequestProgressEvent = (loaded: number, total: number) => void
export type SuccessHandler = (method: Method, status: number) => void

export class ApiClient {
    private static axiosClient?: AxiosInstance

    public readonly externalUsers = new ExternalUsersApi(this)
    public readonly applications = new ApplicationsApi(this)
    public readonly regions = new RegionsApi(this)
    public readonly documents = new DocumentsApi(this)

    private readonly _tenantId: TenantId
    private readonly _contentLanguage: SupportedLanguage
    private readonly _getAccessToken?: () => Promise<string>

    private _reportProgress?: ReportProgressF
    private _onUnexpected?: ErrorHandler
    private _onSuccess?: SuccessHandler

    static _Initialize() {
        if (ApiClient.axiosClient) return

        ApiClient.axiosClient = axios.create({
            baseURL: API_URL,
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json"
            },
            validateStatus: status => status >= 200 && status <= 510
        })
        const looksLikeADate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/ // Validate only starting part

        ApiClient.axiosClient.interceptors.request.use(config => {
            // Heuristic of the 'kakias oras'
            const shouldOverride =
                // No body
                !config.data &&
                // It's a GET
                (config.method === 'get' || config.method === 'GET') &&
                (
                    // And either URL is long
                    (config.url?.length ?? 0) > 2000 ||
                    // or any one of the string parameters
                    (typeof config.params === 'object' && Object.values(config.params).some(v => typeof v === 'string' && v.length > 2000))
                )

            if (shouldOverride) {
                const params = config.params
                config.transformRequest = (data: any, headers?: any) => {
                    const url = config.url?.split('?')
                    config.url = url?.[0]
                    const paramsAsBody =
                        url?.[1]
                            ? Array.from(new URLSearchParams(url[1]).entries()).map(kv => ({ Key: kv[0], Value: kv[1] }))
                            : _.map(params, (v, k) => ({ Key: k, Value: v }))
                    return JSON.stringify({ Query: paramsAsBody })
                }
                config.method = 'post'
                config.headers = { ...config.headers, 'X-HTTP-Method-Override': 'GET' }
                config.params = {}
            }
            return config
        })

        ApiClient.axiosClient.interceptors.request.use(config => {
            const transform = (current: any) => {
                const typ = typeof current
                switch (typ) {
                    case 'object':
                        if (Array.isArray(current))
                            for (const it of current)
                                transform(it) // TODO what if it's an array of 'dates'
                        else
                            for (const key in current) {
                                if (!Object.prototype.hasOwnProperty.call(current, key)) continue
                                const element = current[key]
                                if (isValidDate(element))
                                    current[key] = toISOStringWIthTimezone(element)
                                else
                                    transform(element)
                            }
                        break
                    case 'string':
                    case 'number':
                    case 'bigint':
                    case 'boolean':
                    case 'function':
                    case 'undefined':
                    case 'symbol': break
                    default: return caseNever(typ)
                }
            }

            config.transformRequest = (data: any, headers?: any) => {
                if (data instanceof FormData)
                    return data
                else {
                    const d = _.cloneDeep(data)
                    transform(d)
                    return JSON.stringify(d)
                }
            }

            return config
        })

        ApiClient.axiosClient.interceptors.response.use(x => {
            const transform = (current: any) => {
                const typ = typeof current
                switch (typ) {
                    case 'object':
                        if (Array.isArray(current))
                            for (const it of current)
                                transform(it) // TODO what if it's an array of 'dates'
                        else
                            for (const key in current) {
                                if (!Object.prototype.hasOwnProperty.call(current, key)) continue
                                const element = current[key]
                                if (typeof element === 'string' && looksLikeADate.test(element)) {
                                    const d = new Date(element)
                                    if (!Number.isNaN(d.getTime()))
                                        current[key] = d
                                }
                                else
                                    transform(element)
                            }
                        break
                    case 'string':
                    case 'number':
                    case 'bigint':
                    case 'boolean':
                    case 'function':
                    case 'undefined':
                    case 'symbol': break
                    default: return caseNever(typ)
                }
            }

            transform(x.data)

            return x
        })
    }

    constructor(ctor: { tenantId: TenantId, contentLanguage: SupportedLanguage, getAccessToken?: () => Promise<string> }) {
        this._tenantId = ctor.tenantId
        this._contentLanguage = ctor.contentLanguage
        this._getAccessToken = ctor.getAccessToken
    }

    public get tenantId(): TenantId {
        return this._tenantId
    }

    public get onUnexpected(): ErrorHandler {
        return this._onUnexpected ?? onUnexpectedIgnore
    }

    public set onProgressReported(report: ReportProgressF) {
        this._reportProgress = report
    }

    public set onUnexpected(handler: ErrorHandler) {
        if (!this._onUnexpected)
            this._onUnexpected = handler
        else throw Error("Cannot set error handler more than once")
    }

    public set onSuccess(handler: SuccessHandler) {
        if (!this.onSuccess)
            this._onSuccess = handler
        else throw Error("Cannot set success handler more than once")
    }

    public async execute<R = {}>(expectedStatusCode: number, method: Method, url: string, queryParams?: any, body?: any, timeout?: number, onUploadProgress?: RequestProgressEvent, onDownloadProgress?: RequestProgressEvent, blob?: boolean): Promise<ApiResponse<R>> {
        const operation = `${method}${url}${new Date().getTime()}`
        const correlationId = uuid.v4()

        try {
            this._reportProgress?.(operation, undefined)
            let token: string | undefined
            try {
                token = await this._getAccessToken?.()
            }
            catch (e) {
                return { kind: 'unexpected', correlationId: correlationId, message: e.message, data: undefined, trace: e.trace, status: -1 }
            }

            const headers: any = {
                Authorization: token ? `Bearer ${token}` : undefined,
                'Request-Id': correlationId,
            }
            const contentLanguage = toContentLanguage(this._contentLanguage)
            if (contentLanguage)
                headers['Content-Language'] = contentLanguage

            const request: AxiosRequestConfig = {
                method: method,
                params: queryParams,
                data: body,
                url: url,
                headers: headers,
                timeout: timeout,
                responseType: blob ? 'blob' : undefined,
                onUploadProgress: !onUploadProgress ? undefined : evt => onUploadProgress(evt.loaded, evt.total),
                onDownloadProgress: !onDownloadProgress ? undefined : evt => onDownloadProgress(evt.loaded, evt.total),
            }

            const response = await ApiClient.axiosClient!.request(request)
            if (response.status === expectedStatusCode) {
                this._onSuccess?.(method, response.status)
                if (blob) {
                    const filenameEncoded = response.headers['x-filename']
                    const blob = { blob: new Blob([response.data], { type: response.headers['content-type'] }), filename: !filenameEncoded ? undefined : decodeURI(filenameEncoded) }
                    return { kind: "success", correlationId: correlationId, data: blob as unknown as R }
                }
                else return { kind: "success", correlationId: correlationId, data: response.data }
            }
            return { kind: "unexpected", correlationId: correlationId, status: response.status, message: response.data?.message, data: response.data, failures: response.data?.failures, trace: response.data?.trace }
        }
        catch (e) {
            if (e instanceof Error)
                return { kind: "unexpected", message: `${e.name} : ${e.message}`, data: undefined, correlationId: correlationId, trace: e.stack }
            else
                return { kind: "unexpected", message: e, correlationId: correlationId, data: undefined }
        }
        finally {
            this._reportProgress?.(operation, 100)
        }
    }
}

ApiClient._Initialize()