import { Button, ButtonGroup, ClickAwayListener, Popover, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDropDown, CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { caseNever } from '../../utils/never'

export type SelectionState = 'all' | 'none' | 'page' | 'partial'

const useStyles = makeStyles((theme: Theme) => ({
    checkbox: {
        marginLeft: 9,
        padding: theme.spacing(0),
    },
    iconButton: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
    },
    popoverEntry: {
        marginLeft: theme.spacing(0.1)
    },
    buttonCheck: {
        paddingLeft: 18,
        padding: 0,
    },
    arrowDown: {
        padding: 0,
        marginLeft: 0,
        marginRight: 0,
    }
}))

export const DataGridCheckboxController = (props: { value: SelectionState, onChange: (value: SelectionState) => void }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined)
    const [selectionState, setSelectionState] = useState(props.value)

    useEffect(() => setSelectionState(props.value), [props.value])

    useEffect(() => { props.onChange(selectionState) }, [selectionState])

    const handleCheckboxClick = useCallback(() => {
        switch (selectionState) {
            case 'partial':
            case 'page':
            case 'all': return setSelectionState('none')
            case 'none': return setSelectionState('page')
            default: return caseNever(selectionState)
        }
    }, [selectionState])

    const closePopover = () => setAnchorEl(undefined)

    const handleClick = (state: SelectionState) => {
        setSelectionState(state)
        closePopover()
    }

    return <ButtonGroup disableElevation variant='text'>
        <Button className={classes.buttonCheck} onClick={handleCheckboxClick}>
            {selectionState === 'all' || selectionState === 'page'
                ? <CheckBox color='secondary' />
                : selectionState === 'partial'
                    ? <IndeterminateCheckBox color='secondary' />
                    : <CheckBoxOutlineBlank />}
        </Button>
        <Button className={classes.arrowDown} variant='text' onClick={e => setAnchorEl(e.currentTarget)}>
            <ArrowDropDown fontSize='small' />
        </Button>
        <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <ClickAwayListener onClickAway={_ => closePopover()}>
                <div>
                    <Button fullWidth className={classes.popoverEntry} onClick={_ => handleClick('page')}>ΣΕΛΙΔΑ</Button>
                    <Button fullWidth className={classes.popoverEntry} onClick={_ => handleClick('none')}>ΚΑΝΕΝΑ</Button>
                    <Button fullWidth className={classes.popoverEntry} onClick={_ => handleClick('all')}>ΟΛΑ</Button>
                </div>
            </ClickAwayListener>
        </Popover>
    </ButtonGroup>
}